import React from 'react';
import {
  BooleanInput,
  DateInput,
  NumberInput,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
} from 'react-admin';

import { validateRequired } from './validators';
import { JsonInput } from 'react-admin-json-view';

const TournamentsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" validate={validateRequired} />
        <TextInput multiline source="description" fullWidth={true} />
        <DateInput source="startDate" />
        <DateInput source="endDate" />
        <BooleanInput source="premium" />
        <SelectInput
          source="gender"
          choices={[
            { id: 'M', name: 'Maschio' },
            { id: 'F', name: 'Femmina' },
          ]}
        />
        <NumberInput source="sequence" label="Ordinamento" min={0} validate={validateRequired} />
        <NumberInput source="requiredPlayers" label="Utenti richiesti" />
        <JsonInput
          source="extra"
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: true,
          }}
        />
        <JsonInput
          source="masterclasses"
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: true,
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default TournamentsCreate;
