import React from 'react';
import { DateInput, Filter, TextInput } from 'react-admin';
import { ActivityLogStatusInput } from './util';

const ActivityLogFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <TextInput label="Task" source="task" alwaysOn />
    <ActivityLogStatusInput source="status" label="Status" alwaysOn />
    <DateInput label="Iniziato dal" source="createdAt[strictly_after]" />
    <DateInput label="Iniziato al" source="createdAt[strictly_before]" />
  </Filter>
);
ActivityLogFilter.propTypes = {};

export default ActivityLogFilter;
