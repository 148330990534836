import React from 'react';
import PropTypes from 'prop-types';
import { useQueryWithStore, Loading, Error } from 'react-admin';
import AsideProfile from './AsideProfile';
import AsideProfileMissing from './AsideProfileMissing';

const AsideUser = (props) => {
  const { userId } = props;
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'users',
    payload: { id: userId },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return data.profile ? (
    <AsideProfile {...props} profileId={data.profile} record={data} />
  ) : (
    <AsideProfileMissing {...props} record={data} />
  );
};
AsideUser.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default AsideUser;
