import React from 'react';
import {
  ChipField,
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
} from 'react-admin';

const UserSponsorCodeShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="sponsorCode" reference="sponsor_codes" sortable={false} link="show">
        <ChipField source="code" />
      </ReferenceField>
      <ReferenceField source="owner" reference="users" sortable={false} link="show">
        <FunctionField render={(record) => `${record.surname} ${record.name}`} />
      </ReferenceField>
      <DateField source="expiredAt" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export default UserSponsorCodeShow;
