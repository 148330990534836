import React from 'react';
import { Button, TextInput, useNotify } from 'react-admin';
import DialogForm from './DialogForm';
import DownloadIcon from '@material-ui/icons/GetApp';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import authProvider from './authProvider';
import { apiRequest, HTTP_METHOD_POST } from './utils';

const ExportButton = ({ disabled, targetClass, filters }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const notify = useNotify();

  const handleInit = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!authProvider.checkExistingToken()) {
      notify('Error during export', 'warning');
    }
    const response = await apiRequest({
      path: '/export',
      method: HTTP_METHOD_POST,
      payload: { filters, targetClass, email },
    });
    setOpen(false);
    if (response.ok) {
      notify('Export file will be sent to ' + email, 'success');
    } else {
      notify('Error during export', 'warning');
    }
  };

  return (
    <>
      <Button disabled={disabled} label="Export" onClick={handleInit}>
        <DownloadIcon />
      </Button>
      <DialogForm
        open={open}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        title="Export current list"
        submitLabel="Export"
      >
        <TextInput
          required
          label="Email"
          source="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </DialogForm>
    </>
  );
};

ExportButton.propTypes = {
  disabled: PropTypes.bool,
  targetClass: PropTypes.string,
  filters: PropTypes.object,
};

export default ExportButton;
