import React from 'react';
import { Button, useNotify, useQueryWithStore, Loading, Error, useRefresh } from 'react-admin';
import { CollectionsBookmark, CollectionsBookmarkOutlined } from '@material-ui/icons';

import { PropTypes } from 'prop-types';
import authProvider from './authProvider';
import { apiRequest, HTTP_METHOD_DELETE, HTTP_METHOD_POST, isPlayer, resourceId } from './utils';

export const TagUserAsPotentialButton = ({ disabled, user, interactionId, onClick }) => {
  const notify = useNotify();

  const handleSubmit = async () => {
    if (!authProvider.checkExistingToken()) {
      return notify('Error: unauthorized, please login again', 'warning');
    }

    let error = null;
    try {
      if (interactionId) {
        await apiRequest({
          path: `profile_interactions/${interactionId}`,
          method: HTTP_METHOD_DELETE,
        });
      } else {
        await apiRequest({
          path: 'profile_interactions',
          payload: {
            profile: user.profile,
            type: 'has-potential',
          },
          method: HTTP_METHOD_POST,
        });
      }
      if (onClick) onClick();
    } catch (err) {
      error = err;
    }

    if (error) {
      notify('Error: unable to tag user, please try again later', 'error');
    } else {
      notify(interactionId ? 'Tag removed successfully' : 'User has been tagged');
    }
  };

  return (
    <>
      <Button
        label={
          interactionId ? `${user.name} is a potential candidate!` : 'Mark as potential candidate'
        }
        disabled={disabled}
        onClick={handleSubmit}
      >
        {interactionId ? <CollectionsBookmark /> : <CollectionsBookmarkOutlined />}
      </Button>
    </>
  );
};

TagUserAsPotentialButton.propTypes = {
  disabled: PropTypes.bool,
  user: PropTypes.object,
  onClick: PropTypes.func | null,
  interactionId: PropTypes.string | null,
};

export const TagUserAsPotentialAction = ({ record }) => {
  const refresh = useRefresh();

  const { loaded, error, data } = useQueryWithStore({
    type: 'getManyReference',
    resource: 'profile_interactions',
    payload: {
      pagination: { page: 1 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: {
        profile: record.profile,
        type: 'has-potential',
      },
    },
  });

  if (!record.profile) return null;

  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <TagUserAsPotentialButton
      disabled={!record?.roles || !isPlayer(record)}
      user={record}
      interactionId={data.length && data[0] ? resourceId(data[0].id) : null}
      onClick={refresh}
    />
  );
};
TagUserAsPotentialAction.propTypes = {
  record: PropTypes.object,
};

export default TagUserAsPotentialAction;
