import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import { Button, DateField, Filter, TextField, TextInput, useMutation } from 'react-admin';

const SellerFilter = (props) => (
  <Filter {...props}>
    <TextInput source="email" label="Email" alwaysOn />
    <TextInput source="code" label="Code" alwaysOn />
  </Filter>
);

const EnableToggleButton = ({ record }) => {
  const [toggleEnable, { loading, error }] = useMutation({
    type: 'update',
    resource: 'promo_codes',
    payload: { id: record.id, data: { enable: !record.enable } },
  });
  return (
    <Button
      label={record.enable ? 'Disable' : 'Enable'}
      onClick={toggleEnable}
      disabled={loading || error}
      startIcon={record.enable ? <VisibilityOff /> : <Visibility />}
    />
  );
};
EnableToggleButton.propTypes = {
  record: PropTypes.object,
};

const SellerList = (props) => (
  <ListGuesser
    {...props}
    title="List of Seller"
    perPage={25}
    filters={<SellerFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <TextField source="email" label="Email" />
    <TextField source="code" label="Code" />
    <TextField source="discount" label="Discount" />
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
    <EnableToggleButton />
  </ListGuesser>
);

export default SellerList;
