import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceField,
  ReferenceInput,
  SelectInput,
  FunctionField,
  useGetList,
  Loading,
  Error,
  ArrayField,
  SingleFieldList,
} from 'react-admin';
import { Chip, Box } from '@material-ui/core';
import OpenIcon from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import InfoBox from '../InfoBox';
import RichTextInput from 'ra-input-rich-text';

/**
 * @param {{}} partnership
 */
export function getPartnershipDisplayName(partnership) {
  return partnership.name + (partnership.partner?.name ? ` [${partnership.partner?.name}]` : '');
}

export const PartnershipField = ({ record }) => {
  if (!record) return null;

  return <Chip label={record.name} />;
};
PartnershipField.propTypes = {
  record: PropTypes.object,
  props: PropTypes.any,
};

export const PartnershipReferenceField = ({ source, reference, label, ...props }) => {
  return (
    <ReferenceField
      label={label ?? 'Partnership'}
      source={source ?? 'partnership'}
      reference={reference ?? 'partnerships'}
      link="show"
      sortable={false}
      {...props}
    >
      <FunctionField render={(record) => <PartnershipField record={record} />} />
    </ReferenceField>
  );
};
PartnershipReferenceField.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  label: PropTypes.string,
};

export const PartnershipSelectInput = ({ source, label, reference, ...props }) => {
  return (
    <ReferenceInput
      label={label ?? 'Partnership'}
      source={source ?? 'partnership'}
      reference={reference ?? 'partnerships'}
      link="show"
      sortable={false}
      {...props}
    >
      <SelectInput translateChoice={false} optionText={<PartnershipField />} />
    </ReferenceInput>
  );
};
PartnershipSelectInput.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  label: PropTypes.string,
};

/**
 * @param {string} label
 * @param {string} source
 * @param {{ [key:string]: any }} dataFilters Filters the list of partnerships, default = { active:true }
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
export const PartnershipSignupFlowSelectInput = ({ label, source, dataFilters = {}, ...props }) => {
  const { data, loading, error } = useGetList(
    'partnerships',
    {},
    {},
    {
      pagination: false,
      // ------ default filters start
      active: true,
      // ------ default filters end
      ...dataFilters,
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <SelectInput
      {...props}
      translateChoice={false}
      choices={Object.entries(data ?? {}).map((entry) => entry?.[1])}
      optionText={(record) => <PartnershipField record={record} />}
      optionValue={'signupFlow'}
      source={source}
      label={label}
    />
  );
};
PartnershipSignupFlowSelectInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
  dataFilters: PropTypes.string,
  props: PropTypes.object,
};

export const PartnershipSignupFlowArrayField = ({
  data,
  loading,
  error,
  isList = true,
  ...props
}) => {
  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;
  const style = {
    padding: isList ? undefined : '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: '1 0 30%',
    gap: '5px',
  };
  const getPartnership = (record) =>
    Object.entries(data ?? {})
      .map((e) => e[1])
      .find((p) => p?.signupFlow === record);
  return (
    <ArrayField source="attributes" {...props} style={style}>
      <SingleFieldList>
        <FunctionField render={(record) => <PartnershipField record={getPartnership(record)} />} />
      </SingleFieldList>
    </ArrayField>
  );
};
PartnershipSignupFlowArrayField.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  isList: PropTypes.bool,
  props: PropTypes.object,
};

export const PartnershipConfigurationUI = ({ page, ...props }) => {
  const [enableEditing] = useState(['edit', 'create'].includes(page));
  const [isOpen, setOpen] = useState(false);

  if (!enableEditing) {
    return null;
  }

  return (
    <FunctionField
      {...props}
      source="configurations"
      render={() => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen(!isOpen)}
              >
                {isOpen && <CloseIcon />}
                {!isOpen && <OpenIcon />}
                <span style={{ fontSize: '24px' }}>
                  <strong>Configurazioni</strong>
                </span>
              </Box>
              <Box style={{ paddingLeft: '32px' }}>
                <InfoBox showIcon={false}>
                  <strong>
                    Queste configurazioni vengono usate dall&apos;applicativo per modificare i
                    comportamenti dei vari servizi delle partnership.
                  </strong>
                </InfoBox>
              </Box>
            </Box>

            {isOpen && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                style={{ gap: '24px', paddingLeft: '48px' }}
              >
                <br />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <InfoBox showIcon={false} showBackground={true} level="info">
                    <span style={{ fontSize: '16px' }}>
                      <strong>Customizzazione pagamenti APP</strong>
                    </span>
                  </InfoBox>
                  <RichTextInput
                    source="configurations.payments.checkout_disclaimer"
                    label="Testo di checkout"
                    helperText="Il testo verrà mostrato in fase di checkout su tutti i pagamenti connessi a questa partnership."
                  />
                </Box>
              </Box>
            )}
            <br />
          </Box>
        );
      }}
    />
  );
};
PartnershipConfigurationUI.propTypes = {
  page: PropTypes.oneOf(['list', 'show', 'edit', 'create']),
  props: PropTypes.object,
};
