import React from 'react';
import { useState } from 'react';
import { Button, useNotify } from 'react-admin';
import { Notifications, Info } from '@material-ui/icons';
import { Typography, CircularProgress, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DialogForm from './DialogForm';
import { PropTypes } from 'prop-types';
import authProvider from './authProvider';
import { apiRequest, HTTP_METHOD_POST } from './utils';

const useStyles = makeStyles({
  infoBox: {
    width: '100%',
    outlineColor: '#08316c',
    outlineWidth: '0.8rem',
    // backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))',
    lineHeight: '1.43',
    color: '#08316c', //'rgb(184, 231, 251)',
    fontWeight: '500',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '1rem',
    borderRadius: '1rem',
  },
  relative: {
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
  },
});

const RequestAdditionalContentButton = ({ disabled, user }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleInit = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!authProvider.checkExistingToken()) {
      notify('Error: unable to notify user', 'warning');
    }

    let error = null;
    try {
      setLoading(true);
      if (!user.profile) throw new Error('failed to fetch profile or user has no profile at all');
      await apiRequest({
        path: '/profile_interactions',
        method: HTTP_METHOD_POST,
        payload: {
          profile: user.profile,
          type: 'content-request',
        },
      });
    } catch (err) {
      error = err;
    } finally {
      setLoading(false);
    }

    if (error) {
      notify('Error: unable to notify user', 'error');
    } else {
      notify('User has been notified');
    }

    setOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      <Button label="Request content" disabled={disabled} onClick={handleInit}>
        <Notifications />
      </Button>
      <DialogForm
        open={open}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        title="Request more content"
        submitLabel="Request"
        className={classes.relative}
      >
        <Typography paragraph={true} variant={'h6'}>
          Vuoi richiedere a {user.name} {user.surname} di aggiungere ulteriori video?
        </Typography>
        <Typography
          paragraph={true}
          variant={'caption'}
          gutterBottom={false}
          className={classes.infoBox}
        >
          Quest&apos;azione causerà l&apos;invio di una notifica ai dispositivi dell&apos;utente{' '}
          <Info />
        </Typography>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '800ms' : '0ms',
          }}
          unmountOnExit
          className={classes.absolute}
        >
          <CircularProgress />
        </Fade>
      </DialogForm>
    </>
  );
};

RequestAdditionalContentButton.propTypes = {
  disabled: PropTypes.bool,
  user: PropTypes.object,
};

export default RequestAdditionalContentButton;
