import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import {
  BooleanField,
  Button,
  DateField,
  Filter,
  FunctionField,
  ReferenceField,
  TextField,
  TextInput,
  useMutation,
} from 'react-admin';
import { PromoCodeTypeChip } from './promoCode.util';

const EnableToggleButton = ({ record }) => {
  const [toggleEnable, { loading, error }] = useMutation({
    type: 'update',
    resource: 'promo_codes',
    payload: { id: record.id, data: { enable: !record.enable } },
  });
  return (
    <Button
      label={record.enable ? 'Disable' : 'Enable'}
      onClick={toggleEnable}
      disabled={loading || error}
      startIcon={record.enable ? <VisibilityOff /> : <Visibility />}
    />
  );
};
EnableToggleButton.propTypes = {
  record: PropTypes.object,
};

const PromoCodeFilter = (props) => (
  <Filter {...props}>
    <TextInput source="type" label="Type" alwaysOn />
    <TextInput source="code" label="Code" alwaysOn />
    <TextInput source="seller.code" label="Seller Code" alwaysOn />
    <TextInput source="seller.email" label="Seller Email" alwaysOn />
  </Filter>
);

const PromoCodeList = (props) => (
  <ListGuesser
    {...props}
    title="List of Promo Code"
    perPage={25}
    filters={<PromoCodeFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <ReferenceField
      label="Seller Code"
      source="seller"
      reference="sellers"
      link="show"
      sortable={false}
    >
      <FunctionField render={(record) => `${record.code}`} />
    </ReferenceField>
    <ReferenceField
      label="Product"
      source="product"
      reference="products"
      link="show"
      sortable={false}
    >
      <FunctionField render={(record) => `${record.title}`} />
    </ReferenceField>
    <PromoCodeTypeChip source="type" label="Type" />
    <TextField source="code" label="Code" />
    <TextField source="discount" label="Discount" />
    <BooleanField source="internal" sortable={false} />
    <BooleanField source="enable" sortable={false} />
    <FunctionField
      label="Events"
      source="has_events"
      render={(record, source) => (
        <BooleanField record={{ ...record, has_events: !!record.events.length }} source={source} />
      )}
    />
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
    <EnableToggleButton />
  </ListGuesser>
);

export default PromoCodeList;
