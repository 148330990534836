import React from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  NumberInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import { validateOwner, validateRequired } from './validators';
import { validateTournament } from '../challenges/validators';
import { tournamentOptionText } from '../utils';

const WinnerCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="Torneo"
          source="tournament"
          reference="tournaments"
          validate={validateTournament}
        >
          <SelectInput optionText={(record) => tournamentOptionText(record)} />
        </ReferenceInput>
        <ReferenceInput
          label="User"
          source="owner"
          reference="users"
          validate={validateOwner}
          filterToQuery={(searchText) => ({ search: searchText, role: 'PLAYER', visible: true })}
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(record) => `${record.surname} ${record.name} ${record.phone}`}
          />
        </ReferenceInput>
        <NumberInput source="place" label="Posizione" min={1} validate={validateRequired} />
        <JsonInput
          source="extra"
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default WinnerCreate;
