import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { TextField, NumberField, DateField } from 'react-admin';
import { PartnershipReferenceField } from '../partnership/utils';
import { AggregatorInstanceField, AggregatorModelField } from './util';
import { VisibilityToggleButton } from '../utils';

const AggregatorLaunchpadList = (props) => (
  <ListGuesser
    {...props}
    title="List of Content Aggregators"
    perPage={25}
    bulkActionButtons={false}
  >
    <TextField source="name" />
    <AggregatorModelField source="model" label="Model" />
    <AggregatorInstanceField />
    <NumberField source="jobFrequency" label="Frequency" />
    <PartnershipReferenceField />
    <TextField source="uri" />
    <DateField source="lastExecutionTime" label="Ultima esecuzione" />
    <DateField source="createdAt" />
    <VisibilityToggleButton resource="remote_aggregator_launchpad" />
  </ListGuesser>
);

export default AggregatorLaunchpadList;
