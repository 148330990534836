import React from 'react';
import PropTypes from 'prop-types';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  Error,
  Loading,
  ReferenceArrayInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  useQueryWithStore,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { resourceId } from '../utils';
import { validateRequired } from './validators';
import { PromoCodeTypes } from './promoCode.util';

const EventSelect = () => {
  const { values } = useFormState();

  if (values && values.product) {
    return <EventLoadSelect productId={values.product} />;
  } else {
    return (
      <AutocompleteArrayInput
        disabledAdd
        disableRemove
        source="events"
        optionText="Events"
        allowEmpty="true"
      />
    );
  }
};

const EventLoadSelect = ({ productId }) => {
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'products',
    payload: { id: productId },
  });

  if (error) {
    return <Error />;
  }

  if (!loaded && !data) {
    return <Loading />;
  }

  const optionRenderer = (choice) =>
    `${choice.place} ${new Date(choice.date).toLocaleDateString('it-it')}`;

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  if (data && data.tournament) {
    return (
      <ReferenceArrayInput
        label="Events"
        source="events"
        reference="events"
        filter={{
          'tournament.id': resourceId(data.tournament),
          visible: true,
          sodOut: false,
          'date[after]': now.toISOString(),
        }}
      >
        <AutocompleteArrayInput optionText={optionRenderer} />
      </ReferenceArrayInput>
    );
  } else {
    return (
      <AutocompleteArrayInput
        disabledAdd
        disableRemove
        source="events"
        optionText="Events"
        allowEmpty="true"
      />
    );
  }
};

EventLoadSelect.propTypes = {
  productId: PropTypes.string.isRequired,
};

const PromoCodeCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="Seller"
          source="seller"
          reference="sellers"
          filterToQuery={(searchText) => ({ code: searchText })}
        >
          <AutocompleteInput optionText="code" />
        </ReferenceInput>
        <ReferenceInput
          label="Product"
          source="product"
          reference="products"
          filterToQuery={(searchText) => ({ title: searchText })}
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <label>Per inserire un promo code associato ad un evento seleziona prima un prodotto</label>
        <EventSelect source="events" />
        <SelectInput
          initialValue={'SECONDCHANCE'}
          source="type"
          label="Type"
          choices={Object.entries(PromoCodeTypes).map(([key, value]) => {
            return { id: key, name: value };
          })}
        />
        <TextInput source="code" label="Code" validate={validateRequired} />
        <NumberInput source="discount" label="Discount" />
        <SelectInput
          source="discountType"
          allowEmpty={true}
          choices={[
            { id: 'P', name: '%' },
            { id: 'V', name: '€' },
          ]}
        />
        <BooleanInput label="Internal" source="internal" initialValue={true} />
        <BooleanInput label="Enable" source="enable" initialValue={true} />
      </SimpleForm>
    </Create>
  );
};

export default PromoCodeCreate;
