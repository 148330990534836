import React from 'react';
import { TextField, NumberField, DateField, Show, SimpleShowLayout } from 'react-admin';
import { Typography } from '@material-ui/core';
import { JsonForm, JsonFormFieldTypes } from '../JsonForm';
import { ActivityLogStatusField } from './util';
import PropTypes from 'prop-types';

const Stacktrace = ({ record, ...props }) => (
  <Typography {...props} paragraph={true} gutterBottom={false}>
    <div dangerouslySetInnerHTML={{ __html: record?.stacktrace }}></div>
  </Typography>
);
Stacktrace.propTypes = {
  record: PropTypes.object,
  props: PropTypes.object,
};

const ActivityLogShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="id" label="ID" />
      <TextField source="handler" label="Task" />
      <ActivityLogStatusField label="Status" />
      <JsonForm
        source="context"
        label="Context"
        mode="field"
        formFieldMap={[
          {
            type: JsonFormFieldTypes.func,
            source: 'begin',
            render: (record) => (record?.begin ? JSON.stringify(record?.begin) : null),
          },
          {
            type: JsonFormFieldTypes.func,
            source: 'end',
            render: (record) => (record?.end ? JSON.stringify(record?.end) : null),
          },
        ]}
      />
      <Stacktrace />
      <DateField source="createdAt" label="Begin date" showTime={true} />
    </SimpleShowLayout>
  </Show>
);

export default ActivityLogShow;
