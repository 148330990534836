import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { ChipField, TextField, BooleanField, ReferenceField } from 'react-admin';

const InAppProductList = (props) => (
  <ListGuesser {...props} title="List of InAppProduct" perPage={25} bulkActionButtons={false}>
    <TextField source="name" label="Name" />
    <TextField source="android" label="Android product id" />
    <TextField source="ios" label="Ios product id" />
    <BooleanField source="premium" sortable={false} />
    <BooleanField source="onboarding" sortable={false} />
    <ReferenceField label="Product" source="product" reference="products" link="show">
      <ChipField source="title" />
    </ReferenceField>
  </ListGuesser>
);

export default InAppProductList;
