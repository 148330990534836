import { LinearProgress } from '@material-ui/core';
import React from 'react';
import {
  BooleanInput,
  TextInput,
  SelectInput,
  FileInput,
  FileField,
  Create,
  SimpleForm,
  useDataProvider,
  useNotify,
  ReferenceInput,
  useQueryWithStore,
  usePermissions,
  Loading,
  Error,
} from 'react-admin';
import { useFormState } from 'react-final-form';

import { fetchOptions } from '../dataProvider';
import mediaCreator from '../mediaCreator';
import { resourceId, isAdvisor, tournamentOptionText } from '../utils';
import { validateDescription, validateFile } from './validators';

const StageReferenceInput = () => {
  const { values } = useFormState();

  const filter =
    values && values.tournament ? { 'tournament.id': resourceId(values.tournament) } : {};

  const { loaded, error, data } = useQueryWithStore({
    type: 'getManyReference',
    resource: 'stages',
    payload: {
      pagination: { page: 1 },
      sort: {},
      filter,
    },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const disabled = !(values && values.tournament);

  return <SelectInput source="stage" choices={data} optionText="title" disabled={disabled} />;
};

const VideoCreate = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions, loading: Loading } = usePermissions();
  const [loading, showLoading] = React.useState(false);

  if (Loading) return <>Loading...</>;
  return (
    <Create
      {...props}
      transform={async (data) => {
        if (data.presentation) {
          const response = await fetch(
            `${process.env.REACT_APP_API_ENTRYPOINT}/profiles?` +
              new URLSearchParams({ pagination: false, visible: true, 'owner.id': permissions.id }),
            fetchOptions({ method: 'GET' })
          );

          const profiles = await response.json();

          delete data.presentation;
          data.profile = profiles['hydra:member'][0]['@id'];
        }

        const createStageVideo = mediaCreator({ dataProvider, notify, mediaFieldLabel: 'media' });
        try {
          showLoading(true);
          const withStageVideo = await createStageVideo(data);
          return withStageVideo;
        } catch (error) {
          notify(`Errore inatteso: ${error.message}`, 'warning');
        } finally {
          showLoading(false);
        }

        return data;
      }}
    >
      <SimpleForm>
        <TextInput multiline source="description" fullWidth={true} validate={validateDescription} />
        {isAdvisor(permissions) && <BooleanInput label="Presentation" source="presentation" />}
        <label>Per inserire un video associato ad una fase finale seleziona prima un torneo</label>
        <ReferenceInput label="Tournament" source="tournament" reference="tournaments">
          <SelectInput optionText={(record) => tournamentOptionText(record)} />
        </ReferenceInput>
        <StageReferenceInput />
        <FileInput
          validate={validateFile}
          source="file"
          label="Video"
          placeholder={<p>Trascina qui il video, o clicca qui per selezionarlo</p>}
        >
          <FileField source="src" title="title" />
        </FileInput>
        {loading && <LinearProgress fullWidth={true} />}
      </SimpleForm>
    </Create>
  );
};

export default VideoCreate;
