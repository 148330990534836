import LocalOffer from '@material-ui/icons/LocalOffer';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import EventCreate from './EventCreate';
import EventEdit from './EventEdit';
import EventList from './EventList';
import EventShow from './EventShow';
import { isAdmin, isEditor } from '../utils';

const EventResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions) && !isEditor(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={EventList}
      show={EventShow}
      create={EventCreate}
      edit={EventEdit}
      icon={LocalOffer}
    />
  );
};
EventResource.defaultProps = {
  name: 'events',
};

export default EventResource;
