import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const PreOrderBuyerShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Name" />
      <TextField source="surname" label="Surname" />
      <TextField source="email" label="Email" />
      <TextField source="phone" label="Phone" />
    </SimpleShowLayout>
  </Show>
);

export default PreOrderBuyerShow;
