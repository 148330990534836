import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles({
  unknown: {},
  male: {
    backgroundColor: '#bcbcff',
  },
  female: {
    backgroundColor: '#ffbcbc',
  },
});

const GenderField = ({ record = {}, source }) => {
  const classes = useStyles();
  let style = classes.unknown;
  switch (record[source]) {
    case 'M':
      style = classes.male;
      break;
    case 'F':
      style = classes.female;
      break;
    default:
      return null;
  }
  return <Chip className={style} label={record[source]} />;
};

GenderField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

GenderField.defaultProps = {
  addLabel: true, // to enable it in the "show" view
};

export default GenderField;
