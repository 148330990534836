import decodeJwt from 'jwt-decode';
import { ROLE_ADMIN, ROLE_ADVISOR, ROLE_EDITOR, ROLE_SCOUT } from './utils';

let refreshTimeOutId;

const checkExistingToken = () =>
  window.localStorage.getItem('token') && window.localStorage.getItem('refresh_token');

const getRefreshedToken = () => {
  const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/refresh-token`, {
    method: 'POST',
    body: JSON.stringify({
      refresh_token: window.localStorage.getItem('refresh_token'),
    }),
    headers: new Headers({ 'Content-Type': 'application/json', 'OOU-Client-Platform': 'web' }),
  });
  return fetch(request)
    .then((response) => {
      if (response.status !== 200) {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('refresh_token');
        return { token: null };
      }
      return response.json();
    })
    .then(({ token, refresh_token }) => {
      if (token) {
        setToken(token, refresh_token);
        return true;
      }

      return false;
    });
};

const setToken = (token, refresh_token, reload = false) => {
  window.localStorage.setItem('token', token);
  window.localStorage.setItem('refresh_token', refresh_token);

  // is needed reload the page after login, due to missing update of the Authorization headers managed by hydraDataProvider
  if (reload) window.location.reload();

  const decodedToken = decodeJwt(token);

  const roles = decodedToken.roles;

  if (
    !roles ||
    !(
      roles.includes(ROLE_ADMIN) ||
      roles.includes(ROLE_SCOUT) ||
      roles.includes(ROLE_ADVISOR) ||
      roles.includes(ROLE_EDITOR)
    )
  ) {
    deleteToken();
    throw new Error('Accesso negato');
  }

  let delay = decodedToken.exp - decodedToken.iat - (10 + Math.floor(Math.random() * 100));
  if (delay < 10) {
    delay = 10;
  }

  refreshTimeOutId = window.setTimeout(getRefreshedToken, delay * 1000);
};

const deleteToken = () => {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('refresh_token');

  if (refreshTimeOutId) {
    window.clearTimeout(refreshTimeOutId);
  }
};

const authProvider = {
  login: async ({ username, password }) => {
    const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/login`, {
      method: 'POST',
      body: JSON.stringify({ username: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json', 'OOU-Client-Platform': 'web' }),
    });
    let response = await fetch(request);
    if (response.status !== 200) throw new Error(response.statusText);
    let { token, refresh_token } = await response.json();
    setToken(token, refresh_token, true);
  },

  // TODO api logout to safely invalidate; WARNING: this code is executed in case of 401 error or failed checkAuth too, expect an error from logout call too
  logout: async () => deleteToken(),

  checkError: async (error) => {
    // Refresh token or logout will be immediately called
    if (error.status === 401) {
      const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/refresh-token`, {
        method: 'POST',
        body: JSON.stringify({ refresh_token: window.localStorage.getItem('refresh_token') }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          'OOU-Client-Platform': 'web',
        }),
      });

      let response = await fetch(request);
      if (response.status === 200) {
        let { token, refresh_token } = await response.json();
        setToken(token, refresh_token, true);
      } else {
        throw new Error('not authorized');
      }
    }
  },

  // checkAuth is used when navigating, even when no api call is needed and cached data will suffice
  checkAuth: async () => {
    // when logged out from a second admin tab
    if (!checkExistingToken()) throw new Error('not authenticated');

    if (!refreshTimeOutId) {
      // when opening a new admin tab, if an existing session is found the token refresh cycle should be started again, also, roles should be extracted
      setToken(window.localStorage.getItem('token'), window.localStorage.getItem('refresh_token'));
    }
  },

  // getPermissions will be useful only if fine-grained permissions are needed
  getPermissions: async () => {
    const token = window.localStorage.getItem('token');
    const decodedToken = decodeJwt(token);
    return decodedToken ? Promise.resolve(decodedToken) : Promise.reject();
  },

  checkExistingToken,
};

export default authProvider;
