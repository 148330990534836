import React from 'react';
import { Resource, usePermissions } from 'react-admin';
import AggregatorIcon from '@material-ui/icons/AdbOutlined';

import { isAdmin } from '../utils';
import AggregatorLaunchpadList from './AggregatorLaunchpadList';
import AggregatorLaunchpadCreate from './AggregatorLaunchpadCreate';
import AggregatorLaunchpadEdit from './AggregatorLaunchpadEdit';
import AggregatorLaunchpadShow from './AggregatorLaunchpadShow';

const AggregatorLaunchpadResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      options={{ label: 'Content Aggregators' }}
      list={AggregatorLaunchpadList}
      create={AggregatorLaunchpadCreate}
      show={AggregatorLaunchpadShow}
      edit={AggregatorLaunchpadEdit}
      icon={AggregatorIcon}
    />
  );
};
AggregatorLaunchpadResource.defaultProps = {
  name: 'aggregator_launchpads',
};

export default AggregatorLaunchpadResource;
