import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SimpleShowLayout } from 'react-admin';

export const FormGrid = ({ record, resource, basePath, version, children, ...props }) => (
  <Grid spacing={2} {...props} container style={{ width: '100%' }}>
    {React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { record, resource, basePath, version });
      }
      return child;
    })}
  </Grid>
);
FormGrid.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  basePath: PropTypes.string,
  version: PropTypes.any,
  children: PropTypes.any,
  props: PropTypes.object,
};

export const FormGridItem = ({ record, resource, basePath, version, children, ...props }) => (
  <Grid {...props} item>
    <SimpleShowLayout record={record} basePath={basePath} resource={resource} version={version}>
      {children}
    </SimpleShowLayout>
  </Grid>
);
FormGridItem.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  basePath: PropTypes.string,
  version: PropTypes.any,
  children: PropTypes.any,
  props: PropTypes.object,
};

export default FormGrid;
