import { ListGuesser } from '@api-platform/admin';
import { PropTypes } from 'prop-types';
import React, { cloneElement, Fragment } from 'react';
import {
  CheckboxGroupInput,
  Error,
  Filter,
  FunctionField,
  Loading,
  NumberInput,
  ReferenceField,
  required,
  sanitizeListRestProps,
  SelectInput,
  TextField,
  TopToolbar,
  useGetList,
  useListContext,
  useQueryWithStore,
  useUnselectAll,
} from 'react-admin';
import AddPassButton from '../AddPassButton';
import ExportButton from '../ExportButton';
import { resourceId, tournamentOptionText } from '../utils';
import PassReferenceField from '../PassReferenceField';

const ChallengeFilterInput = () => {
  const types = [{ title: 'General', type: 'general' }];
  for (let date = new Date('2020-12-01'); date <= new Date(); date.setMonth(date.getMonth() + 1)) {
    types.push({
      title: date.toISOString().substring(0, 7),
      type: 'month-' + date.toISOString().substring(0, 7),
    });
  }
  const { filterValues } = useListContext();

  const filter =
    filterValues && filterValues.tournament ? { 'tournament.id': filterValues.tournament } : {};

  const { data: tournament, ids: tournamentIds } = useGetList(
    'tournaments',
    {},
    {},
    { pagination: false }
  );

  const { loaded, error, data } = useQueryWithStore({
    type: 'getManyReference',
    resource: 'challenges',
    payload: {
      pagination: { page: 1 },
      sort: {},
      filter,
    },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  data.forEach((challenge) => {
    const tId = tournamentIds.find((id) => tournament[id].id === challenge.tournament);
    types.push({
      title: (tId ? tournament[tId].title + ': ' : '') + challenge.title,
      type: 'challenge-' + challenge.originId,
    });
  });

  return (
    <Fragment>
      <SelectInput
        alwaysOn
        label="Challenge"
        source="type"
        choices={types}
        optionText="title"
        optionValue="type"
        validate={[required()]}
        allowEmpty={false}
        helperText={false}
      />
    </Fragment>
  );
};

const LeaderboardsFilter = (props) => {
  const unselectAll = useUnselectAll();
  const { data: tournament, ids: tournamentIds } = useGetList(
    'tournaments',
    {},
    {},
    { pagination: false }
  );

  const tournamentId = props.filterValues.tournament
    ? resourceId(props.filterValues.tournament)
    : null;

  return (
    <Filter {...props}>
      <SelectInput
        alwaysOn
        label="Tournament"
        source="tournament"
        choices={tournamentIds.map((id) => tournament[id])}
        optionText={(record) => tournamentOptionText(record)}
        optionValue="originId"
        validate={[required()]}
        allowEmpty={false}
        onChange={(input) => {
          unselectAll('leaderboards');
          props.setFilters({
            ...props.filterValues,
            tournament: input.target.value,
            hasVideo: null,
          });
        }}
      />
      <ChallengeFilterInput source="type" alwaysOn />
      <NumberInput label="Punteggio dal" source="points[gte]" alwaysOn />
      <NumberInput label="Punteggio al" source="points[lte]" alwaysOn />
      <SelectInput
        alwaysOn
        label="Premium"
        source="premium"
        allowEmpty={true}
        initialValue={null}
        choices={[
          { id: true, name: 'Yes' },
          { id: false, name: 'No' },
        ]}
      />
      <CheckboxGroupInput
        alwaysOn
        source="hasVideo"
        label=""
        choices={[{ id: tournamentId, label: 'Con video' }]}
        disabled={!props.filterValues.tournament}
        optionText="label"
        optionValue="id"
        title={!props.filterValues.tournament ? 'Seleziona prima il torneo' : ''}
      />
      <CheckboxGroupInput
        alwaysOn
        source="profile.role"
        label=""
        choices={[
          { role: 'GK', name: 'Goalkeeper' },
          { role: 'DF', name: 'Defender' },
          { role: 'MF', name: 'Midfielder' },
          { role: 'FW', name: 'Striker or Forward' },
        ]}
        optionText="name"
        optionValue="role"
      />
    </Filter>
  );
};

LeaderboardsFilter.propTypes = {
  setFilters: PropTypes.func,
  filterValues: PropTypes.object,
};

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        targetClass={'App\\Entity\\Leaderboard'}
        filters={filterValues}
      />
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.object,
};

const LeaderboardsBulkActionButtons = (props) => {
  return (
    <Fragment>
      <AddPassButton
        {...props}
        source="user"
        tournament={props.filterValues.tournament}
        disabled={!props.filterValues.tournament}
      />
    </Fragment>
  );
};

LeaderboardsBulkActionButtons.propTypes = {
  filterValues: PropTypes.object,
  selectedIds: PropTypes.array,
};

const LeaderboardsList = (props) => (
  <ListGuesser
    {...props}
    title="Leaderboards"
    perPage={25}
    bulkActionButtons={<LeaderboardsBulkActionButtons />}
    actions={<ListActions />}
    filters={<LeaderboardsFilter />}
    filterDefaultValues={{ type: 'general', owner: { role: 'PLAYER' } }}
  >
    <TextField source="position" />
    <ReferenceField source="user" reference="users" link="show" sortable={false}>
      <FunctionField label="User" render={(record) => `${record.surname} ${record.name}`} />
    </ReferenceField>
    <ReferenceField
      basePath="/passes"
      label="Pass"
      source="user"
      reference="users"
      sortable={false}
    >
      <PassReferenceField source="activePass" />
    </ReferenceField>
    <TextField source="points" />
  </ListGuesser>
);

export default LeaderboardsList;
