import EventIcon from '@material-ui/icons/EmojiEvents';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import WinnerCreate from './WinnerCreate';
import WinnerEdit from './WinnerEdit';
import WinnerList from './WinnerList';
import WinnerShow from './WinnerShow';
import { isAdmin } from '../utils';

const WinnerResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={WinnerList}
      show={WinnerShow}
      create={WinnerCreate}
      edit={WinnerEdit}
      icon={EventIcon}
    />
  );
};
WinnerResource.defaultProps = {
  name: 'winners',
};

export default WinnerResource;
