import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput, SelectInput, NumberInput } from 'react-admin';

import { validateEmail, validateRequired } from './validators';

const SellerCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="email" label="Email" validate={validateEmail} />
        <TextInput source="code" label="Code" validate={validateRequired} />
        <NumberInput source="discount" label="Discount" />
        <SelectInput
          source="discountType"
          choices={[
            { id: 'P', name: '%' },
            { id: 'V', name: '€' },
          ]}
        />
        <BooleanInput label="Enable" source="enable" initialValue={true} />
      </SimpleForm>
    </Create>
  );
};

export default SellerCreate;
