import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import MoreIcon from '@material-ui/icons/SportsSoccer';

const useStyles = makeStyles(() => ({
  op: {
    backgroundColor: '#bcbcff',
  },
  gk: {
    backgroundColor: '#bcffbc',
  },
  extra: {
    backgroundColor: '#dbdbdb',
  },
}));

const PlayerRoleField = ({ record = {}, source }) => {
  const classes = useStyles();
  return ['OP', 'movement'].includes(record[source]) ? (
    <Tooltip title="Giocatore di movimento">
      <DirectionsRunIcon className={classes.op} />
    </Tooltip>
  ) : 'extra' === record[source] ? (
    <Tooltip title="Extra">
      <MoreIcon className={classes.extra} />
    </Tooltip>
  ) : (
    <Tooltip title="Portiere">
      <SportsHandballIcon className={classes.gk} />
    </Tooltip>
  );
};

PlayerRoleField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

PlayerRoleField.defaultProps = {
  addLabel: true, // to enable it in the "show" view
};

export default PlayerRoleField;
