import React from 'react';
import { Button, useNotify, FileInput, FileField, ReferenceInput, SelectInput } from 'react-admin';
import DialogForm from '../DialogForm';
import Icon from '@material-ui/icons/CloudUpload';
import { Box } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import authProvider from '../authProvider';
import { resourceId } from '../utils';
import { fetchOptions } from '../dataProvider';
import { getPartnershipDisplayName } from '../partnership/utils';

const SponsorCodeImportButton = ({ disabled }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(false);
  const [partnershipId, setPartnershipId] = useState(null);
  const notify = useNotify();

  const handleInit = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async (data) => {
    if (!authProvider.checkExistingToken()) {
      notify('Login expired, please authenticate again', 'warning');
      return;
    }

    if (!file) {
      notify('Please upload a file', 'warning');
      return;
    }

    const formData = new FormData();
    formData.set('upload', data.upload.rawFile, data.upload.title);

    const query = new URLSearchParams();
    query.append('partnershipId', partnershipId);

    const response = await fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/sponsor_codes/import` +
        (partnershipId ? '?' + query.toString() : ''),
      fetchOptions({ body: formData, method: 'POST' })
    );

    if (response.ok) {
      notify(`File uploaded, the import is underway.
      You'll be notified via email once the operation ends.`);
      setOpen(false);
    } else {
      notify('Unable to complete operation', 'warning');
    }
  };

  return (
    <>
      <Button disabled={disabled} label="Import" onClick={handleInit}>
        <Icon />
      </Button>
      <DialogForm
        open={open}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        title="Import sponsor-codes as csv file"
        submitLabel="Import"
      >
        <Box display="flex" flexDirection="column">
          <FileInput
            source="upload"
            label="CSV file"
            accept="text/csv"
            onChange={(data) => setFile(data)}
            placeholder={
              <p>
                Trascina qui un file in formato csv contenente i vari sponsorCodes, o clicca qui per
                selezionarlo
              </p>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
          <ReferenceInput
            label="Partnership"
            source="partnership"
            reference="partnerships"
            onChange={(event) => setPartnershipId(resourceId(event.target.value))}
          >
            <SelectInput optionText={(record) => getPartnershipDisplayName(record)} />
          </ReferenceInput>
        </Box>
      </DialogForm>
    </>
  );
};

SponsorCodeImportButton.propTypes = {
  disabled: PropTypes.bool,
};

export default SponsorCodeImportButton;
