import React from 'react';

import { BooleanField, ReferenceField, FunctionField, ChipField, CreateButton } from 'react-admin';
import { MasterclassFilter } from './MasterclassFilter';
import { ROLE_ADMIN, ROLE_EDITOR, tournamentOptionText } from '../utils';
import MatView from '../materialized-views/mat-view.utils';
import PropTypes from 'prop-types';
import { PlayerRoleField } from '../fields';

const MasterclassList = ({ permissions, ...props }) => {
  return (
    <MatView.List
      {...props}
      title="List of masterclass"
      perPage={25}
      bulkActionButtons={false}
      filters={<MasterclassFilter />}
      actions={<CreateButton />}
    >
      <MatView.DisclaimerField></MatView.DisclaimerField>
      <MatView.LastUpdatedField
        source="updatedAt"
        label="Ultimo aggiornamento"
      ></MatView.LastUpdatedField>
      <ReferenceField label="Torneo" source="tournament" reference="tournaments" sortable={false}>
        <FunctionField
          render={(record) => (
            <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
          )}
        />
      </ReferenceField>
      <PlayerRoleField source="category" />
      <BooleanField source="complete" />
      <BooleanField source="disabled" />
      <MatView.Button action={MatView.buttonActions.show} permissions={permissions} />
      <MatView.Button
        action={MatView.buttonActions.edit}
        acl={[ROLE_ADMIN, ROLE_EDITOR]}
        permissions={permissions}
      />
    </MatView.List>
  );
};

MasterclassList.propTypes = {
  permissions: PropTypes.object,
  props: PropTypes.any,
};

export default MasterclassList;
