import React from 'react';
import {
  Button,
  // TextInput,
  useNotify,
  NumberInput,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
} from 'react-admin';
import DialogForm from '../DialogForm';
import Icon from '@material-ui/icons/CloudUpload';
import { Box } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import authProvider from '../authProvider';
import { resourceId } from '../utils';
import InfoBox from '../InfoBox';
import { fetchOptions } from '../dataProvider';

const TicketImportButton = ({ disabled }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(false);
  const [codeChallengeLength, setCodeChallengeLength] = useState(undefined);
  const [productId, setProductId] = useState(null);
  const [partnershipId, setPartnershipId] = useState(null);
  const notify = useNotify();

  const handleInit = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async (data) => {
    if (!authProvider.checkExistingToken()) {
      notify('Login expired, please authenticate again', 'warning');
      return;
    }
    if (!file) {
      notify('Please upload a file', 'warning');
      return;
    }

    const formData = new FormData();
    formData.set('upload', data.upload.rawFile, data.upload.title);

    const query = new URLSearchParams();
    query.append('productId', productId);
    query.append('codeChallengeLength', codeChallengeLength);
    if (partnershipId) {
      query.append('partnershipId', partnershipId);
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/tickets/import?` + query.toString(),
      fetchOptions({ body: formData, method: 'POST' })
    );

    if (response.ok) {
      notify(`File uploaded, the import is underway.
            You'll be notified via email once the operation ends.`);
      setOpen(false);
    } else {
      notify('Unable to complete operation', 'warning');
    }
  };

  return (
    <>
      <Button disabled={disabled} label="Import" onClick={handleInit}>
        <Icon />
      </Button>
      <DialogForm
        open={open}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        title="Generate any amount of redeemable tickets"
        submitLabel="Import"
      >
        <Box display="flex" flexDirection="column">
          <FileInput
            source="upload"
            label="CSV file"
            accept="text/csv"
            onChange={(data) => setFile(data)}
            placeholder={
              <p>
                Trascina qui un file in formato csv contenente i vari codici esterni, o clicca qui
                per selezionarlo
              </p>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
          <NumberInput
            required
            source="codeChallengeLength"
            label="Lunghezza codice esterno"
            value={codeChallengeLength}
            onChange={(event) => setCodeChallengeLength(parseInt(event.target.value))}
          />
          <InfoBox
            text={
              "Il valore soprastante verrà usato per controllare che tutti i codici esterni siano della stessa lunghezza, eventuali errori annulleranno l'operazione"
            }
          />
          <ReferenceInput
            label="Product"
            source="product"
            reference="products"
            onChange={(event) => setProductId(resourceId(event.target.value))}
            filter={{ enable: true }}
          >
            <SelectInput required optionText={(record) => `${record.title} [${record.type}]`} />
          </ReferenceInput>
          <ReferenceInput
            label="Partnership"
            source="partnership"
            reference="partnerships"
            onChange={(event) => setPartnershipId(resourceId(event.target.value))}
          >
            <SelectInput optionText={(record) => `${record.name} [${record.partner.name}]`} />
          </ReferenceInput>
        </Box>
      </DialogForm>
    </>
  );
};

TicketImportButton.propTypes = {
  disabled: PropTypes.bool,
};

export default TicketImportButton;
