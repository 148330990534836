import UserIcon from '@material-ui/icons/People';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import PreOrderBuyerList from './preOrderBuyerList';
import PreOrderBuyerShow from './preOrderBuyerShow';
import { isAdmin } from '../utils';

const PreOrderBuyerResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions)) {
    return <Resource {...props} />;
  }

  return <Resource {...props} list={PreOrderBuyerList} show={PreOrderBuyerShow} icon={UserIcon} />;
};
PreOrderBuyerResource.defaultProps = {
  name: 'pre_order_buyers',
};

export default PreOrderBuyerResource;
