import React from 'react';
import { Chip, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { splitCamelCaseString } from './utils';

const ColorField = ({ source, record }) => {
  const fieldName = splitCamelCaseString(source)
    .map((s, i) => (i === 0 ? `${s[0].toUpperCase()}${s.substring(1)}` : s))
    .join(' ');

  return (
    <Box>
      <Typography className={['MuiFormLabel-root', 'MuiInputLabel-shrink']}>{fieldName}</Typography>
      <Box display="flex" alignItems="center" padding="8px 0 0">
        <Chip
          key={source}
          style={{
            background: record[source],
            height: '40px',
            width: '40px',
            borderRadius: '100%',
            marginRight: '12px',
          }}
          label=""
        />
        <Typography className={['MuiInputLabel-shrink']}>{record[source]}</Typography>
      </Box>
    </Box>
  );
};
ColorField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
};

export default ColorField;
