import UserIcon from '@material-ui/icons/People';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdmin, isEditor, isScout } from '../utils';
import UsersEdit from './UsersEdit';
import UsersList from './UsersList';
import UsersShow from './UsersShow';
import UsersCreate from './UsersCreate';

const UsersResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdmin(permissions)) {
    return (
      <Resource
        {...props}
        list={UsersList}
        show={UsersShow}
        create={UsersCreate}
        edit={UsersEdit}
        icon={UserIcon}
      />
    );
  }

  if (isScout(permissions) || isEditor(permissions)) {
    return <Resource {...props} list={UsersList} show={UsersShow} icon={UserIcon} />;
  }

  return <Resource {...props} />;
};
UsersResource.defaultProps = {
  name: 'users',
};

export default UsersResource;
