import { defaultTheme } from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';
import merge from 'lodash/merge';

export const theme = createMuiTheme(
  merge({}, defaultTheme, {
    palette: {
      primary: {
        main: '#1d1d1b',
      },
      secondary: {
        main: '#1d1d1b',
      },
    },
  })
);
