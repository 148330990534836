import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { Filter, TextField, TextInput, ReferenceField, FunctionField } from 'react-admin';
import SponsorCodeListActions from './SponsorCodeListActions';
import { getPartnershipDisplayName } from '../partnership/utils';

const SponsorCodeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Code" source="code" alwaysOn />
    <TextInput label="Search surname" source="userSponsorCode.owner.surname" alwaysOn />
    <TextInput label="Search name" source="userSponsorCode.owner.name" alwaysOn />
    <TextInput label="Search phone" source="userSponsorCode.owner.email" alwaysOn />
    <TextInput label="Search email" source="userSponsorCode.owner.phone" alwaysOn />
  </Filter>
);

const SponsorCodeList = (props) => (
  <ListGuesser
    {...props}
    title="List of Promo Code"
    perPage={25}
    filters={<SponsorCodeFilter />}
    bulkActionButtons={false}
    actions={<SponsorCodeListActions />}
  >
    <TextField source="code" label="Code" />
    <ReferenceField source="partnership" label="Partnership" reference="partnerships">
      <FunctionField render={(record) => getPartnershipDisplayName(record)} />
    </ReferenceField>
  </ListGuesser>
);

export default SponsorCodeList;
