export const PassTypes = [
  { id: 'GENERAL', name: 'General' },
  { id: 'MONTHLY', name: 'Monthly' },
  { id: 'CHALLENGE', name: 'Challenge' },
  { id: 'EXTRA', name: 'Extra' },
  { id: 'STAGE', name: 'Fase' },
];

export default {
  PassTypes,
};
