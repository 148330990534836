import React from 'react';
import { DateInput, Filter, SelectInput, TextInput, useGetList } from 'react-admin';
import PropTypes from 'prop-types';
import { isAdmin, isEditor, resourceId } from '../utils';
import { PartnerField } from '../partner/utils';

const canEdit = (permissions) => isAdmin(permissions) || isEditor(permissions);

const PartnershipFilter = ({ permissions, ...props }) => {
  const { data: partners, ids: partnerIds } = useGetList('partners', {}, {}, { pagination: false });

  return (
    <Filter {...props}>
      <TextInput label="Search name" source="name" alwaysOn />
      {canEdit(permissions) && (
        <SelectInput
          alwaysOn
          label="Stato"
          source="visible"
          allowEmpty={true}
          choices={[
            { id: true, name: 'Attivo' },
            { id: false, name: 'Cancellato' },
          ]}
        />
      )}
      <SelectInput
        alwaysOn
        label="Partner"
        source="partner"
        choices={partnerIds.map((id) => partners[id])}
        optionText={<PartnerField />}
        allowEmpty={true}
        translateChoice={false}
        onChange={(input) => {
          if (input.target.value)
            props.setFilters({
              ...props.filterValues,
              partner: { id: resourceId(input.target.value) },
            });
        }}
      />
      <TextInput label="Sign-up flow" source="signupFlow" alwaysOn />
      <DateInput label="Comincia il" source="startDate[strictly_after]" />
      <DateInput label="Termina il" source="endDate[strictly_before]" />
      <DateInput label="Registrato dal" source="createdAt[strictly_after]" />
      <DateInput label="Registrato al" source="createdAt[strictly_before]" />
    </Filter>
  );
};
PartnershipFilter.propTypes = {
  setFilters: PropTypes.func,
  filterValues: PropTypes.object,
  permissions: PropTypes.array,
};

export default PartnershipFilter;
