import ListIcon from '@material-ui/icons/List';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import {isAdvisor, isScoutPlay2Give} from '../utils';
import LeaderboardsList from './LeaderboardsList';

const LeaderboardsResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdvisor(permissions) || isScoutPlay2Give(permissions)) {
    return <Resource {...props} />;
  }

  return <Resource {...props} list={LeaderboardsList} icon={ListIcon} />;
};
LeaderboardsResource.defaultProps = {
  name: 'leaderboards',
};

export default LeaderboardsResource;
