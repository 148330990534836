import PropTypes from 'prop-types';
import React from 'react';
import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  ReferenceInput,
  NumberInput,
  AutocompleteInput,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';

import { validateOwner, validateRequired } from './validators';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const WinnerEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceInput
          label="User"
          source="owner"
          reference="users"
          validate={validateOwner}
          filterToQuery={(searchText) => ({ search: searchText, role: 'PLAYER', visible: true })}
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(record) => `${record.surname} ${record.name} ${record.phone || ''}`}
          />
        </ReferenceInput>
        <NumberInput source="place" label="Posizione" min={1} validate={validateRequired} />
        <JsonInput
          source="extra"
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default WinnerEdit;
