import React from 'react';
import { cloneElement } from 'react';
import {
  BooleanField,
  Button,
  DateField,
  DateInput,
  EmailField,
  Filter,
  SelectInput,
  TextField,
  TextInput,
  useMutation,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  usePermissions,
  ReferenceField,
  useGetList,
  SelectArrayInput,
} from 'react-admin';
import PropTypes from 'prop-types';
import { VisibilityOff, Visibility } from '@material-ui/icons';

import { GenderField, RolesField } from '../fields';
import {
  isAdmin,
  isEditor,
  isScout,
  isScoutPlay2Give,
  OperatingSystems,
  ROLE_ADMIN,
} from '../utils';
import ExportButton from '../ExportButton';
import { UserAgeField, UserVideoCount } from './users.util';
import PassReferenceField from '../PassReferenceField';
import { PassTypes } from '../passes/passes.util';
import OperatingSystemsField from '../OperatingSystemsField';
import {
  PartnershipField,
  PartnershipSignupFlowArrayField,
  PartnershipSignupFlowSelectInput,
} from '../partnership/utils';
import MatView, { parseMaterializedViewRecordId } from '../materialized-views/mat-view.utils';
import InfoBox from '../InfoBox';

const ListActions = ({ className, filters, ...props }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  const { permissions } = usePermissions();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(props)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {isAdmin(permissions) && <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        targetClass={'App\\Entity\\UserView'}
        filters={filterValues}
      />
      {isAdmin(permissions) && (
        <MatView.Button
          resource={'App\\Entity\\UserView'}
          action={MatView.buttonActions.refresh}
          title="Aggiornamento vista utenti"
          description={
            <>
              <InfoBox
                level="info"
                text={`<h3>Informazioni</h3>
                <p>
                  Il comando "Refresh" permette di aggiornare manualmente le viste pre-compilate e di vederne i dati più recenti a fine aggiornamento.
                  <h4>La vista utenti viene aggiornata ogni 12h (ore):</h4>
                  <ul><li>1am (mattina)</li><li>1pm (pomeriggio)</li></ul>
                  <strong>L'aggiornamento della vista non sarà immediato</strong>, tipicamente l'operazione dura tra i 5 e 10 minuti.
                </p>`}
              ></InfoBox>
              <InfoBox
                level="warning"
                text={`<h3>Precauzioni</h3>
                <p>Questa operazione è un abbastanza complessa e consuma molte risorse del server, 
                si <strong>sconsiglia</strong> di aggiornare continuamente le viste, tale comportamento potrebbe risultare in rallentamenti o disservizi.</p>
                <strong>Non eseguire il refresh durante gli orari prestabiliti di aggiornamento!</strong>`}
              ></InfoBox>
            </>
          }
        />
      )}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.object,
  permissions: PropTypes.object,
};

const BulkHideButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    'users',
    selectedIds,
    { visible: false },
    {
      onSuccess: () => {
        refresh();
        notify('Users nascosti');
        unselectAll('users');
      },
      onFailure: () => notify('Errore: users non nascosti', 'warning'),
    }
  );
  return (
    <Button
      label="Nascondi"
      onClick={updateMany}
      disabled={loading}
      startIcon={<VisibilityOff />}
    ></Button>
  );
};
BulkHideButton.propTypes = {
  selectedIds: PropTypes.array,
};

const VisibilityToggleButton = ({ record }) => {
  const [toggleVisibility, { loading, error }] = useMutation({
    type: 'update',
    resource: 'users',
    payload: { id: parseMaterializedViewRecordId(record.id), data: { visible: !record.visible } },
  });
  return (
    <Button
      label={record.visible ? 'Nascondi' : 'Rendi visibile'}
      onClick={toggleVisibility}
      disabled={loading || error}
      startIcon={record.visible ? <VisibilityOff /> : <Visibility />}
    />
  );
};
VisibilityToggleButton.propTypes = {
  record: PropTypes.object,
};

const UsersFilter = ({ permissions, partnerships, ...props }) => (
  <Filter {...props}>
    <TextInput label="Search surname" source="surname" alwaysOn />
    <TextInput label="Search name" source="name" alwaysOn />
    {isAdmin(permissions) && <TextInput label="Phone number" source="phone" alwaysOn />}
    {isAdmin(permissions) && (
      <SelectInput
        alwaysOn
        label="Operating System"
        source="pushTokens.type"
        allowEmpty={true}
        choices={Object.entries(OperatingSystems).map(([key, value]) => {
          return { id: key, name: value.toUpperCase() };
        })}
      />
    )}
    {isAdmin(permissions) && <TextInput label="Email" source="email" alwaysOn />}
    {isAdmin(permissions) && (
      <SelectInput
        alwaysOn
        label="Stato email"
        source="verifiedEmail"
        allowEmpty={true}
        choices={[
          { id: true, name: 'Verificata' },
          { id: false, name: 'Non verificata' },
        ]}
      />
    )}
    {isAdmin(permissions) && (
      <SelectInput
        alwaysOn
        label="Stato telefono"
        source="verifiedPhone"
        allowEmpty={true}
        choices={[
          { id: true, name: 'Verificato' },
          { id: false, name: 'Non verificato' },
        ]}
      />
    )}
    {isAdmin(permissions) && (
      <SelectInput
        alwaysOn
        label="Stato utente"
        source="visible"
        allowEmpty={true}
        choices={[
          { id: true, name: 'Attivo' },
          { id: false, name: 'Cancellato' },
        ]}
      />
    )}
    {!isScoutPlay2Give(permissions) && (
      <SelectInput
        alwaysOn
        label="Genere"
        source="gender"
        allowEmpty={true}
        choices={[
          { id: 'M', name: 'Maschio' },
          { id: 'F', name: 'Femmina' },
        ]}
      />
    )}
    {!isScoutPlay2Give(permissions) && (
      <SelectInput
        alwaysOn
        label="Ruolo"
        source="role"
        allowEmpty={true}
        onChange={(input) => {
          props.setFilters({
            ...props.filterValues,
            role: input.target.value,
            premium: input.target.value === 'PLAYER' ? props.filterValues.premium : null,
          });
        }}
        choices={[
          { id: 'USER', name: 'Spettatore' },
          { id: 'PLAYER', name: 'Giocatore' },
          { id: 'SCOUT', name: 'Scout' },
          { id: 'ADMIN', name: 'Admin' },
          { id: 'EDITOR', name: 'Editor' },
        ]}
      />
    )}
    <SelectInput
      alwaysOn
      label="Premium"
      source="premium"
      disabled={props.filterValues.role !== 'PLAYER'}
      allowEmpty={true}
      initialValue={null}
      choices={[
        { id: true, name: 'Yes' },
        { id: false, name: 'No' },
      ]}
    />
    {(isEditor(permissions) || isAdmin(permissions)) && (
      <SelectInput
        alwaysOn
        label="Ha acquistato FP"
        source="hasEverBeenPremium"
        helperText={
          "Mostra tutti gli utenti che hanno eseguito l'upgrade a FP almeno una volta negli ultimi 2 anni"
        }
        allowEmpty={true}
        initialValue={null}
        choices={[{ id: true, name: 'Yes' }]}
      />
    )}
    {!isScoutPlay2Give(permissions) && (
      <PartnershipSignupFlowSelectInput source={'signupFlow'} label={'Signup flow'} alwaysOn />
    )}
    {(isAdmin(permissions) || isEditor(permissions)) && (
      <SelectArrayInput
        label="Attributi"
        style={{ minWidth: '100px' }}
        source="attributes"
        translateChoice={false}
        choices={partnerships ?? []}
        optionValue="signupFlow"
        optionText={(record) => <PartnershipField record={record} />}
        onChange={(input) => {
          props.setFilters({
            ...props.filterValues,
            attributes: input.target.value,
          });
        }}
        alwaysOn
      ></SelectArrayInput>
    )}
    {(isAdmin(permissions) || isEditor(permissions)) && (
      <TextInput label="Provincia di interesse" source="preferredProvince" alwaysOn />
    )}
    <SelectInput
      alwaysOn
      label="Candidato"
      source="isPotentialCandidate"
      allowEmpty={true}
      initialValue={null}
      choices={[
        { id: true, name: 'Yes' },
        { id: false, name: 'No' },
      ]}
    />
    <SelectInput
      alwaysOn
      label="Pass"
      source="activePass.type"
      allowEmpty={true}
      choices={PassTypes}
    />
    <SelectInput
      alwaysOn
      label="N° Video"
      source="videoCount"
      allowEmpty={true}
      initialValue={null}
      choices={[
        { id: 1, name: 'Almeno 1' },
        { id: 0, name: 'Nessuno' },
      ]}
      {...props}
    />
    {(isAdmin(permissions) || isEditor(permissions)) && (
      <SelectInput
        alwaysOn
        label="SkillTale"
        source="custom-filter_skillTaleId"
        allowEmpty={true}
        choices={[
          { id: 'true', name: 'Partecipa' },
          { id: 'false', name: 'Non partecipa' },
        ]}
        onChange={(input) => {
          let value = !input.target.value ? undefined : input.target.value === 'true';
          props.setFilters({
            ...props.filterValues,
            'custom-filter_skillTaleId': value,
            exists: { skillTaleId: value },
          });
        }}
      />
    )}
    <DateInput label="Registrato dal" source="createdAt[strictly_after]" />
    <DateInput label="Registrato al" source="createdAt[strictly_before]" />
    <DateInput label="Visualizzato al" source="lastScoutView[before]" />
    <DateInput label="Visualizzato dal" source="lastScoutView[after]" />
  </Filter>
);
UsersFilter.propTypes = {
  setFilters: PropTypes.func,
  filterValues: PropTypes.object,
  permissions: PropTypes.object,
  partnerships: PropTypes.array,
};

const LastViewDateField = (props) => {
  return (
    <ReferenceField source="profile" reference="profiles" link="show" {...props}>
      <DateField source="lastScoutView" />
    </ReferenceField>
  );
};

const IsPotentialCandidate = (props) => {
  return (
    <ReferenceField source="profile" reference="profiles" link="show" {...props}>
      <BooleanField source="isPotentialCandidate" />
    </ReferenceField>
  );
};

const UsersList = ({ permissions, ...props }) => {
  const request = useGetList(
    'partnerships',
    {},
    {},
    {
      pagination: false,
      active: true,
    }
  );

  return (
    <MatView.List
      {...props}
      title={'List of users'}
      perPage={25}
      filters={
        <UsersFilter
          permissions={permissions}
          partnerships={Object.entries(request?.data ?? {}).map((e) => e[1])}
          {...props}
        />
      }
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={<ListActions />}
      bulkActionButtons={isAdmin(permissions) ? <BulkHideButton /> : false}
    >
      <TextField source="surname" />
      <TextField source="name" />
      {isAdmin(permissions) && <TextField source="phone" />}
      {isAdmin(permissions) && <OperatingSystemsField label="OS" source="platforms" />}
      {isAdmin(permissions) && (
        <BooleanField source="verifiedPhone" label="Telefono verificato" sortable={false} />
      )}
      {isAdmin(permissions) && <EmailField source="email" />}
      {isAdmin(permissions) && (
        <BooleanField source="verifiedEmail" label="Email verificata" sortable={false} />
      )}
      <RolesField source="roles" sortable={false} />
      {!isEditor(permissions) && !isScout(permissions) && <DateField source="birthDate" />}
      {isScout(permissions) && <UserAgeField source="birthDate" label="Age" />}
      <GenderField source="gender" sortable={false} />
      <DateField source="createdAt" />
      <PassReferenceField label="Pass" source="activePass" />
      <UserVideoCount label="Video" />
      {isScout(permissions) && (
        <LastViewDateField
          label="Last view"
          options={{ year: 'numeric', month: 'long', day: 'numeric' }}
        />
      )}
      {(isScout(permissions) || isAdmin(permissions)) && <IsPotentialCandidate label="Potential" />}
      {(isEditor(permissions) || isAdmin(permissions)) && (
        <BooleanField source="hasEverBeenPremium" label="Was ever premium" />
      )}
      {isAdmin(permissions) && <BooleanField source="test" sortable={false} />}
      {isAdmin(permissions) && <BooleanField source="visible" sortable={false} />}
      <TextField source="signupFlow" />
      <PartnershipSignupFlowArrayField
        source="attributes"
        label="Attributi attivi"
        data={request?.data}
        loading={request?.loading}
        error={request?.error}
      />
      {(isAdmin(permissions) || isEditor(permissions)) && (
        <TextField label="Provincia di interesse" source="preferredProvince" />
      )}
      {isAdmin(permissions) && <VisibilityToggleButton />}
      <MatView.Button
        resource={'users'}
        action={MatView.buttonActions.show}
        permissions={permissions}
      />
      <MatView.Button
        resource={'users'}
        action={MatView.buttonActions.edit}
        acl={[ROLE_ADMIN]}
        permissions={permissions}
      />
    </MatView.List>
  );
};

UsersList.propTypes = {
  permissions: PropTypes.object,
  props: PropTypes.any,
};

export default UsersList;
