import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { TextField, NumberField, DateField } from 'react-admin';
import { ActivityLogStatusField } from './util';
import ActivityLogFilters from './ActivityLogFilters';

const ActivityLogList = (props) => (
  <ListGuesser
    {...props}
    title="Server activity logs"
    perPage={25}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ActivityLogFilters />}
  >
    <NumberField source="id" label="ID" />
    <TextField source="handler" label="Task" />
    <ActivityLogStatusField label="Status" />
    <DateField source="createdAt" label="Begin date" showTime={true} />
  </ListGuesser>
);

export default ActivityLogList;
