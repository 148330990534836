import React from 'react';
import {
  ChipField,
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

const BansShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField
        label="User"
        source="target"
        reference="users"
        link="show"
      >
        <FunctionField render={(record) => `${record.surname} ${record.name}`} />
      </ReferenceField>
      <ReferenceField label="User" source="target" reference="users" link="show">
        <ChipField source="email" />
      </ReferenceField>
      <ReferenceField label="Phone" source="target" reference="users" link="show">
        <ChipField source="phone" />
      </ReferenceField>
      <TextField source="reason" />
      <DateField source="until" />
    </SimpleShowLayout>
  </Show>
);

export default BansShow;
