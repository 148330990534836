import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const SellerShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="email" label="Email" />
      <TextField source="code" label="Code" />
      <TextField source="discount" label="Discount" />
    </SimpleShowLayout>
  </Show>
);

export default SellerShow;
