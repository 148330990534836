import React from 'react';
import { Show, BooleanField, TextField, DateField, NumberField, RichTextField } from 'react-admin';
import { isAdmin, isEditor } from '../utils';
import PropTypes from 'prop-types';
import { UserBenefitJsonContentShowUI, UserBenefitTypeField } from './util';
import FormGrid, { FormGridItem } from '../FormGrid';
import { PartnershipReferenceField } from '../partnership/utils';

const hasPermissions = (permissions) => isEditor(permissions) || isAdmin(permissions);

const UserBenefitShow = ({ permissions, ...props }) => {
  return (
    <Show {...props}>
      <FormGrid spacing={2} style={{ width: '100%' }}>
        <FormGridItem xs={6} style={{ height: '100%' }}>
          <TextField source="name" />
          <PartnershipReferenceField label="Partnership" source="partnership" />
          <RichTextField source="description" />
          <UserBenefitTypeField />
          <DateField source="startDate" />
          <DateField source="endDate" />
          <NumberField source="supply" label="Slot totali" />
          <NumberField source="availableSupply" label="Slot disponibili" />
          <DateField source="startDate" />
          <DateField source="endDate" />
          <BooleanField source="visible" label="Monouso" />
          <BooleanField label="Main" source="main" />
          {hasPermissions(permissions) && <BooleanField source="visible" addLabel={true} />}
          <DateField source="createdAt" />
          {hasPermissions(permissions) && <DateField source="updatedAt" />}
        </FormGridItem>

        <FormGridItem xs={6} style={{ height: '100%' }}>
          <UserBenefitJsonContentShowUI
            source="content"
            label="Settings"
            style={{
              height: '100%',
              paddingLeft: '30px',
              borderLeft: 'solid 5px #f5f5f5',
            }}
          />
        </FormGridItem>
      </FormGrid>
    </Show>
  );
};
UserBenefitShow.propTypes = {
  permissions: PropTypes.object,
  props: PropTypes.any,
};

export default UserBenefitShow;
