import React from 'react';
import {
  BooleanInput,
  DateInput,
  TextInput,
  SelectInput,
  NumberInput,
  Edit,
  SimpleForm,
  TabbedForm,
  FormTab,
  SaveButton,
  Toolbar,
  Loading,
  Error,
  useGetOne,
  useDataProvider,
  useNotify,
} from 'react-admin';
import PropTypes from 'prop-types';

import {
  validateEmail,
  validateName,
  validateSurname,
  validateBirthDate,
  validateProfileBio,
  validateProfileWeight,
  validateProfileHeight,
  validateProfileFoot,
  validateProfileFigcCard,
  validatePhone,
} from './validators';
import AsideView from '../asideView';

import { UsersTitle } from './UserTitle';
import { PartnershipSignupFlowSelectInput } from '../partnership/utils';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const FetchRelated = ({ record, save, reference, source, secondRecordName, children }) => {
  const { data, loading, error } = useGetOne(reference, record[source]);
  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;
  record[secondRecordName] = data;

  const childrenWithExtraProp = React.Children.map(children, (child) =>
    React.cloneElement(child, { record, save })
  );
  return <React.Fragment>{childrenWithExtraProp}</React.Fragment>;
};
FetchRelated.propTypes = {
  record: PropTypes.object.isRequired,
  save: PropTypes.any.isRequired,
  reference: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  secondRecordName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const ProfileExistanceConditional = ({ record, save, source, dontexists, exists }) => {
  if (record[source]) return React.cloneElement(exists, { record, save });
  return React.cloneElement(dontexists, { record, save });
};

const UsersEdit = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  return (
    <Edit
      aside={<AsideView />}
      title={<UsersTitle />}
      {...props}
      transform={async (data) => {
        if (!data.profile) return data;
        try {
          await dataProvider.update('profiles', { id: data.profile, data: data.secondRecord });
        } catch (error) {
          notify(`Errore inatteso: ${error.message}`, 'warning');
        }
        delete data.secondRecord;
        return data;
      }}
    >
      <ProfileExistanceConditional
        source="profile"
        dontexists={
          <SimpleForm toolbar={<EditToolbar />}>
            <TextInput source="phone" validate={validatePhone} />
            <TextInput source="email" type="email" validate={validateEmail} />
            <TextInput source="name" validate={validateName} />
            <TextInput source="surname" validate={validateSurname} />
            <DateInput source="birthDate" validate={validateBirthDate} />
            <SelectInput
              source="gender"
              choices={[
                { id: 'M', name: 'Maschio' },
                { id: 'F', name: 'Femmina' },
              ]}
            />
            <PartnershipSignupFlowSelectInput source="signupFlow" />
            <TextInput source="preferredProvince" />
            <BooleanInput source="test" />
          </SimpleForm>
        }
        exists={
          <FetchRelated source="profile" reference="profiles" secondRecordName="secondRecord">
            <TabbedForm toolbar={<EditToolbar />}>
              <FormTab label="Utente">
                <TextInput source="phone" validate={validatePhone} />
                <TextInput source="email" type="email" validate={validateEmail} />
                <TextInput source="name" validate={validateName} />
                <TextInput source="surname" validate={validateSurname} />
                <DateInput source="birthDate" validate={validateBirthDate} />
                <SelectInput
                  source="gender"
                  choices={[
                    { id: 'M', name: 'Maschio' },
                    { id: 'F', name: 'Femmina' },
                  ]}
                />
                <PartnershipSignupFlowSelectInput source="signupFlow" />
                <BooleanInput source="test" />
              </FormTab>
              <FormTab label="Profilo">
                <TextInput source="secondRecord.bio" label="Bio" validate={validateProfileBio} />
                <NumberInput
                  min={40}
                  max={250}
                  source="secondRecord.weight"
                  label="Peso (kg)"
                  validate={validateProfileWeight}
                />
                <NumberInput
                  min={100}
                  max={250}
                  source="secondRecord.height"
                  label="Altezza (cm)"
                  validate={validateProfileHeight}
                />
                <SelectInput
                  source="secondRecord.role"
                  label="Ruolo"
                  choices={[
                    { id: 'GK', name: 'Portiere' },
                    { id: 'DF', name: 'Difensore' },
                    { id: 'MF', name: 'Mediano' },
                    { id: 'FW', name: 'Attaccante' },
                  ]}
                />
                <TextInput
                  source="secondRecord.foot"
                  label="Piede"
                  validate={validateProfileFoot}
                />
                <TextInput
                  source="secondRecord.figcCard"
                  label="FIGC"
                  validate={validateProfileFigcCard}
                />
                <TextInput source="secondRecord.kitSize" label="Taglia kit" />
              </FormTab>
            </TabbedForm>
          </FetchRelated>
        }
      />
    </Edit>
  );
};

export default UsersEdit;
