import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import {
  ChipField,
  DateField,
  Filter,
  FunctionField,
  ReferenceField,
  TextInput,
} from 'react-admin';

const UserSponsorCodeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Code" source="sponsorCode.code" alwaysOn />
    <TextInput label="Search surname" source="owner.surname" alwaysOn />
    <TextInput label="Search name" source="owner.name" alwaysOn />
    <TextInput label="Search phone" source="owner.email" alwaysOn />
    <TextInput label="Search email" source="owner.phone" alwaysOn />
  </Filter>
);

const UserSponsorCodeList = (props) => (
  <ListGuesser
    {...props}
    title="List of User Sponsor Code"
    perPage={25}
    filters={<UserSponsorCodeFilter />}
    bulkActionButtons={false}
  >
    <ReferenceField source="sponsorCode" reference="sponsor_codes" sortable={false} link="show">
      <ChipField source="code" />
    </ReferenceField>
    <ReferenceField source="owner" reference="users" sortable={false} link="show">
      <FunctionField render={(record) => `${record.surname} ${record.name}`} />
    </ReferenceField>
    <DateField source="expiredAt" />
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
  </ListGuesser>
);

export default UserSponsorCodeList;
