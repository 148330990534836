import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  useQueryWithStore,
  Loading,
  Error,
  FunctionField,
  BooleanInput,
} from 'react-admin';
import { Box } from '@material-ui/core';
import OpenIcon from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import InfoBox from '../InfoBox';

export const PartnerField = ({ record }) => {
  const { loaded, error, data: media } = useQueryWithStore({
    type: 'getOne',
    resource: 'media',
    payload: { id: record?.icon },
  });

  if (record.icon) {
    if (error) {
      return <Error />;
    }
    if (!loaded || !media) {
      return <Loading />;
    }
  }

  if (!record) return null;

  return (
    <Box display="flex" alignItems="center">
      {media != null && (
        <img
          src={media.path}
          alt="partner icon"
          style={{ height: '30px', aspectRatio: 1, borderRadius: '100%' }}
        />
      )}
      <TextField source="name" record={record} />
    </Box>
  );
};
PartnerField.propTypes = {
  record: PropTypes.object,
  props: PropTypes.any,
};

export const PartnerReferenceField = ({ source, reference, label, ...props }) => {
  return (
    <ReferenceField
      label={label ?? 'Partner'}
      source={source ?? 'partner'}
      reference={reference ?? 'partners'}
      link="show"
      sortable={false}
      {...props}
    >
      <FunctionField render={(record) => <PartnerField record={record} />} />
    </ReferenceField>
  );
};
PartnerReferenceField.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  label: PropTypes.string,
};

export const PartnerSelectInput = ({ source, label, reference, ...props }) => {
  return (
    <ReferenceInput
      label={label ?? 'Partner'}
      source={source}
      reference={reference ?? 'partners'}
      link="show"
      sortable={false}
      {...props}
    >
      <SelectInput translateChoice={false} optionText={<PartnerField />} />
    </ReferenceInput>
  );
};
PartnerSelectInput.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  label: PropTypes.string,
};

export const PartnerConfigurationUI = ({ page, ...props }) => {
  const [enableEditing] = useState(['edit', 'create'].includes(page));
  const [isOpen, setOpen] = useState(false);

  if (!enableEditing) {
    return null;
  }

  return (
    <FunctionField
      {...props}
      source="configurations"
      label={false}
      render={(record) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen(!isOpen)}
              >
                {isOpen && <CloseIcon />}
                {!isOpen && <OpenIcon />}
                <span style={{ fontSize: '24px' }}>
                  <strong>Configurazioni</strong>
                </span>
              </Box>
              <Box style={{ paddingLeft: '32px' }}>
                <InfoBox showIcon={false}>
                  <strong>
                    Queste configurazioni vengono usate dall&apos;applicativo per modificare i
                    comportamenti dei vari servizi delle partnership (e.g. contenuti feed)
                  </strong>
                </InfoBox>
              </Box>
            </Box>

            {isOpen && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                style={{ gap: '24px', paddingLeft: '48px' }}
              >
                <br />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <InfoBox showIcon={false} showBackground={true} level="info">
                    <span style={{ fontSize: '16px' }}>
                      <strong>Customizzazione elementi del feed</strong>
                    </span>
                  </InfoBox>
                  <SelectInput
                    {...props}
                    required
                    source="configurations.feedItems.redirectTo"
                    label="Link per la lettura degli articoli"
                    record={record}
                    initialValue="oneofus"
                    choices={[
                      { id: 'oneofus', name: 'Sito OneOfUs' },
                      { id: 'partner', name: 'Sito Partner' },
                    ]}
                    helperText="Selezionando OneOfUs, all'apertura del link articolo nel feed l'utente sarà portato nella sezione articoli del sito oneofus."
                    allowEmpty={false}
                    translateChoice={false}
                  ></SelectInput>
                  <BooleanInput
                    source="configurations.feedItems.customize"
                    label="Mostrare customizzazione dei video"
                    helperText="Attivando questa opzione, i video degli utenti con almeno un attributo del partner mostreranno la customizzazione (colore di sfondo + badge)."
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <InfoBox showIcon={false} showBackground={true} level="info">
                    <span style={{ fontSize: '16px' }}>
                      <strong>Customizzazione profilo utente</strong>
                    </span>
                  </InfoBox>
                  <BooleanInput
                    source="configurations.profile.banner"
                    label="Mostrare customizzazione banner"
                    helperText="Attivando questa opzione, i profili degli utenti con almeno un attributo del partner avranno il banner del partner attivo."
                  />
                  <BooleanInput
                    source="configurations.profile.pattern"
                    label="Mostrare customizzazione pattern"
                    helperText="Attivando questa opzione, i profili degli utenti con almeno un attributo del partner avranno il pattern di background attivo con il badge del partner."
                  />
                </Box>
              </Box>
            )}
            <br />
          </Box>
        );
      }}
    />
  );
};
PartnerConfigurationUI.propTypes = {
  page: PropTypes.oneOf(['list', 'show', 'edit', 'create']),
  props: PropTypes.object,
};
