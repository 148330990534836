import PropTypes from 'prop-types';
import React from 'react';
import {
  BooleanInput,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';

import { validateRequired } from './validators';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const InAppProductEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={validateRequired} />
        <TextInput source="android" />
        <TextInput source="ios" />
        <BooleanInput source="premium" initialValue={false} />
        <BooleanInput source="onboarding" initialValue={false} />
        <ReferenceArrayInput
          label="Tournaments"
          source="tournaments"
          reference="tournaments"
          validate={validateRequired}
        >
          <SelectArrayInput optionText={(record) => `${record.title} - ${record.gender}`} />
        </ReferenceArrayInput>
        <ReferenceInput label="Product" source="product" reference="products">
          <SelectInput optionText={(record) => `${record.title} [${record.type}]`} />
        </ReferenceInput>
        <JsonInput
          source="extra"
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: true,
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default InAppProductEdit;
