import UserIcon from '@material-ui/icons/People';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import ClubsList from './ClubsList';
import ClubsShow from './ClubsShow';
import { isAdmin, isEditor } from '../utils';

const ClubsResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions) && !isEditor(permissions)) {
    return <Resource {...props} />;
  }

  return <Resource {...props} list={ClubsList} show={ClubsShow} icon={UserIcon} />;
};
ClubsResource.defaultProps = {
  name: 'clubs',
};

export default ClubsResource;
