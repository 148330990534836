import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import {isAdvisor, isScout, isScoutPlay2Give} from '../utils';
import ChallengesCreate from './ChallengesCreate';
import ChallengesEdit from './ChallengesEdit';
import ChallengesList from './ChallengesList';
import ChallengesShow from './ChallengesShow';

const ChallengesResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdvisor(permissions) || isScoutPlay2Give(permissions)) {
    return <Resource {...props} />;
  }

  if (isScout(permissions)) {
    return (
      <Resource {...props} list={ChallengesList} show={ChallengesShow} icon={SportsSoccerIcon} />
    );
  }

  return (
    <Resource
      {...props}
      list={ChallengesList}
      show={ChallengesShow}
      create={ChallengesCreate}
      edit={ChallengesEdit}
      icon={SportsSoccerIcon}
    />
  );
};
ChallengesResource.defaultProps = {
  name: 'challenges',
};

export default ChallengesResource;
