import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import {
  ReferenceField,
  Filter,
  DateInput,
  FunctionField,
  TextInput,
  TextField,
  DateField,
} from 'react-admin';

const BansFilter = (props) => (
  <Filter {...props}>
    <DateInput source="until" label="Fine" />
    <TextInput source="owner.surname" label="Cognome" />
    <TextInput source="owner.email" label="Email" />
  </Filter>
);

const BansList = (props) => (
  <ListGuesser
    {...props}
    title="List of bans"
    perPage={25}
    filters={<BansFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <ReferenceField
      label="User"
      source="target"
      reference="users"
      link="show"
      sortable={false}
      sortBy="owner.surname"
    >
      <FunctionField render={(record) => `${record.surname} ${record.name}`} />
    </ReferenceField>
    <ReferenceField label="Email" source="target" reference="users" link={false} sortable={false}>
      <TextField source="email" />
    </ReferenceField>
    <ReferenceField label="Phone" source="target" reference="users" link={false} sortable={false}>
      <TextField source="phone" />
    </ReferenceField>
    <TextField source="reason" />
    <DateField source="until" />
    <DateField source="createdAt" />
  </ListGuesser>
);

export default BansList;
