import React from 'react';
import {
  Show,
  SimpleShowLayout,
  DateField,
  NumberField,
  ReferenceField,
  ChipField,
  ReferenceManyField,
  Pagination,
  Datagrid,
  BooleanField,
  ShowButton,
  FunctionField,
} from 'react-admin';
import { PlayerRoleField, ChallengeTypeField } from '../fields';
import AsideView from '../asideView';
import { PropTypes } from 'prop-types';
import { isAdmin, tournamentOptionText } from '../utils';

const ChallengesShow = ({ permissions, ...props }) => (
  <Show aside={<AsideView />} {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Torneo" source="tournament" reference="tournaments">
        <FunctionField
          render={(record) => (
            <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
          )}
        />
      </ReferenceField>
      <DateField source="availableAt" showTime={true} />
      <DateField source="startDate" showTime={true} />
      <DateField source="endDate" showTime={true} />
      <NumberField source="maxParticipants" />
      <NumberField source="videoLength" label="lunghezza massima dei video in secondi" />
      <ChallengeTypeField source="type" />
      <PlayerRoleField source="role" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceManyField
        pagination={<Pagination />}
        label="User uploaded videos"
        reference="videos"
        target="challenge.id"
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <Datagrid>
          <DateField source="createdAt" />
          <ReferenceField
            label="User"
            source="owner"
            reference="users"
            link="show"
            sortBy="owner.surname"
          >
            <FunctionField label="Name" render={(record) => `${record.surname} ${record.name}`} />
          </ReferenceField>
          {isAdmin(permissions) && <BooleanField source="visible" sortable={false} />}
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

ChallengesShow.propTypes = {
  permissions: PropTypes.array,
  props: PropTypes.any,
};

export default ChallengesShow;
