import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import ProductCreate from './ProductCreate';
import ProductEdit from './ProductEdit';
import ProductList from './ProductList';
import ProductShow from './ProductShow';
import { isAdmin, isEditor } from '../utils';

const ProductResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions) && !isEditor(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={ProductList}
      show={ProductShow}
      create={ProductCreate}
      edit={ProductEdit}
      icon={ShoppingBasket}
    />
  );
};
ProductResource.defaultProps = {
  name: 'products',
};

export default ProductResource;
