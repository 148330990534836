import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { ReferenceField, FunctionField, Filter, TextField, DateField, SelectInput } from 'react-admin';
import { ReportStateField, ReportTargetReferenceField } from '../fields';

const ReportsFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      source="state"
      choices={[
        { id: 'waiting', name: 'Aperto' },
        { id: 'approved', name: 'Approvato' },
        { id: 'denied', name: 'Negato' },
      ]}
    />
  </Filter>
);

const ReportsList = (props) => (
  <ListGuesser
    {...props}
    title="List of reports"
    perPage={25}
    filters={<ReportsFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    hasEdit={false}
    bulkActionButtons={false}
  >
    <TextField source="reason" sortable={false} />
    <ReferenceField label="Owner" source="owner" reference="users" link="show" sortable={false}>
      <FunctionField render={(record) => `${record.surname} ${record.name}`} />
    </ReferenceField>
    <ReportTargetReferenceField label="Target" source="targetIRI" link="show" sortable={false} />
    <ReportStateField source="state" sortable={false} />
    <DateField source="createdAt" />
  </ListGuesser>
);

export default ReportsList;
