import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  SimpleShowLayout,
  TextField,
  useQueryWithStore,
  Loading,
  Error,
  Button,
  useDataProvider,
  useRefresh,
  useNotify,
  LinearProgress,
  BooleanField,
} from 'react-admin';
import { useState } from 'react';

import { Typography, CardMedia, Card } from '@material-ui/core';
import useAsideStyles from './useAsideStyles';
import { resourceId, isScout } from '../utils';

const AsideProfile = (props) => {
  const { profileId } = props;
  const { skillTaleId } = props;
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'profiles',
    payload: { id: profileId },
  });
  if (error) {
    return <Error />;
  }
  if (!loaded || !data) {
    return <Loading />;
  }

  if (data.document) {
    return <AsideProfileLoadDocument {...props} profile={data} />;
  }

  if (data.address) {
    return <AsideProfileLoadAddress {...props} profile={data} />;
  }

  return <AsideProfileLoadVideopresentation {...props} profile={data} />;
};
AsideProfile.propTypes = {
  skillTaleId: PropTypes.string,
  profileId: PropTypes.string.isRequired,
};

const AsideProfileLoadDocument = (props) => {
  const { profile } = props;
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'documents',
    payload: { id: profile.document },
  });
  if (error) {
    return <Error />;
  }
  if (!loaded || !data) {
    return <Loading />;
  }

  if (profile.address) {
    return <AsideProfileLoadAddress {...props} document={data} />;
  }

  return <AsideProfileLoadVideopresentation {...props} document={data} />;
};

AsideProfileLoadDocument.propTypes = {
  profile: PropTypes.object.isRequired,
};

const AsideProfileLoadAddress = (props) => {
  const { profile } = props;
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'address',
    payload: { id: profile.address },
  });
  if (error) {
    return <Error />;
  }
  if (!loaded || !data) {
    return <Loading />;
  }

  return <AsideProfileLoadVideopresentation {...props} address={data} />;
};
AsideProfileLoadAddress.propTypes = {
  profile: PropTypes.object.isRequired,
};

const AsideProfileLoadVideopresentation = (props) => {
  const { profile } = props;
  const { loaded, error, data, total } = useQueryWithStore({
    type: 'getList',
    resource: 'videos',
    payload: {
      pagination: {},
      sort: {},
      filter: {
        'owner.id': resourceId(profile.owner),
        'exists[challenge]': false,
        visible: true,
      },
    },
  });
  if (error) {
    return <Error />;
  }
  if (!loaded || !data) {
    return <Loading />;
  }
  if (total > 0) {
    return <AsideProfileLoadVideopresentationMedia {...props} videoPresentation={data} />;
  }
  return profile.avatar ? (
    <AsideProfileLoadAvatar {...props} videoPresentation={data} videoPresentationMedia={[]} />
  ) : (
    <AsideProfileAllLoaded
      {...props}
      videoPresentation={data}
      videoPresentationMedia={[]}
      avatar={null}
    />
  );
};
AsideProfileLoadVideopresentation.propTypes = {
  profile: PropTypes.object.isRequired,
};

const AsideProfileLoadVideopresentationMedia = (props) => {
  const { profile, videoPresentation } = props;
  const { loaded, error, data } = useQueryWithStore({
    type: 'getMany',
    resource: 'media',
    payload: { ids: videoPresentation.map((data) => data.media) },
  });
  if (error) {
    return <Error />;
  }
  if (!loaded || !data) {
    return <Loading />;
  }

  return profile.avatar ? (
    <AsideProfileLoadAvatar {...props} videoPresentationMedia={data} />
  ) : (
    <AsideProfileAllLoaded {...props} videoPresentationMedia={data} avatar={null} />
  );
};
AsideProfileLoadVideopresentationMedia.propTypes = {
  profile: PropTypes.object.isRequired,
  videoPresentation: PropTypes.array.isRequired,
};

const AsideProfileLoadAvatar = (props) => {
  const { profile } = props;
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'media',
    payload: { id: profile.avatar },
  });
  if (error) {
    return <Error />;
  }
  if (!loaded || !data) {
    return <Loading />;
  }

  return <AsideProfileAllLoaded {...props} avatar={data.path} />;
};

AsideProfileLoadAvatar.propTypes = {
  profile: PropTypes.object.isRequired,
};

const VideoPresentation = ({ video, media }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useAsideStyles();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const deleteVideo = () => {
    if (!window.confirm('sei sicuro di voler rimuovere questo video?')) return;
    setLoading(true);
    dataProvider
      .delete('videos', { id: video.id })
      .then(() => {
        refresh();
        notify('video rimosso con successo');
      })
      .catch((error) => {
        setError(error);
        notify('errore nella rimozione del video');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (error) return <Error />;

  return (
    <div className={classes.videoPresentation}>
      <CardMedia component="video" src={media.path} controls />
      {loading ? (
        <LinearProgress classes={{ root: classes.removeButtonLoading }} />
      ) : (
        <Button
          className={classes.removeButton}
          label="rimuovi"
          variant="contained"
          onClick={deleteVideo}
        />
      )}
    </div>
  );
};
VideoPresentation.propTypes = {
  video: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
};

const AsideProfileAllLoaded = ({
  record,
  profile,
  document,
  avatar,
  address,
  videoPresentation,
  videoPresentationMedia, skillTaleId,
  ...props
}) => {
  const classes = useAsideStyles();
  return (
    <Card className={classes.containerNoPadding}>
      <SimpleShowLayout record={profile}>
        <div className={classes.header}>
          <Typography variant="h6">
            {record.name} {record.surname}
          </Typography>
          {avatar && (
            <CardMedia className={classes.avatar} component={'img'} src={avatar} controls />
          )}
        </div>
        <div className={classes.videoPresentationsContainer}>
          {videoPresentationMedia.map((data, index) => {
            const video = videoPresentation.find((e) => e.media === data['@id']);
            return video ? <VideoPresentation key={index} video={video} media={data} /> : null;
          })}
        </div>
        <TextField source="bio" />
        <TextField source="weight" />
        <TextField source="height" />
        <TextField source="role" />
        <TextField source="foot" />
        <TextField source="figcCard" />
        <TextField source="kitSize" />
        <BooleanField source="hasEverBeenPremium" label="Was ever premium"/>
      </SimpleShowLayout>
      <SimpleShowLayout record={document}>
        <TextField source="type" label="Document type" />
        <TextField source="code" label="Document code" />
      </SimpleShowLayout>
      <SimpleShowLayout record={address}>
        <div className={classes.header}>
          <Typography variant="h6">Address</Typography>
        </div>
        {!isScout(props.permissions) && <TextField source="street" />}
        <TextField source="city" />
        <TextField source="postalCode" />
        <TextField source="province" />
        <TextField source="region" />
        <TextField source="country" />
      </SimpleShowLayout>
      {
        skillTaleId &&
            <ProfileSkilltaleLayout {...props} skillTaleId={skillTaleId}/>

      }
    </Card>
  );
};
AsideProfileAllLoaded.propTypes = {
  avatar: PropTypes.string,
  record: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  document: PropTypes.object,
  address: PropTypes.object,
  videoPresentation: PropTypes.array.isRequired,
  videoPresentationMedia: PropTypes.array.isRequired,
  permissions: PropTypes.object,
};

const ProfileSkilltaleLayout = (props) => {

  const {skillTaleId} = props;

  const [url, setUrl] = useState('');

  const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

  useEffect(() => {
    fetch(entrypoint + '/gateway/proxy?provider=skillTale&resource=auth', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`
      }
    })
        .then(response => response.json())
        .then(json => {
          const params = [
            'embedded=true',
            'uuid=' + skillTaleId,
            'token=' + json.access_token,
            'lang=IT'
          ];
          setUrl('https://oneofus-dev.skilltale.com#backoffice?' + params.join('&'))
        })
        .catch(error => console.error(error));
  }, [])


  return (
      <div>
        { url &&
          <iframe
          src={url} style={{height: '700px', width: '100%'}}>

          </iframe>
        }
      </div>
  )
}
ProfileSkilltaleLayout.propTypes = {
  skillTaleId: PropTypes.string,
}

export default AsideProfile;
