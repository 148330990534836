import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import ConfigCreate from './ConfigCreate';
import ConfigEdit from './ConfigEdit';
import ConfigList from './ConfigList';
import ConfigShow from './ConfigShow';
import { isAdmin } from '../utils';

const ConfigResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions)) {
    return <Resource {...props} />;
  }

  return (
      <Resource
          {...props}
          list={ConfigList}
          show={ConfigShow}
          create={ConfigCreate}
          edit={ConfigEdit}
      />
  );
};
ConfigResource.defaultProps = {
  name: 'configs',
};

export default ConfigResource;
