import PropTypes from 'prop-types';
import React from 'react';
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  SelectInput,
} from 'react-admin';

import { validateRequired } from './validators';
import { JsonInput } from 'react-admin-json-view';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const TournamentsEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="title" validate={validateRequired} />
        <TextInput multiline source="description" fullWidth={true} />
        <DateInput source="startDate" />
        <DateInput source="endDate" />
        <BooleanInput source="premium" />
        <SelectInput
          source="gender"
          choices={[
            { id: 'M', name: 'Maschio' },
            { id: 'F', name: 'Femmina' },
          ]}
        />
        <NumberInput source="sequence" label="Ordinamento" min={0} validate={validateRequired} />
        <NumberInput source="requiredPlayers" label="Utenti richiesti" />
        <JsonInput
          source="extra"
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: true,
          }}
        />
        <JsonInput
          source="masterclasses"
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: true,
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default TournamentsEdit;
