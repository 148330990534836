import React from 'react';
import PropTypes from 'prop-types';
import { isEditor } from '../utils';

export const UsersTitle = ({ record }) => {
  let data = `${record?.name} ${record?.surname}`;
  if (record.email && !isEditor(record)) data = record.email;

  return <span>User: {`${data}`}</span>;
};
UsersTitle.propTypes = {
  record: PropTypes.object,
};
