import React from 'react';
import {
  BooleanInput,
  Create,
  DateInput,
  CheckboxGroupInput,
  PasswordInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import {
  validateBirthDate,
  validateEmail,
  validateName,
  validatePassword,
  validatePhone,
  validateSurname,
} from './validators';
import { PartnershipSignupFlowSelectInput } from '../partnership/utils';

const UsersCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="phone" validate={validatePhone} />
        <TextInput source="email" type="email" validate={validateEmail} />
        <TextInput source="name" validate={validateName} />
        <TextInput source="surname" validate={validateSurname} />
        <PasswordInput source="password" validate={validatePassword} />
        <DateInput
          source="birthDate"
          validate={validateBirthDate}
          initialValue={new Date(2000, 0, 1)}
        />
        <SelectInput
          source="gender"
          choices={[
            { id: 'M', name: 'Maschio' },
            { id: 'F', name: 'Femmina' },
          ]}
        />
        <PartnershipSignupFlowSelectInput source="signupFlow" />
        <TextInput source="preferredProvince" />
        <CheckboxGroupInput
          source="roles"
          choices={[
            { id: 'ROLE_ADMIN', name: 'Admin' },
            { id: 'ROLE_EDITOR', name: 'Editor' },
            { id: 'ROLE_SCOUT', name: 'Scout' },
            { id: 'ROLE_PLAYER', name: 'Player' },
            { id: 'ROLE_ADVISOR', name: 'Advisor' },
          ]}
        />
        <BooleanInput label="Verificato" source="verified" initialValue={true} />
      </SimpleForm>
    </Create>
  );
};

export default UsersCreate;
