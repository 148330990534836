import React from 'react';
import { DateInput, Filter, SelectInput, TextInput } from 'react-admin';
import PropTypes from 'prop-types';
import { isAdmin, isEditor } from '../utils';

const canEdit = (permissions) => isAdmin(permissions) || isEditor(permissions);

const PartnerFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Search name" source="name" alwaysOn />
    {canEdit(permissions) && (
      <SelectInput
        alwaysOn
        label="Stato"
        source="visible"
        allowEmpty={true}
        choices={[
          { id: true, name: 'Attivo' },
          { id: false, name: 'Cancellato' },
        ]}
      />
    )}
    <TextInput label="Sign-up flow" source="signupFlow" alwaysOn />
    <DateInput label="Registrato dal" source="createdAt[strictly_after]" />
    <DateInput label="Registrato al" source="createdAt[strictly_before]" />
  </Filter>
);
PartnerFilter.propTypes = {
  setFilters: PropTypes.func,
  filterValues: PropTypes.object,
  permissions: PropTypes.array,
};

export default PartnerFilter;
