import React from 'react';
import { cloneElement } from 'react';
import { ListGuesser } from '@api-platform/admin';
import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceField,
  Filter,
  FunctionField,
  TextInput,
  TextField,
  DateField,
  SelectInput,
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';
import PropTypes from 'prop-types';

import { isAdmin, resourceId, tournamentOptionText } from '../utils';
import ExportButton from '../ExportButton';
import { PassTypes } from './passes.util';

const ListActions = ({ className, filters, ...props }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  const { permissions } = usePermissions();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(props)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {isAdmin(permissions) && <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        targetClass={'App\\Entity\\Pass'}
        filters={filterValues}
      />
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.array,
  permissions: PropTypes.array,
};

const PassesFilter = (props) => {
  let filter = {};
  if (props.filterValues.stage?.tournament?.id) {
    filter = { 'tournament.id': resourceId(props.filterValues.stage?.tournament?.id) };
  }
  return (
    <Filter {...props}>
      <TextInput source="target.surname" label="Cognome" alwaysOn />
      <TextInput source="target.email" label="Email" alwaysOn />
      <ReferenceInput
        label="Torneo"
        source="stage.tournament.id"
        reference="tournaments"
        onChange={(input) => {
          props.setFilters({
            ...props.filterValues,
            stage: { tournament: { id: input.target.value }, id: null },
          });
        }}
        alwaysOn
      >
        <SelectInput optionText={(record) => tournamentOptionText(record)} />
      </ReferenceInput>
      <ReferenceInput
        label="Fase"
        source="stage.id"
        reference="stages"
        disabled={!props.filterValues.stage?.tournament?.id}
        filter={filter}
        alwaysOn
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <SelectInput alwaysOn label="Type" source="type" allowEmpty={true} choices={PassTypes} />
    </Filter>
  );
};
PassesFilter.propTypes = {
  setFilters: PropTypes.func,
  filterValues: PropTypes.object,
};

const PassesList = (props) => (
  <ListGuesser
    {...props}
    title="List of passes"
    perPage={25}
    filters={<PassesFilter />}
    actions={<ListActions />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <ReferenceField label="User" source="target" reference="users" link="show" sortable={false}>
      <FunctionField render={(record) => `${record.surname} ${record.name}`} />
    </ReferenceField>
    <ReferenceField label="Scout" source="owner" reference="users" link="show" sortable={false}>
      <FunctionField render={(record) => `${record.surname} ${record.name}`} />
    </ReferenceField>
    <TextField source="type" />
    <ReferenceField label="Fase" source="stage" reference="stages">
      <TextField source="title" />
    </ReferenceField>
    <DateField source="createdAt" />
  </ListGuesser>
);

export default PassesList;
