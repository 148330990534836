import React from 'react';
import {
  Create,
  DateInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  AutocompleteInput,
} from 'react-admin';
import { validateTarget, validateUntil } from './validators';

const BansCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="User"
          source="target"
          reference="users"
          validate={validateTarget}
          filterToQuery={(searchText) => ({ search: searchText, visible: true })}
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(record) => `${record.surname} ${record.name} ${record.phone || ''}`}
          />
        </ReferenceInput>
        <TextInput multiline source="reason" fullWidth="true" />
        <DateInput source="until" validate={validateUntil} />
      </SimpleForm>
    </Create>
  );
};

export default BansCreate;
