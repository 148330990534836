import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { TextField } from 'react-admin';

const PreOrderBuyerList = (props) => (
  <ListGuesser
    {...props}
    title="List of Pre-Order Buyer"
    perPage={25}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <TextField source="name" label="Name" />
    <TextField source="surname" label="Surname" />
    <TextField source="email" label="Email" />
    <TextField source="phone" label="Phone" />
  </ListGuesser>
);

export default PreOrderBuyerList;
