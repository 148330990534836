import React from 'react';
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { validateRequired } from './validators';
import { JsonInput } from 'react-admin-json-view';

const InAppProductCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={validateRequired} />
        <TextInput source="android" />
        <TextInput source="ios" />
        <BooleanInput source="premium" initialValue={false} />
        <BooleanInput source="onboarding" initialValue={false} />
        <ReferenceArrayInput
          label="Tournaments"
          source="tournaments"
          reference="tournaments"
          validate={validateRequired}
        >
          <SelectArrayInput optionText={(record) => `${record.title} - ${record.gender}`} />
        </ReferenceArrayInput>
        <ReferenceInput label="Product" source="product" reference="products">
          <SelectInput optionText={(record) => `${record.title} [${record.type}]`} />
        </ReferenceInput>
        <JsonInput
          source="extra"
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: true,
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default InAppProductCreate;
