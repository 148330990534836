import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { validateRequired } from './validators';
import { getPartnershipDisplayName } from '../partnership/utils';

const SponsorCodeCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="code" label="Code" validate={validateRequired} />
        <ReferenceInput
          label="Partnership"
          source="partnership"
          reference="partnerships"
          link="show"
        >
          <SelectInput optionText={(record) => getPartnershipDisplayName(record)} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default SponsorCodeCreate;
