import React from 'react';
import { PropTypes } from 'prop-types';
//import {TextField} from 'react-admin';
import { Chip } from '@material-ui/core';

export const NotifiableTargetField = ({ record }) => {
  return <Chip label={record.notifiableTarget ?? 'Tutti'} />;
};
NotifiableTargetField.propTypes = {
  record: PropTypes.object,
};

export const NewsRecipientTypes = [
  { id: 'players', name: 'Players' },
  { id: 'future-players', name: 'Future players' },
  { id: 'your-chance', name: 'Your Chance' },
  { id: 'talents', name: 'Talents' },
  { id: 'semifinalists', name: 'Semifinalisti' },
  { id: 'finalists', name: 'Finalisti' },
];


export const TournamentTargetField = ({ record }) => {
  return <Chip label={record.tournament ?? 'Tutti'} />;
};
TournamentTargetField.propTypes = {
  record: PropTypes.object,
};


export default {
  TournamentTargetField,
  NotifiableTargetField,
  NewsRecipientTypes,
};
