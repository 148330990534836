import React from 'react';
import { Show, SimpleShowLayout, DateField } from 'react-admin';
import AsideView from '../asideView';
import { NotifiableTargetField } from './news.util';

const ChallengesShow = (props) => (
  <Show aside={<AsideView />} {...props}>
    <SimpleShowLayout>
      <NotifiableTargetField label="Destinatari" />
      <DateField source="publicationDate" label="Data di pubblicazione" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export default ChallengesShow;
