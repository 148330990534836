import React from 'react';
import {
  Button,
  // TextInput,
  useNotify,
  NumberInput,
  ReferenceInput,
  SelectInput,
  useRefresh,
} from 'react-admin';
import DialogForm from '../DialogForm';
import Icon from '@material-ui/icons/PlusOne';
import { Box } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import authProvider from '../authProvider';
import { apiRequest, HTTP_METHOD_POST, resourceId } from '../utils';
import InfoBox from '../InfoBox';

const TicketGenerateButton = ({ disabled }) => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [codeChallengeLength, setCodeChallengeLength] = useState(undefined);
  const [productId, setProductId] = useState(null);
  const [partnershipId, setPartnershipId] = useState(null);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleInit = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!authProvider.checkExistingToken()) {
      notify('Login expired, please authenticate again', 'warning');
      return;
    }
    const payload = { amount, productId, partnershipId };
    if (codeChallengeLength && codeChallengeLength > 6) {
      payload.codeChallengeLength = codeChallengeLength;
    }
    const response = await apiRequest({
      path: '/tickets/generate',
      method: HTTP_METHOD_POST,
      payload,
    });
    setOpen(false);
    if (response.ok) {
      notify(`${amount} tickets were created!`);
      refresh();
    } else {
      notify('Unable to complete operation', 'warning');
    }
  };

  return (
    <>
      <Button disabled={disabled} label="Generate" onClick={handleInit}>
        <Icon />
      </Button>
      <DialogForm
        open={open}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        title="Generate any amount of redeemable tickets"
        submitLabel="Generate"
      >
        <Box display="flex" flexDirection="column">
          <NumberInput
            required
            source="amount"
            label="Numero di ticket da generare"
            value={amount}
            onChange={(event) => setAmount(parseInt(event.target.value))}
          />
          <NumberInput
            source="codeChallengeLength"
            label="Lunghezza codice esterno"
            value={codeChallengeLength}
            onChange={(event) => setCodeChallengeLength(parseInt(event.target.value))}
          />
          <InfoBox
            text={
              'Riempire il campo soprastante per generare codici redimibili <strong>SOLO</strong> con codice esterno'
            }
          />
          <ReferenceInput
            label="Product"
            source="product"
            reference="products"
            onChange={(event) => setProductId(resourceId(event.target.value))}
            filter={{ enable: true }}
          >
            <SelectInput required optionText={(record) => `${record.title} [${record.type}]`} />
          </ReferenceInput>
          <ReferenceInput
            label="Partnership"
            source="partnership"
            reference="partnerships"
            onChange={(event) => setPartnershipId(resourceId(event.target.value))}
          >
            <SelectInput optionText={(record) => `${record.name} [${record.partner.name}]`} />
          </ReferenceInput>
        </Box>
      </DialogForm>
    </>
  );
};

TicketGenerateButton.propTypes = {
  disabled: PropTypes.bool,
};

export default TicketGenerateButton;
