import React from 'react';
import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

const ClubsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Name" />
      <TextField source="vat" label="P.Iva / Cod. FIs." />
      <TextField source="managerName" label="Manager name" />
      <TextField source="managerSurname" label="Manager Surname" />
      <TextField source="assignment" label="Assignment" />
      <TextField source="phone" label="Phone" />
      <TextField source="email" label="Email" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export default ClubsShow;
