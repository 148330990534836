import PropTypes from 'prop-types';
import React from 'react';
import {
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import { validateRequired } from './validators';
import { getPartnershipDisplayName } from '../partnership/utils';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const SponsorCodeEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="code" label="Code" validate={validateRequired} />
        <ReferenceInput
          label="Partnership"
          source="partnership"
          reference="partnerships"
          link="show"
        >
          <SelectInput optionText={(record) => getPartnershipDisplayName(record)} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default SponsorCodeEdit;
