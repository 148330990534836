import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import InfoBox from './InfoBox';

export const USER_CLUSTERS = [
  { id: 'spectators', name: 'Tifosi' },
  { id: 'players', name: 'Players' },
  { id: 'future-players', name: 'Future players' },
  { id: 'your-chance', name: 'Your Chance' },
  { id: 'talents', name: 'Talents' },
  { id: 'semifinalists', name: 'Semifinalisti' },
  { id: 'finalists', name: 'Finalisti' },
];

export const UserClusterField = ({ value, record, source, infoBoxText, infoBoxLevel }) => {
  const val = value ?? record?.[source];
  const cluster = typeof val === 'object' ? val : USER_CLUSTERS.find((c) => c.id === val);

  if (!cluster) {
    return infoBoxText ? <InfoBox level={infoBoxLevel} text={infoBoxText} /> : null;
  }

  return <Chip label={cluster.name} />;
};
UserClusterField.propTypes = {
  value: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  infoBoxText: PropTypes.string,
  infoBoxLevel: PropTypes.string,
};
