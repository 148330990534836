import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ProductTypes } from '../products/products.util';

export const PromoCodeTypes = {
  ...ProductTypes,
  SECONDCHANCE: '[OLD] SECONDCHANCE',
};

const useProductTypeStyles = makeStyles({
  premium: {
    // backgroundColor: '#91d8ff',
    backgroundColor: '#ffe600',
  },
  your_chance: {
    // backgroundColor: '#a3fba9',
    backgroundColor: '#1d1d1b',
    color: '#fff',
  },
  SECONDCHANCE: {
    // backgroundColor: '#a3fba9',
    backgroundColor: '#cbcbc5',
    color: '#000000',
  },
  none: {
    backgroundColor: 'transparent',
  },
});
export const PromoCodeTypeChip = ({ type, record, source }) => {
  const classes = useProductTypeStyles();
  let _t = type;

  if (!type && record && source) _t = record[source];

  console.log({ record, source, type });

  return <Chip className={classes[_t ?? 'none']} label={PromoCodeTypes[_t]} />;
};
PromoCodeTypeChip.propTypes = {
  type: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
};
