import React from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  DateInput,
  BooleanInput,
  required,
  SelectInput,
  NumberInput,
  ReferenceInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { getUserBenefitTypesMap, UserBenefitJsonContentEditUI } from './util';
import { Typography } from '@material-ui/core';
import { FormGrid, FormGridItem } from '../FormGrid';

const UserBenefitEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <FormGrid>
        <FormGridItem xs={6}>
          <TextInput source="name" validate={[required()]} />
          <ReferenceInput
            label="Partnership"
            source="partnership"
            reference="partnerships"
            link="show"
            filter={{ active: true }}
          >
            <SelectInput optionText={(record) => record.name}></SelectInput>
          </ReferenceInput>
          <RichTextInput source="description" />

          <SelectInput
            style={{ marginTop: '30px' }}
            translateChoice={false}
            choices={getUserBenefitTypesMap()}
            source="type"
            optionText="text"
            optionValue="value"
            validate={[required()]}
          />
          <Typography
            style={{ marginBottom: '30px' }}
            className={['MuiFormLabel-root', 'MuiInputLabel-shrink']}
          >
            &quot;Type&quot; descrive la tipologia del contenuto attribuito dal beneficio, la
            seguente lista contiene dei comportamenti prefissati e documentati.
          </Typography>

          <NumberInput style={{ marginTop: '0px' }} source="supply" label="Slot disponibili" />
          <Typography
            style={{ marginBottom: '30px' }}
            className={['MuiFormLabel-root', 'MuiInputLabel-shrink']}
          >
            Per &quot;slot disponibili&quot; facciamo riferimento al numero di user che possono
            usufruire del beneficio.
            <br />
            <strong>
              N.B: questo non agisce da discriminante verso la tipologia di utente (tier, cluster,
              etc..)
            </strong>
          </Typography>

          <DateInput source="startDate" />
          <DateInput source="endDate" />

          <BooleanInput label="Monouso" source="oneTimeOnly" />
          <BooleanInput label="Main" source="main" />
          <BooleanInput label="Visibile" source="visible" initialValue={true} />
        </FormGridItem>

        <FormGridItem xs={6}>
          <UserBenefitJsonContentEditUI
            source={'content'}
            label="Settings"
            style={{
              height: '100%',
              paddingLeft: '30px',
              borderLeft: 'solid 5px #f5f5f5',
            }}
          />
        </FormGridItem>
      </FormGrid>
    </SimpleForm>
  </Edit>
);

export default UserBenefitEdit;
