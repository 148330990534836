import React from 'react';
import {
  TextInput,
  FileInput,
  FileField,
  Create,
  SimpleForm,
  useDataProvider,
  useNotify,
  SelectInput,
  ReferenceInput,
  DateTimeInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { validateTitle, validateText, validateFile } from './validators';
import mediaCreator from '../mediaCreator';
import { NewsRecipientTypes } from './news.util';
import InfoBox from '../InfoBox';

const ChallengesCreate = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  return (
    <Create
      {...props}
      transform={async (data) => {
        const createCover = mediaCreator({
          dataProvider,
          notify,
          mediaFieldLabel: 'cover',
          fileLabel: 'fileCover',
        });
        const createVideo = mediaCreator({
          dataProvider,
          notify,
          mediaFieldLabel: 'video',
          fileLabel: 'fileVideo',
        });
        try {
          const withCover = await createCover(data);
          const withVideo = await createVideo(withCover);
          return withVideo;
        } catch (error) {
          notify(`Errore inatteso: ${error.message}`, 'warning');
        }
        return data;
      }}
    >
      <SimpleForm>
        <TextInput source="title" validate={validateTitle} />
        <RichTextInput
          validate={validateText}
          source="text"
          toolbar={[
            [{ font: [] }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ color: [] }, { background: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image'],
          ]}
        />
        <SelectInput
          source="notifiableTarget"
          label="Destinatari [tutti]"
          allowEmpty={true}
          initialValue={null}
          choices={NewsRecipientTypes}
        />
        <ReferenceInput label="Torneo" source="tournament" reference="tournaments">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <FileInput
          validate={validateFile}
          source="fileCover"
          label="Immagine cover"
          placeholder={<p>Trascina qui l&apos;immagine cover, o clicca qui per selezionarla</p>}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          source="fileVideo"
          label="Video"
          placeholder={
            <p>Trascina qui un video da associare alla notizia, o clicca qui per selezionarlo</p>
          }
        >
          <FileField source="src" title="title" />
        </FileInput>
        <DateTimeInput source="publicationDate" label="Data di pubblicazione" />
        <InfoBox showIcon={false}>
          <span>
            <strong style={{ fontSize: '18px' }}>Data di pubblicazione</strong>
            <br />
            In fase di creazione puoi impostare:
            <ul>
              <li>
                <strong>nessuna data (nullo)</strong>: la news non sarà pubblicata;
              </li>
              <li>
                <strong>una data passata</strong>: la news sarà pubblicata immediatamente con tanto
                di invio mail;
              </li>
              <li>
                <strong>una data futura</strong>: la news sarà schedulata per la pubblicazione.
              </li>
            </ul>
          </span>
        </InfoBox>
      </SimpleForm>
    </Create>
  );
};

export default ChallengesCreate;
