import React from 'react';
import {
  BooleanInput,
  Create,
  SimpleForm,
  SelectInput,
  required,
  NumberInput,
  TextInput,
  DateTimeInput,
  FunctionField,
} from 'react-admin';
import { AggregatorModelSelectInput } from './util';
import { PartnershipSelectInput } from '../partnership/utils';
import { JsonInput } from 'react-admin-json-view';
import InfoBox from '../InfoBox';

const AggregatorLaunchpadCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <AggregatorModelSelectInput source="model" validate={[required()]} />
        <PartnershipSelectInput />
        <NumberInput
          source="jobFrequency"
          label="Job frequency (in hours)"
          validate={[required()]}
        />
        <TextInput source="uri" />
        <SelectInput
          source="contentType"
          choices={[
            { id: 'json', name: 'JSON' },
            { id: 'xml', name: 'XML' },
          ]}
          translateChoice={false}
        />
        <JsonInput source="options" label="Symfony httpclient options" />
        <DateTimeInput source="lastExecutionTime" label="Ultima esecuzione" />
        <FunctionField
          source="lastExecutionTime"
          label={false}
          render={() => (
            <InfoBox>
              Impostare il valore a nullo o una data inferiore ad &quot;ora - job_frequency&quot;
              per garantirne l&apos;esecuzione allo scoccare della prossima ora.
              <br />è possibile impostare una data futura, l&apos;aggregatore non verrà eseguito.
            </InfoBox>
          )}
        />
        <BooleanInput label="Visibile" source="visible" initialValue={true} />
      </SimpleForm>
    </Create>
  );
};

export default AggregatorLaunchpadCreate;
