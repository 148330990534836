import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Card } from '@material-ui/core';
import useAsideStyles from './useAsideStyles';

const AsideProfileMissing = ({ record }) => {
  const classes = useAsideStyles();

  return (
    <Card className={classes.container}>
      <Typography variant="h6">
        {record.name} {record.name}
      </Typography>
      <Typography>Non possiede un profilo giocatore</Typography>
    </Card>
  );
};
AsideProfileMissing.propTypes = {
  record: PropTypes.object.isRequired,
};

export default AsideProfileMissing;
