import React from 'react';
import { Show, SimpleShowLayout, BooleanField, TextField, DateField } from 'react-admin';
import AsideView from '../asideView';
import { isAdmin, isEditor } from '../utils';
import PropTypes from 'prop-types';
import ColorField from '../ColorField';

const hasPermissions = (permissions) => isEditor(permissions) || isAdmin(permissions);

const PartnerShow = ({ permissions, ...props }) => (
  <Show aside={<AsideView />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
      <ColorField source="primaryColor" />
      {hasPermissions(permissions) && <BooleanField source="visible" addLabel={true} />}
      <DateField source="createdAt" />
      {hasPermissions(permissions) && <DateField source="updatedAt" />}
    </SimpleShowLayout>
  </Show>
);
PartnerShow.propTypes = {
  permissions: PropTypes.object,
  props: PropTypes.any,
};

export default PartnerShow;
