import React from 'react';

import { FileInput, FileField } from 'react-admin';
import { Typography, CardMedia } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getRawUploadUrl } from '../utils';

export const MediaUploadInput = ({ children, ...props }) => (
  <FileInput placeholder={<p>{children}</p>} {...props}>
    <FileField source="src" title="title" />
  </FileInput>
);
MediaUploadInput.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  props: PropTypes.object,
};

export const ImageUploadInput = (props) => <MediaUploadInput accept="image/*" {...props} />;

export const VideoUploadInput = (props) => <MediaUploadInput accept="video/*" {...props} />;

export const ShowMedia = ({ label, path, mimeType, empty, source, record }) => {
  const url = path ? path : record?.[source];
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      {label && (
        <Typography
          style={{ padding: '1.5rem 0 1rem', width: '100%', textAlign: 'center' }}
          variant={'caption'}
        >
          {label}
        </Typography>
      )}
      {url && (
        <CardMedia
          component={/^video/.exec(mimeType ?? '') ? 'video' : 'img'}
          src={getRawUploadUrl(url)}
          controls
        />
      )}
      {!url && <>{empty}</>}
    </div>
  );
};
ShowMedia.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  mimeType: PropTypes.string,
  empty: PropTypes.node,
  source: PropTypes.string,
  record: PropTypes.object,
};

export default {
  MediaUploadInput,
  ImageUploadInput,
  VideoUploadInput,
  ShowMedia,
};
