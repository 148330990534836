import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { TextField, NumberField, SelectInput } from 'react-admin';
import { apiRequest, HTTP_METHOD_GET, resourceId } from '../utils';

export function getYearsFromBirthdate(date) {
  let _date = date;

  if (!(date instanceof Date)) _date = new Date(date);

  return Math.floor((new Date() - _date) / (1000 * 60 * 60 * 24 * 365));
}

export const UserAgeField = ({ record, source, ...props }) => {
  let r = Object.assign({}, record);
  let age = getYearsFromBirthdate(r[source]);
  if (!isNaN(age)) r.age = `${age} anni`;
  else r.age = '-';
  return (
    <TextField
      addLabel={true}
      {...Object.assign({}, props, { record: r, source: 'age' })}
    ></TextField>
  );
};

UserAgeField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

export const UserVideoCount = ({ label, source, ...props }) => {
  return <NumberField label={label ?? 'N° Video'} source={source ?? 'videoCount'} {...props} />;
};
UserVideoCount.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

/**
 * @param {string|undefined} uuid
 * @param {'users'|'profiles'|'profile_tournaments'|'tickets'|'tournaments'} resource
 * @param {{}} payload
 */
export async function getResource(uuid, resource, payload = undefined) {
  return await apiRequest({
    path: uuid ? `/${resource}/${resourceId(uuid)}` : `/${resource}`,
    method: HTTP_METHOD_GET,
    payload,
  }).then((response) => {
    if (response.ok) {
      return response.json().then((r) => {
        if (uuid) {
          return r;
        }
        return r['hydra:member'];
      });
    } else {
      return response
        .json()
        .then((e) => e?.['hydra:description'] ?? 'Unable to complete operation');
    }
  });
}

export const ExcludeSelectInput = ({ choices, identifiers, customKey, ...props }) => {
  const [ch, setChoices] = useState([]);

  const ids = identifiers?.filter((id) => id).map((id) => resourceId(id ?? ''));
  const allowed = [];
  for (const choice of choices) {
    if (
      !ids ||
      ids.includes(resourceId(customKey ? choice[customKey] : choice['@id'] ?? choice.id ?? ''))
    ) {
      allowed.push(choice);
    }
  }
  if (ch.length !== allowed.length) {
    setChoices(allowed);
  }

  if (!ch.length) {
    return null;
  }

  return <SelectInput translateChoice={false} {...props} choices={ch} />;
};
ExcludeSelectInput.propTypes = {
  choices: PropTypes.array,
  identifiers: PropTypes.array,
  customKey: PropTypes.string,
  props: PropTypes.object,
};

export default {
  UserAgeField,
  getYearsFromBirthdate,
  UserVideoCount,
  ExcludeSelectInput,
};

export const TournamentRoles = {
  fan: 'Tifoso',
  player_free: 'Player Free',
  player_future_player: 'Future Player',
};
