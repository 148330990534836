import { ListGuesser } from '@api-platform/admin';
import React from 'react';
import {
  BooleanField,
  Button,
  ChipField,
  DateField,
  Filter,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  useNotify,
  useMutation,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
  FunctionField,
} from 'react-admin';
import { ChallengeTypeField, PlayerRoleField } from '../fields';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import { isAdmin, tournamentOptionText } from '../utils';

const BulkHideButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    'challenges',
    selectedIds,
    { visible: false },
    {
      onSuccess: () => {
        refresh();
        notify('Challenges hidden');
        unselectAll('challenges');
      },
      onFailure: () => {
        refresh();
        notify('Errore: challenges non nascosti', 'warning');
      },
    }
  );
  return (
    <Button
      label="Nascondi"
      onClick={updateMany}
      disabled={loading}
      startIcon={<VisibilityOff />}
    ></Button>
  );
};
BulkHideButton.propTypes = {
  selectedIds: PropTypes.array,
};

const VisibilityToggleButton = ({ record }) => {
  const [toggleVisibility, { loading, error }] = useMutation({
    type: 'update',
    resource: 'challenges',
    payload: { id: record.id, data: { visible: !record.visible } },
  });
  return (
    <Button
      label={record.visible ? 'Nascondi' : 'Rendi visibile'}
      onClick={toggleVisibility}
      disabled={loading || error}
      startIcon={record.visible ? <VisibilityOff /> : <Visibility />}
    />
  );
};
VisibilityToggleButton.propTypes = {
  record: PropTypes.object,
};

const ChallengesFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="title" alwaysOn />
    <SelectInput
      label="Stato"
      source="status"
      choices={[
        { id: 'not-started', name: 'Non ancora iniziati' },
        { id: 'active', name: 'Attivi' },
        { id: 'ended', name: 'Terminati' },
      ]}
    />
    <SelectInput
      source="type"
      choices={[
        { id: 'QLT', name: 'Qualità' },
        { id: 'QNT', name: 'Quantità' },
      ]}
    />
    <SelectInput
      source="role"
      choices={[
        { id: 'OP', name: 'Giocatore di movimento' },
        { id: 'GK', name: 'Portiere' },
      ]}
    />
    <ReferenceInput label="Torneo" source="tournament.id" reference="tournaments" alwaysOn>
      <SelectInput optionText={(record) => tournamentOptionText(record)} />
    </ReferenceInput>
  </Filter>
);

const ChallengesList = ({ permissions, ...props }) => (
  <ListGuesser
    {...props}
    title="List of challenges"
    perPage={25}
    filters={<ChallengesFilter />}
    sort={{ field: 'title', order: 'DESC' }}
    bulkActionButtons={isAdmin(permissions) ? <BulkHideButton /> : false}
  >
    <TextField source="title" />
    <ReferenceField label="Torneo" source="tournament" reference="tournaments" sortable={false}>
      <FunctionField
        render={(record) => (
          <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
        )}
      />
    </ReferenceField>
    <DateField source="availableAt" showTime={true} />
    <DateField source="startDate" showTime={true} />
    <DateField source="endDate" showTime={true} />
    <NumberField source="maxParticipants" />
    <ChallengeTypeField source="type" />
    <PlayerRoleField source="role" />
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
    {isAdmin(permissions) && <BooleanField source="visible" sortable={false} />}
    {isAdmin(permissions) && <VisibilityToggleButton />}
  </ListGuesser>
);

ChallengesList.propTypes = {
  permissions: PropTypes.array,
  props: PropTypes.any,
};

export default ChallengesList;
