import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, Button } from 'react-admin';

const ApproveToggleButton = ({ record }) => {
  const [toggleApprove, { loading, error }] = useMutation({
    type: 'update',
    resource: 'videos',
    payload: { id: record.id, data: { approve: !record.approve } },
  });
  return (
    <Button
      label={record.approve ? 'Rifiuta' : 'Approva'}
      onClick={toggleApprove}
      disabled={loading || error}
    />
  );
};
ApproveToggleButton.propTypes = {
  record: PropTypes.object,
};

export default ApproveToggleButton;
