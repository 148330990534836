import Icon from '@material-ui/icons/PresentToAll';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdmin, isEditor } from '../utils';
import UserBenefitList from './UserBenefitList';
import UserBenefitCreate from './UserBenefitCreate';
import UserBenefitShow from './UserBenefitShow';
import UserBenefitEdit from './UserBenefitEdit';

const UserBenefitResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdmin(permissions) || isEditor(permissions)) {
    return (
      <Resource
        {...props}
        list={UserBenefitList}
        create={UserBenefitCreate}
        show={UserBenefitShow}
        edit={UserBenefitEdit}
        icon={Icon}
      />
    );
  }

  return <Resource {...props} />;
};
UserBenefitResource.defaultProps = {
  name: 'user_benefits',
};

export default UserBenefitResource;
