import CardGiftcard from '@material-ui/icons/CardGiftcard';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdmin } from '../utils';
import SponsorCodeCreate from './SponsorCodeCreate';
import SponsorCodeEdit from './SponsorCodeEdit';
import SponsorCodeList from './SponsorCodeList';
import SponsorCodeShow from './SponsorCodeShow';

const SponsorCodeResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={SponsorCodeList}
      show={SponsorCodeShow}
      create={SponsorCodeCreate}
      edit={SponsorCodeEdit}
      icon={CardGiftcard}
    />
  );
};
SponsorCodeResource.defaultProps = {
  name: 'sponsor_codes',
};

export default SponsorCodeResource;
