import React from 'react';
import {
  BooleanInput,
  Create,
  DateInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { tournamentOptionText } from '../utils';
import { validateRequired } from './validators';

const EventCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Tournament" source="tournament" reference="tournaments">
          <SelectInput optionText={(record) => tournamentOptionText(record)} />
        </ReferenceInput>
        <TextInput source="name" label="Name" validate={validateRequired} />
        <TextInput source="place" label="Place" validate={validateRequired} />
        <DateInput source="date" label="Date" validate={validateRequired} />
        <BooleanInput label="Sold out" source="soldOut" />
        <BooleanInput label="Enable" source="visible" initialValue={true} />
        <SelectInput source="type" choices={[{ id: 'YourChance', name: 'Your Chance' }]} />
      </SimpleForm>
    </Create>
  );
};

export default EventCreate;
