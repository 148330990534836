import React from 'react';
import { AutocompleteInput, Create, ReferenceInput, SimpleForm } from 'react-admin';
import { validateRequired } from './validators';

const UserSponsorCodeCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="User"
          source="owner"
          reference="users"
          validate={validateRequired}
          filterToQuery={(searchText) => ({ search: searchText, visible: true })}
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(record) => `${record.surname} ${record.name} ${record.phone || ''}`}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Sponsor Code"
          source="sponsorCode"
          reference="sponsor_codes"
          validate={validateRequired}
          filterToQuery={(searchText) => ({ code: searchText })}
        >
          <AutocompleteInput optionText="code" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default UserSponsorCodeCreate;
