import { required, email, minValue, maxValue, minLength, maxLength, regex } from 'react-admin';
import moment from 'moment';

const yearsOld = (age) => (value) =>
  moment().diff(value, 'years') < age ? `L'età minima è di ${age} anni` : undefined;

export const validateEmail = [email()];
export const validatePhone = [required(), regex(/^[+]\d+$/), maxLength(50)];
export const validateName = [required()];
export const validateSurname = [required()];
export const validatePassword = [required(), regex(/^(?=.*[A-Z])([\S ])+$/), minLength(8)];
export const validateBirthDate = [yearsOld(16)];

export const validateProfileBio = [];
export const validateProfileWeight = [minValue(40), maxValue(250)];
export const validateProfileHeight = [minValue(100), maxValue(250)];
export const validateProfileFoot = [maxLength(10)];
export const validateProfileFigcCard = [maxLength(10)];
