import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import {
  TextField,
  DateField,
  SelectInput,
  Filter,
  DateInput,
  TextInput,
  BooleanField,
} from 'react-admin';
import GenderField from '../fields/GenderField';
import { PartnershipReferenceField } from '../partnership/utils';

const TournamentsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Titolo" source="title" alwaysOn />
    <DateInput label="Inizio" source="startDate" showTime={true} alwaysOn />
    <DateInput label="Fine" source="endDate" showTime={true} alwaysOn />
    <SelectInput
      alwaysOn
      label="Genere"
      source="gender"
      allowEmpty={true}
      choices={[
        { id: 'M', name: 'Maschio' },
        { id: 'F', name: 'Femmina' },
      ]}
    />
    <DateInput source="createdAt" />
    <DateInput source="updatedAt" />
  </Filter>
);

const TournamentsList = (props) => (
  <ListGuesser
    {...props}
    title="List of tournament"
    perPage={25}
    bulkActionButtons={false}
    filters={<TournamentsFilter />}
  >
    <TextField source="title" />
    <TextField source="description" />
    <PartnershipReferenceField label="Partnership"></PartnershipReferenceField>
    <DateField source="startDate" />
    <DateField source="endDate" />
    <GenderField source="gender" sortable={false} />
    <TextField source="sequence" label="Ordinamento" />
    <BooleanField source="premium" />
    <TextField source="subscribedPlayers" label="Partecipanti" />
  </ListGuesser>
);

export default TournamentsList;
