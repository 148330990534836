import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ChipField,
  BooleanField,
  ReferenceArrayField,
  SingleFieldList,
} from 'react-admin';

const InAppProductShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Name" />
      <TextField source="android" label="Android product id" />
      <TextField source="ios" label="Ios product id" />
      <BooleanField source="premium" sortable={false} />
      <BooleanField source="onboarding" sortable={false} />
      <ReferenceArrayField label="Tournaments" source="tournaments" reference="tournaments">
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField label="Product" source="product" reference="products" link="show">
        <ChipField source="title" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default InAppProductShow;
