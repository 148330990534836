import PropTypes from 'prop-types';
import React from 'react';
import {
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  SelectInput,
  BooleanInput,
  NumberInput,
} from 'react-admin';

import { validateEmail, validateRequired } from './validators';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const SellerEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="email" label="Email" validate={validateEmail} />
        <TextInput source="code" label="Code" validate={validateRequired} />
        <NumberInput source="discount" label="Discount" />
        <SelectInput
          source="discountType"
          choices={[
            { id: 'P', name: '%' },
            { id: 'V', name: '€' },
          ]}
        />
        <BooleanInput label="Enable" source="enable" />
      </SimpleForm>
    </Edit>
  );
};

export default SellerEdit;
