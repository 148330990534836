import React from 'react';
import { cloneElement } from 'react';
import { ListGuesser } from '@api-platform/admin';
import {
  Filter,
  TextInput,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  SelectInput,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  DateInput,
  useGetList,
} from 'react-admin';
import { PropTypes } from 'prop-types';
import ExportButton from '../ExportButton';
import { ProductTypeChip, ProductTypes } from '../products/products.util';
import TicketGenerateButton from './TicketGenerateButton';
import { Chip } from '@material-ui/core';
import { resourceId } from '../utils';
import TicketImportButton from './TicketImportButton';

const TicketFilter = (props) => {
  const { data: partnerships, ids: partnershipIds } = useGetList(
    'partnerships',
    {},
    {},
    { pagination: false }
  );

  return (
    <Filter {...props}>
      <SelectInput
        alwaysOn
        label="Riscattato"
        source="hasOwner"
        allowEmpty={true}
        choices={[
          { id: true, name: 'Si' },
          { id: false, name: 'No' },
        ]}
      />
      <SelectInput
        alwaysOn
        label="State"
        source="state"
        allowEmpty={true}
        choices={[
          { id: 'waiting-for-payment', name: 'Waiting for payment' },
          { id: 'accepted', name: 'Accepted' },
          { id: 'completed', name: 'Completed' },
        ]}
      />
      <SelectInput
        alwaysOn
        label="Product Type"
        source="product.type"
        allowEmpty={true}
        choices={Object.entries(ProductTypes).map(([key, value]) => {
          return { id: key, name: value };
        })}
      />
      <TextInput source="owner.surname" label="User surname" />
      <TextInput source="owner.name" label="User name" />
      <TextInput source="owner.phone" label="User phone number" />
      <TextInput source="promoCode.code" label="Promo Code" alwaysOn />
      <TextInput source="promoCode.seller.code" label="Seller Code" />
      <TextInput source="promoCode.seller.email" label="Seller Email" />
      <SelectInput
        alwaysOn
        label="Pre-Order"
        source="exists[preOrderBuyer]"
        allowEmpty={true}
        initialValue={null}
        choices={[
          { id: true, name: 'Yes' },
          { id: false, name: 'No' },
        ]}
      />
      <SelectInput
        alwaysOn
        label="Partnership"
        source="partnership"
        choices={[
          { name: 'Nessuna Partnership', id: 'custom-filter_property_empty' },
          ...partnershipIds.map((id) => partnerships[id]),
        ]}
        optionText={(record) => record.name}
        allowEmpty={true}
        translateChoice={false}
        onChange={(input) => {
          if (input.target.value)
            props.setFilters({
              ...props.filterValues,
              partnershipId: resourceId(input.target.value),
            });
        }}
      />
      <DateInput label="Creato dal" source="createdAt[strictly_after]" />
      <DateInput label="Creato al" source="createdAt[strictly_before]" />
    </Filter>
  );
};
TicketFilter.propTypes = {
  setFilters: PropTypes.func,
  filterValues: PropTypes.object,
};

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <TicketGenerateButton />
      <ExportButton
        disabled={total === 0}
        targetClass={'App\\Entity\\Ticket'}
        filters={filterValues}
      />
      <TicketImportButton />
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.array,
};

const TicketList = (props) => (
  <ListGuesser
    {...props}
    title="List of Ticket"
    perPage={25}
    filters={<TicketFilter />}
    actions={<ListActions />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <ReferenceField label="User" source="owner" reference="users" link="show" sortable={false}>
      <FunctionField render={(record) => (record ? `${record.surname} ${record.name}` : '')} />
    </ReferenceField>
    <ReferenceField
      label="Partnership"
      source="partnership"
      reference="partnerships"
      link="show"
      sortable={false}
    >
      <FunctionField render={(record) => (record ? <Chip label={record.name} /> : '')} />
    </ReferenceField>
    <ReferenceField
      label="Seller Code"
      source="seller"
      reference="sellers"
      link="show"
      sortable={false}
    >
      <FunctionField render={(record) => (record ? `${record.code}` : '')} />
    </ReferenceField>
    <ReferenceField
      label="Promo Code"
      source="promoCode"
      reference="promo_codes"
      link="show"
      sortable={false}
    >
      <FunctionField render={(record) => (record ? `${record.code}` : '')} />
    </ReferenceField>
    <TextField source="state" label="State" />
    <TextField source="price" label="Price" />
    <ReferenceField
      label="Product Type"
      source="product"
      reference="products"
      link={false}
      sortable={false}
    >
      <FunctionField render={(record) => <ProductTypeChip type={record.type} />} />
    </ReferenceField>
    <ReferenceField
      label="Product"
      source="product"
      reference="products"
      link="show"
      sortable={false}
    >
      <FunctionField render={(record) => `${record.title}`} />
    </ReferenceField>
    <TextField source="platform" label="Platform" />
    <TextField source="preOrderCode" label="Pre-Order Code" />
    <TextField source="codeChallenge" label="Codice esterno" />
    <TextField source="placePrefer" label="Place prefer" />
    <DateField source="datePrefer" label="Date prefer" />
    <ReferenceField
      label="Pre-Order Buyer"
      source="preOrderBuyer"
      reference="pre_order_buyers"
      link="show"
      sortable={false}
    >
      <FunctionField
        render={(record) => (record ? (record.phone ? `${record.phone}` : `${record.email}`) : '')}
      />
    </ReferenceField>
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
  </ListGuesser>
);

export default TicketList;
