import React from 'react';
import { Resource, Layout, AppBar, usePermissions } from 'react-admin';
import { HydraAdmin } from '@api-platform/admin';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { theme } from './theme';

// Providers
import authProvider from './authProvider';
import dataProvider from './dataProvider';
// Sections
import BansResource from './bans';
import ChallengesResource from './challenges';
import ConfigResource from './config';
import LeaderboardsResource from './leaderboards';
import NewsResource from './news';
import PassesResource from './passes';
import ReportsResource from './reports';
import TournamentsResource from './tournaments';
import UsersResource from './users';
import VideosResource from './videos';
import SellerResource from './seller/index';
import PromoCodeResource from './promoCode/index';
import TicketResource from './ticket/index';
import ProductResource from './products';
import PreOrderBuyerResource from './preOrderBuyer';
import StageResource from './stage';
import { isAdvisor } from './utils';
import WinnerResource from './winner';
import ProfileTournamentResource from './profileTournament';
import InAppProductResource from './inAppProduct';
import SponsorCodeResource from './sponsorCode';
import UserSponsorCodeResource from './userSponsorCode';
import ClubsResource from './clubs';
import EventResource from './event';
import PartnerResource from './partner';
import PartnershipResource from './partnership';
import UserBenefitResource from './userBenefit';
import AggregatorLaunchpadResource from './AggregatorLaunchpad';
import ActivityLogResource from './ActivityLog';
import MasterclassResource from './masterclass';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  logo: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'center',
  },
  spacer: {
    flex: 1,
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <Typography variant="h6" color="inherit" className={classes.logo}>
        One Of Us - Admin
      </Typography>
      <span className={classes.spacer} />
    </AppBar>
  );
};

const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;

const MainHydraAdmin = () => {
  const { permissions } = usePermissions();
  return (
    <HydraAdmin
      layout={CustomLayout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      entrypoint={process.env.REACT_APP_API_ENTRYPOINT}
      theme={theme}
    >
      {!isAdvisor(permissions) && <UsersResource />}
      <VideosResource />
      <ChallengesResource />
      <InAppProductResource />

      <LeaderboardsResource />
      <PassesResource />
      <StageResource />
      <WinnerResource />

      <TournamentsResource />
      <MasterclassResource />
      <ProfileTournamentResource />

      <NewsResource />
      <ReportsResource />
      <BansResource />

      <ProductResource />
      <SellerResource />
      <PromoCodeResource />
      <TicketResource />
      <PreOrderBuyerResource />

      <PartnerResource />
      <PartnershipResource />
      <UserBenefitResource />
      <SponsorCodeResource />
      <UserSponsorCodeResource />

      <ClubsResource />
      <EventResource />

      <Resource name="profiles" />
      <Resource name="media" />
      <Resource name="comments" />
      <Resource name="views/users"></Resource>
      <Resource name="views/masterclass"></Resource>
      <Resource name="premium_states"></Resource>

      <ConfigResource />

      <ActivityLogResource />
      <AggregatorLaunchpadResource />
    </HydraAdmin>
  );
};

export default MainHydraAdmin;
