import React from 'react';
import PropTypes from 'prop-types';
import { useQueryWithStore, Loading, Error } from 'react-admin';
import AsideVideo from './AsideVideo';

const AsideComment = (props) => {
  const { commentId } = props;
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'comments',
    payload: { id: commentId },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  return (
    <AsideVideo
      {...props}
      videoId={data.video}
      descriptionField="text"
      commentRecord={data}
      hasTitle={false}
    />
  );
};
AsideComment.propTypes = {
  commentId: PropTypes.string.isRequired,
};

export default AsideComment;
