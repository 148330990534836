import React from 'react';
import {
  Show,
  SimpleShowLayout,
  DateField,
  BooleanField,
  FunctionField,
  ReferenceField,
  ChipField,
  LinearProgress,
  Error,
  Button,
  useMutation,
} from 'react-admin';
import AsideView from '../asideView';
import PropTypes from 'prop-types';
import { isAdmin, isEditor } from '../utils';
import ApproveToggleButton from './utils';

const VisibilityToggleButton = ({ record }) => {
  const [toggleVisibility, { loading, error }] = useMutation({
    type: 'update',
    resource: 'videos',
    payload: record && { id: record.id, data: { visible: !record.visible } },
  });
  if (!record) return null;
  if (loading) return <LinearProgress />;
  if (error) return <Error />;
  return (
    <Button
      label={record.visible ? 'Nascondi video' : 'Rendi nuovamente visibile'}
      color="primary"
      variant="contained"
      onClick={toggleVisibility}
    />
  );
};
VisibilityToggleButton.propTypes = {
  record: PropTypes.object,
};

const hasPermissions = (permissions) => isAdmin(permissions) || isEditor(permissions);

const VideosShow = ({ permissions, ...props }) => (
  <Show aside={<AsideView />} {...props}>
    <SimpleShowLayout>
      {hasPermissions(permissions) && <BooleanField source="visible" />}
      {hasPermissions(permissions) && <VisibilityToggleButton />}
      <BooleanField source="approve" />
      <ApproveToggleButton />
      <DateField source="createdAt" />
      <ReferenceField label="Owner" source="owner" reference="users" link="show">
        <FunctionField render={(record) => `${record.surname} ${record.name}`} />
      </ReferenceField>
      <ReferenceField label="Challenge" source="challenge" reference="challenges" link="show">
        <ChipField source="title" />
      </ReferenceField>
      <ReferenceField label="Stage" source="stage" reference="stages" link="show">
        <ChipField source="title" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

VideosShow.propTypes = {
  permissions: PropTypes.array,
  props: PropTypes.any,
};

export default VideosShow;
