import React from 'react';
import {
  DateField,
  Show,
  SimpleShowLayout,
  ReferenceField,
  FunctionField,
  ChipField,
  TextField,
} from 'react-admin';
import { tournamentOptionText } from '../utils';

const ProfileTournamentShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="User" source="profile" reference="profiles" sortable={false}>
        <ReferenceField source="owner" reference="users" link="show">
          <FunctionField render={(record) => `${record.surname} ${record.name}`} />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField source="tournament" reference="tournaments" sortable={false} link="show">
        <FunctionField
          render={(record) => (
            <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
          )}
        />
      </ReferenceField>
      <ReferenceField source="product" reference="in_app_products" sortable={false} link="show">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="purchaseReceipt" />
      <TextField source="paymentSystem" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
);

export default ProfileTournamentShow;
