import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import InAppProductCreate from './InAppProductCreate';
import InAppProductEdit from './InAppProductEdit';
import InAppProductList from './InAppProductList';
import InAppProductShow from './InAppProductShow';
import { isAdmin } from '../utils';

const InAppProductResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={InAppProductList}
      show={InAppProductShow}
      create={InAppProductCreate}
      edit={InAppProductEdit}
      icon={ShoppingBasket}
    />
  );
};
InAppProductResource.defaultProps = {
  name: 'in_app_products',
};

export default InAppProductResource;
