import PropTypes from 'prop-types';
import React from 'react';
import {
  AutocompleteInput,
  Edit,
  Error,
  Loading,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useQueryWithStore,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { resourceId } from '../utils';

import { validateStage, validateTarget } from './validators';

const StageFilter = () => {
  const { values } = useFormState();

  const filter =
    values && values.target
      ? { 'profile.owner.id': resourceId(values.target), visible: true }
      : { visible: true };

  const { loaded, error, data } = useQueryWithStore({
    type: 'getManyReference',
    resource: 'profile_tournaments',
    payload: {
      pagination: { page: 1 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter,
    },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const disabled = data.length !== 1;

  return (
    <ReferenceInput
      disabled={disabled}
      label="Fase"
      source="stage"
      reference="stages"
      required
      validate={validateStage}
      filterToQuery={(searchText) => ({
        title: searchText,
        'tournament.id': resourceId(data[0].tournament),
      })}
    >
      <SelectInput optionText="title" />
    </ReferenceInput>
  );
};

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const PassesEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceInput
          label="User"
          source="target"
          reference="users"
          validate={validateTarget}
          disabled={true}
          filterToQuery={(searchText) => ({
            search: searchText,
            role: 'PLAYER',
            verifiedPhone: true,
            visible: true,
          })}
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(record) => `${record.surname} ${record.name} ${record.phone}`}
          />
        </ReferenceInput>
        <TextInput multiline source="description" fullWidth="true" />
        <SelectInput
          source="type"
          choices={[
            { id: 'GENERAL', name: 'General' },
            { id: 'MONTHLY', name: 'Monthly' },
            { id: 'CHALLENGE', name: 'Challenge' },
            { id: 'EXTRA', name: 'Extra' },
            { id: 'STAGE', name: 'Fase' },
          ]}
        />
        <StageFilter />
      </SimpleForm>
    </Edit>
  );
};

export default PassesEdit;
