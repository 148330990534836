import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, FunctionField } from 'react-admin';
import { getPartnershipDisplayName } from '../partnership/utils';

const SponsorCodeShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="code" label="Code" />
      <ReferenceField source="partnership" label="Partnership" reference="partnerships">
        <FunctionField render={(record) => getPartnershipDisplayName(record)} />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default SponsorCodeShow;
