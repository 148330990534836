import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { DateField, TextField } from 'react-admin';

const ClubsList = (props) => (
  <ListGuesser
    {...props}
    title="List of Clubs"
    perPage={25}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <TextField source="name" label="Name" />
    <TextField source="vat" label="P.Iva / Cod. FIs." />
    <TextField source="managerName" label="Manager name" />
    <TextField source="managerSurname" label="Manager Surname" />
    <TextField source="assignment" label="Assignment" />
    <TextField source="phone" label="Phone" />
    <TextField source="email" label="Email" />
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
  </ListGuesser>
);

export default ClubsList;
