import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AvailablePlatforms } from './utils';
import InfoBox from './InfoBox';

const useOperativeSystemStyle = makeStyles({
  android: {
    backgroundColor: '#3DDC84',
    color: '#fff',
    textTransform: 'uppercase',
  },
  ios: {
    backgroundColor: '#000000',
    color: '#fff',
    textTransform: 'uppercase',
  },
});

const useAvailablePlatformsStyle = makeStyles({
  android: {
    backgroundColor: '#3DDC84',
    color: '#fff',
    textTransform: 'uppercase',
  },
  ios: {
    backgroundColor: '#000000',
    color: '#fff',
    textTransform: 'uppercase',
  },
  mobile: {
    backgroundColor: '#1e44d0',
    color: '#fff',
    textTransform: 'uppercase',
  },
  web: {
    backgroundColor: '#7a51e0',
    color: '#fff',
    textTransform: 'uppercase',
  },
  any: {
    backgroundColor: '#e52d56',
    color: '#fff',
    textTransform: 'uppercase',
  },
});

export const OperatingSystemListField = ({ platforms, record, source }) => {
  const classes = useOperativeSystemStyle();
  let _p = platforms;

  if ((!platforms || platforms.length) && record && source) _p = record[source];

  return (_p ?? []).map((platform) => (
    <Chip key={platform} label={platform} className={classes[platform]} />
  ));
};
OperatingSystemListField.propTypes = {
  platforms: PropTypes.array,
  record: PropTypes.object,
  source: PropTypes.string,
};

export default OperatingSystemListField;

export const AvailablePlatformsField = ({ record, source, infoBoxText, infoBoxLevel }) => {
  const classes = useAvailablePlatformsStyle();
  const platform = record[source];
  const label = AvailablePlatforms[platform];

  if (!platform && infoBoxText) return <InfoBox text={infoBoxText} level={infoBoxLevel} />;
  if (!platform) return <Chip label={'Any platform'} className={classes['any']} />;

  return <Chip label={label} className={classes[platform]} />;
};
AvailablePlatformsField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  infoBoxText: PropTypes.string,
  infoBoxLevel: PropTypes.string,
};
