import RssFeed from '@material-ui/icons/RssFeed';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdmin, isEditor } from '../utils';
import NewsCreate from './NewsCreate';
import NewsEdit from './NewsEdit';
import NewsList from './NewsList';
import NewsShow from './NewsShow';

const NewsResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions) && !isEditor(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={NewsList}
      show={NewsShow}
      create={NewsCreate}
      edit={NewsEdit}
      icon={RssFeed}
    />
  );
};
NewsResource.defaultProps = {
  name: 'news',
};

export default NewsResource;
