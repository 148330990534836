import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import {
  BooleanField,
  Button,
  DateField,
  Filter,
  TextField,
  TextInput,
  useMutation,
  ReferenceField,
  ChipField,
  FunctionField,
} from 'react-admin';
import { tournamentOptionText } from '../utils';
import { ProductTypeChip } from './products.util';

const EnableToggleButton = ({ record }) => {
  const [toggleEnable, { loading, error }] = useMutation({
    type: 'update',
    resource: 'products',
    payload: { id: record.id, data: { enable: !record.enable } },
  });
  return (
    <Button
      label={record.enable ? 'Disable' : 'Enable'}
      onClick={toggleEnable}
      disabled={loading || error}
      startIcon={record.enable ? <VisibilityOff /> : <Visibility />}
    />
  );
};
EnableToggleButton.propTypes = {
  record: PropTypes.object,
};

const ProductFilter = (props) => (
  <Filter {...props}>
    <TextInput source="title" label="Title" alwaysOn />
  </Filter>
);

const ProductList = (props) => (
  <ListGuesser
    {...props}
    title="List of Product"
    perPage={25}
    filters={<ProductFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <TextField source="title" label="Title" />
    <ProductTypeChip label="Type" source="type" />
    <TextField source="price" label="Price" />
    <BooleanField source="main" sortable={false} />
    <BooleanField source="enable" sortable={false} />
    <ReferenceField label="Torneo" source="tournament" reference="tournaments" sortable={false}>
      <FunctionField
        render={(record) => (
          <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
        )}
      />
    </ReferenceField>
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
    <EnableToggleButton />
  </ListGuesser>
);

export default ProductList;
