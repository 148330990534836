import ReportIcon from '@material-ui/icons/Report';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdmin, isEditor } from '../utils';
import ReportsList from './ReportsList';
import ReportsShow from './ReportsShow';

const ReportsResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions) && !isEditor(permissions)) {
    return <Resource {...props} />;
  }

  return <Resource {...props} list={ReportsList} show={ReportsShow} icon={ReportIcon} />;
};
ReportsResource.defaultProps = {
  name: 'reports',
};

export default ReportsResource;
