import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import {
  BooleanField,
  DateField,
  Filter,
  TextField,
  ReferenceField,
  ReferenceInput,
  ChipField,
  SelectInput,
  FunctionField,
} from 'react-admin';
import { tournamentOptionText } from '../utils';

const StageFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Torneo" source="tournament.id" reference="tournaments" alwaysOn>
      <SelectInput optionText={(record) => tournamentOptionText(record)} />
    </ReferenceInput>
  </Filter>
);

const StageList = (props) => (
  <ListGuesser
    {...props}
    title="List of Stage"
    perPage={25}
    filters={<StageFilter />}
    bulkActionButtons={false}
    sort={{ field: 'startDate', order: 'ASC' }}
  >
    <TextField source="title" />
    <ReferenceField label="Torneo" source="tournament" reference="tournaments" sortable={false}>
      <FunctionField
        render={(record) => (
          <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
        )}
      />
    </ReferenceField>
    <TextField source="sequence" label="Ordinamento" />
    <DateField source="startDate" showTime={false} />
    <DateField source="endDate" showTime={false} />
    <BooleanField label="Your chance" source="yourChance" sortable={false} />
  </ListGuesser>
);

export default StageList;
