import BlockIcon from '@material-ui/icons/Block';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdmin, isEditor } from '../utils';
import BansCreate from './BansCreate';
import BansEdit from './BansEdit';
import BansList from './BansList';
import BansShow from './BansShow';

const BansResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions) && !isEditor(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={BansList}
      show={BansShow}
      create={BansCreate}
      edit={BansEdit}
      icon={BlockIcon}
    />
  );
};
BansResource.defaultProps = {
  name: 'bans',
};

export default BansResource;
