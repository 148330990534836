import BusinessCenter from '@material-ui/icons/BusinessCenter';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import SellerCreate from './SellerCreate';
import SellerEdit from './SellerEdit';
import SellerList from './SellerList';
import SellerShow from './SellerShow';
import { isAdmin, isEditor } from '../utils';

const SellerResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions) && !isEditor(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={SellerList}
      show={SellerShow}
      create={SellerCreate}
      edit={SellerEdit}
      icon={BusinessCenter}
    />
  );
};
SellerResource.defaultProps = {
  name: 'sellers',
};

export default SellerResource;
