import BookmarkIcon from '@material-ui/icons/Bookmark';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import PassesCreate from './PassesCreate';
import PassesEdit from './PassesEdit';
import PassesList from './PassesList';
import PassesShow from './PassesShow';
import {isAdvisor, isScoutPlay2Give} from '../utils';

const PassesResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdvisor(permissions) || isScoutPlay2Give(permissions) ) {
    return <Resource {...props} />;
  }
  return (
    <Resource
      {...props}
      list={PassesList}
      show={PassesShow}
      create={PassesCreate}
      edit={PassesEdit}
      icon={BookmarkIcon}
    />
  );
};
PassesResource.defaultProps = {
  name: 'passes',
};

export default PassesResource;
