import React, { cloneElement } from 'react';
import { ListGuesser } from '@api-platform/admin';
import { VisibilityOff } from '@material-ui/icons';
import {
  BooleanField,
  ReferenceField,
  ChipField,
  DeleteButton,
  Filter,
  ReferenceInput,
  SelectInput,
  FunctionField,
  TextInput,
  useMutation,
  Button,
  DateField,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  NullableBooleanInput,
  DateInput,
  CreateButton,
} from 'react-admin';
import PropTypes from 'prop-types';

import { isScout, isAdmin, isAdvisor, isEditor } from '../utils';
import ApproveToggleButton from './utils';
import ExportButton from '../ExportButton';

const BulkHideButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    'videos',
    selectedIds,
    { visible: false },
    {
      onSuccess: () => {
        refresh();
        notify('Video nascosti');
        unselectAll('videos');
      },
      onFailure: () => notify('Errore: video non nascosti', 'warning'),
    }
  );
  return (
    <Button label="Nascondi" onClick={updateMany} disabled={loading}>
      <VisibilityOff />
    </Button>
  );
};
BulkHideButton.propTypes = {
  selectedIds: PropTypes.array,
};

const VisibilityToggleButton = ({ record }) => {
  const [toggleVisibility, { loading, error }] = useMutation({
    type: 'update',
    resource: 'videos',
    payload: { id: record.id, data: { visible: !record.visible } },
  });
  return (
    <Button
      label={record.visible ? 'Nascondi' : 'Rendi visibile'}
      onClick={toggleVisibility}
      disabled={loading || error}
    />
  );
};
VisibilityToggleButton.propTypes = {
  record: PropTypes.object,
};

const VideosFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Challenge" source="challenge.id" reference="challenges">
      <SelectInput optionText="title" />
    </ReferenceInput>
    <TextInput alwaysOn source="owner.surname" label="Cognome" />
    <TextInput alwaysOn source="owner.email" label="Email" />
    <NullableBooleanInput source="exists[challenge]" label="Challenge video" defaultValue={false} />
    <NullableBooleanInput
      source="exists[profile]"
      label="Presentazione video"
      defaultValue={false}
    />
    <NullableBooleanInput source="exists[stage]" label="Fase video" defaultValue={false} />
    <DateInput label="Creato dal" source="createdAt[strictly_after]" alwaysOn />
    <DateInput label="Creato al" source="createdAt[strictly_before]" alwaysOn />
    <NullableBooleanInput alwaysOn source="visible" label="Attivi" defaultValue={false} />
    <NullableBooleanInput alwaysOn source="approve" label="Approvati" defaultValue={false} />
  </Filter>
);

const ListActions = (props) => {
  const { className, filters, permissions, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    basePath,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {!isScout(permissions) && <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        targetClass={'App\\Entity\\Video'}
        filters={filterValues}
      />
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.object,
  permissions: PropTypes.array,
};

const VideosList = ({ permissions, ...props }) => (
  <ListGuesser
    {...props}
    title="List of videos"
    perPage={25}
    filters={<VideosFilter />}
    actions={<ListActions />}
    filter={
      permissions && !(isAdmin(permissions) || isEditor(permissions))
        ? { 'owner.id': permissions.id }
        : undefined
    }
    sort={{ field: 'createdAt', order: 'DESC' }}
    hasEdit={false}
    bulkActionButtons={!isAdvisor(permissions) && <BulkHideButton />}
  >
    <ReferenceField
      label="User"
      source="owner"
      reference="users"
      link="show"
      sortable={false}
      sortBy="owner.surname"
    >
      <FunctionField render={(record) => `${record.surname} ${record.name}`} />
    </ReferenceField>
    {isAdmin(permissions) && (
      <ReferenceField label="Phone" source="owner" reference="users" sortable={false}>
        <FunctionField render={(record) => record.phone} />
      </ReferenceField>
    )}
    <ReferenceField
      label="Challenge"
      source="challenge"
      reference="challenges"
      link="show"
      sortable={false}
    >
      <ChipField source="title" />
    </ReferenceField>
    <ReferenceField label="Stage" source="stage" reference="stages" link="show" sortable={false}>
      <ChipField source="title" />
    </ReferenceField>
    <FunctionField
      label="Presentation"
      source="is_presentation"
      render={(record, source) => (
        <BooleanField record={{ ...record, is_presentation: !!record.profile }} source={source} />
      )}
    />
    <DateField source="createdAt" />
    {!isAdvisor(permissions) && <BooleanField source="visible" sortable={false} />}
    {!isAdvisor(permissions) && <BooleanField source="approve" sortable={false} />}
    {!isAdvisor(permissions) && <VisibilityToggleButton />}
    {!isAdvisor(permissions) && <ApproveToggleButton />}
    {isAdvisor(permissions) && <DeleteButton />}
  </ListGuesser>
);

VideosList.propTypes = {
  permissions: PropTypes.object,
  props: PropTypes.any,
};

export default VideosList;
