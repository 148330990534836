import VideocamIcon from '@material-ui/icons/Videocam';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isScout } from '../utils';
import VideosList from './VideosList';
import VideosShow from './VideosShow';
import VideoCreate from './VideoCreate';
import VideoEdit from './VideoEdit';

const VideosResource = (props) => {
  const { permissions } = usePermissions();

  if (isScout(permissions)) {
    return <Resource {...props} show={VideosShow} />;
  }

  return (
    <Resource
      {...props}
      list={VideosList}
      show={VideosShow}
      create={VideoCreate}
      edit={VideoEdit}
      icon={VideocamIcon}
    />
  );
};
VideosResource.defaultProps = {
  name: 'videos',
};

export default VideosResource;
