import React from 'react';
import {
  BooleanField,
  Show,
  DateField,
  TextField,
  ReferenceField,
  ChipField,
  FunctionField,
} from 'react-admin';
import { tournamentOptionText } from '../utils';
import FormGrid, { FormGridItem } from '../FormGrid';

const StageShow = (props) => (
  <Show {...props}>
    <FormGrid>
      <FormGridItem sx={6}>
        <TextField source="title" />
        <FunctionField
          source="description"
          render={(stage) => <div dangerouslySetInnerHTML={{ __html: stage?.description }}></div>}
        />
        <ReferenceField label="Torneo" source="tournament" reference="tournaments">
          <FunctionField
            render={(record) => (
              <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
            )}
          />
        </ReferenceField>
        <TextField source="sequence" label="Ordinamento" />
        <DateField source="startDate" showTime={false} />
        <DateField source="endDate" showTime={false} />
        <BooleanField label="Your chance" source="yourChance" />
      </FormGridItem>
      <FormGridItem sx={6}>
        <FunctionField
          label="Informazioni fase"
          source="description"
          render={(stage) => <div dangerouslySetInnerHTML={{ __html: stage?.extra?.info }}></div>}
        />
        <FunctionField
          label="Descrizione: la fase sta per cominciare"
          source="description"
          render={(stage) => (
            <div dangerouslySetInnerHTML={{ __html: stage?.extra?.headerStarting }}></div>
          )}
        />
        <FunctionField
          label="Descrizione: inizio fase"
          source="description"
          render={(stage) => (
            <div dangerouslySetInnerHTML={{ __html: stage?.extra?.headerStarted }}></div>
          )}
        />
        <FunctionField
          label="Descrizione: fine fase"
          source="description"
          render={(stage) => (
            <div dangerouslySetInnerHTML={{ __html: stage?.extra?.headerEnded }}></div>
          )}
        />
      </FormGridItem>
    </FormGrid>
  </Show>
);

export default StageShow;
