import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceField, FunctionField } from 'react-admin';
import { Chip } from '@material-ui/core';

import { PassTypes } from './passes/passes.util';

export const PassReferenceField = ({ source, reference, label, ...props }) => {
  return (
    <ReferenceField
      label={label ?? 'Pass'}
      source={source}
      reference={reference ?? 'passes'}
      link="show"
      sortable={false}
      {...props}
    >
      <FunctionField
        render={(record) => <Chip label={`${record.title} - ${getPassName(record)}`} />}
      />
    </ReferenceField>
  );
};
PassReferenceField.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  label: PropTypes.string,
};

export function getPassName(record) {
  return (
    PassTypes.filter((type) => type.id.toUpperCase() === record?.type?.toUpperCase())[0]?.name ??
    record?.type
  );
}

export default PassReferenceField;
