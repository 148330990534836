import Icon from '@material-ui/icons/Dashboard';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdmin, isEditor } from '../utils';
import PartnerList from './PartnerList';
import PartnerCreate from './PartnerCreate';
import PartnerShow from './PartnerShow';
import PartnerEdit from './PartnerEdit';

const PartnerResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdmin(permissions) || isEditor(permissions)) {
    return (
      <Resource
        {...props}
        list={PartnerList}
        create={PartnerCreate}
        show={PartnerShow}
        edit={PartnerEdit}
        icon={Icon}
      />
    );
  }

  return <Resource {...props} />;
};
PartnerResource.defaultProps = {
  name: 'partners',
};

export default PartnerResource;
