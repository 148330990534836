import EventIcon from '@material-ui/icons/EmojiEvents';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import TournamentsCreate from './TournamentsCreate';
import TournamentsEdit from './TournamentsEdit';
import TournamentsList from './TournamentsList';
import TournamentsShow from './TournamentsShow';
import {isAdvisor, isScout, isScoutPlay2Give} from '../utils';

const TournamentsResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdvisor(permissions) || isScoutPlay2Give(permissions)) {
    return <Resource {...props} />;
  }

  if (isScout(permissions)) {
    return <Resource {...props} list={TournamentsList} show={TournamentsShow} icon={EventIcon} />;
  }

  return (
    <Resource
      {...props}
      list={TournamentsList}
      show={TournamentsShow}
      edit={TournamentsEdit}
      create={TournamentsCreate}
      icon={EventIcon}
    />
  );
};
TournamentsResource.defaultProps = {
  name: 'tournaments',
};

export default TournamentsResource;
