import LocalOffer from '@material-ui/icons/LocalOffer';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import PromoCodeCreate from './PromoCodeCreate';
import PromoCodeEdit from './PromoCodeEdit';
import PromoCodeList from './PromoCodeList';
import PromoCodeShow from './PromoCodeShow';
import { isAdmin, isEditor } from '../utils';

const PromoCodeResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions) && !isEditor(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={PromoCodeList}
      show={PromoCodeShow}
      create={PromoCodeCreate}
      edit={PromoCodeEdit}
      icon={LocalOffer}
    />
  );
};
PromoCodeResource.defaultProps = {
  name: 'promo_codes',
};

export default PromoCodeResource;
