import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
} from 'react-admin';
import {validateRequired} from "./validators";
import {ConfigEditForm} from "./utils";

const ConfigCreate = (props) => {
  return (
      <Create {...props}>
        <SimpleForm>
          <TextInput source="key" validate={validateRequired} />
          <ConfigEditForm />
          <BooleanInput source="private" />
        </SimpleForm>
      </Create>
  );
};

export default ConfigCreate;
