import React from 'react';
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { tournamentOptionText } from '../utils';
import { validateRequired } from './validators';
import InfoBox from '../InfoBox';
import { TournamentRoles } from '../users/users.util';

const ProductCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" label="Title" validate={validateRequired} />
        <SelectInput
          source="type"
          choices={[
            { id: 'premium', name: 'Premium' },
            { id: 'your_chance', name: 'Your Chance' },
          ]}
        />
        <TextInput source="description" label="Description" />
        <TextInput source="price" label="Price" validate={validateRequired} />
        <BooleanInput label="Main product" source="main" initialValue={false} />
        <BooleanInput label="Enable" source="enable" initialValue={true} />
        <ReferenceInput
          label="Torneo"
          source="tournament"
          reference="tournaments"
          validate={validateRequired}
        >
          <SelectInput optionText={(record) => tournamentOptionText(record)} />
        </ReferenceInput>
        <SelectInput
          source="automaticDiscountBeneficiary"
          allowEmpty={true}
          label="Sconto automatico"
          choices={Object.entries(TournamentRoles).map(([key, value]) => {
            return { id: key, name: value };
          })}
        />
        <InfoBox
          level={'info'}
          text={
            'Il ruolo selezionato garantirà automaticamente uno sconto interno (PromoCode interno) a tutti gli utenti aventi ruolo per il torneo del prodotto corrente.'
          }
        />
        <InfoBox
          level={'warning'}
          text={`
          <h3>Verrà utilizzato il primo PromoCode che corrisponde ai seguenti parametri:</h3>
          <ul>
            <li>Interno: campo <strong>internal</strong> attiva.</li>
            <li>Attivo: campo <strong>enable</strong> attiva.</li>
            <li>Prodotto: dev'essere associato al prodotto corrente (creare il prodotto prima del PromoCode).</li>
            <li>Tipologia: campo <strong>type</strong> deve corrispondere alla tipologia del prodotto corrente.</li>
          </ul>
        `}
        />
      </SimpleForm>
    </Create>
  );
};

export default ProductCreate;
