import React from 'react';
import { Resource, usePermissions } from 'react-admin';
import ActivityLogIcon from '@material-ui/icons/AdbOutlined';

import { isAdmin, isEditor } from '../utils';
import ActivityLogList from './ActivityLogList';
import ActivityLogShow from './ActivityLogShow';

const ActivityLogResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions) || !isEditor(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource {...props} list={ActivityLogList} show={ActivityLogShow} icon={ActivityLogIcon} />
  );
};
ActivityLogResource.defaultProps = {
  name: 'activity_logs',
};

export default ActivityLogResource;
