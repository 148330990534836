import Stars from '@material-ui/icons/Stars';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import StageCreate from './StageCreate';
import StageEdit from './StageEdit';
import StageList from './StageList';
import StageShow from './StageShow';
import {isAdvisor, isScout, isScoutPlay2Give} from '../utils';

const StageResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdvisor(permissions) || isScoutPlay2Give(permissions)) {
    return <Resource {...props} />;
  }

  if (isScout(permissions)) {
    return <Resource {...props} list={StageList} show={StageShow} icon={Stars} />;
  }

  return (
    <Resource
      {...props}
      list={StageList}
      show={StageShow}
      create={StageCreate}
      edit={StageEdit}
      icon={Stars}
    />
  );
};
StageResource.defaultProps = {
  name: 'stages',
};

export default StageResource;
