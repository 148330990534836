import React from 'react';
import SponsorCodeImportButton from './SponsorCodeImportButton';
import { TopToolbar, useListContext, usePermissions, CreateButton } from 'react-admin';
import { isAdmin, isEditor } from '../utils';

const hasPermissions = (permissions) => isEditor(permissions) || isAdmin(permissions);
const SponsorCodeListActions = () => {
  const { basePath } = useListContext();
  const { permissions } = usePermissions();

  return (
    <TopToolbar>
      {hasPermissions(permissions) && <CreateButton basePath={basePath} />}
      {hasPermissions(permissions) && <SponsorCodeImportButton />}
    </TopToolbar>
  );
};
SponsorCodeListActions.propTypes = {};

export default SponsorCodeListActions;
