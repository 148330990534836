// import PropTypes from 'prop-types';
import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput
} from 'react-admin';

import { validateRequired } from './validators';
import {ConfigEditForm} from "./utils";

const ConfigEdit = (props) => {
  return (
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="key" disabled={true} validate={validateRequired} />
          <ConfigEditForm />
          <BooleanInput source="private" />
        </SimpleForm>
      </Edit>
  );
};

export default ConfigEdit;
