import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ChipField,
  FunctionField,
} from 'react-admin';
import { tournamentOptionText } from '../utils';

const WinnerShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Owner" source="owner" reference="users" link="show">
        <FunctionField render={(record) => `${record.surname} ${record.name}`} />
      </ReferenceField>
      <ReferenceField label="Torneo" source="tournament" reference="tournaments">
        <FunctionField
          render={(record) => (
            <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
          )}
        />
      </ReferenceField>
      <TextField source="place" label="Posizione" />
    </SimpleShowLayout>
  </Show>
);

export default WinnerShow;
