import React, { cloneElement } from 'react';
import { ListGuesser } from '@api-platform/admin';
import {
  DateField,
  ReferenceField,
  FunctionField,
  ChipField,
  Filter,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import PropTypes from 'prop-types';

import { isScout, tournamentOptionText } from '../utils';
import ExportButton from '../ExportButton';

const ProfileTournamentFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Torneo" source="tournament.id" reference="tournaments" alwaysOn>
      <SelectInput optionText={(record) => tournamentOptionText(record)} />
    </ReferenceInput>
    <TextInput label="Search surname" source="profile.owner.surname" alwaysOn />
    <TextInput label="Search name" source="profile.owner.name" alwaysOn />
  </Filter>
);

const ListActions = (props) => {
  const { className, filters, permissions, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    basePath,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {!isScout(permissions) && <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        targetClass={'App\\Entity\\ProfileTournament'}
        filters={filterValues}
      />
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: CreateButton.string,
  filters: PropTypes.object,
  permissions: PropTypes.array,
};

const ProfileTournamentList = (props) => (
  <ListGuesser
    {...props}
    title="List of participant"
    perPage={25}
    bulkActionButtons={false}
    sort={{ field: 'createdAt', order: 'DESC' }}
    default
    filters={<ProfileTournamentFilter />}
    actions={<ListActions />}
    filterDefaultValues={{
      visible: true,
      'profile.owner.role': 'PLAYER',
      'profile.owner.visible': true,
    }}
  >
    <ReferenceField label="User" source="owner" reference="users" sortable={false} link="show">
      <FunctionField render={(record) => `${record.surname} ${record.name}`} />
    </ReferenceField>
    <ReferenceField source="tournament" reference="tournaments" sortable={false} link="show">
      <FunctionField
        render={(record) => (
          <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
        )}
      />
    </ReferenceField>
    <ReferenceField source="product" reference="in_app_products" sortable={false} link="show">
      <ChipField source="name" />
    </ReferenceField>
    <DateField source="createdAt" />
  </ListGuesser>
);

export default ProfileTournamentList;
