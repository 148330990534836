import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
} from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import authProvider from './authProvider';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
export const fetchOptions = (options) =>
  authProvider.checkExistingToken()
    ? {
        ...options,
        headers: new Headers({
            'OOU-Client-Platform': 'web',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        }),
      }
    : {
          ...options,
          headers: new Headers({
              'OOU-Client-Platform': 'web',
          }),
      };

const fetchHydra = (url, options = {}) => {
  if (url.href.includes('%255B')) {
    url.href = url.href.replace('%255B', '%5B').replace('%255D', '%5D');
    url.search = url.search.replace('%255B', '%5B').replace('%255D', '%5D');
  }
  return baseFetchHydra(url, fetchOptions(options));
};

const apiDocumentationParser = (entrypoint) =>
  parseHydraDocumentation(entrypoint, fetchOptions({})).then(
    ({ api }) => ({ api }),
    (result) => {
      switch (result.status) {
        case 401:
          return authProvider.logout().then(() =>
            Promise.resolve({
              api: result.api,
              customRoutes: [
                // this reload mechanism in the original code from api-platform apparently is needed: without it, react-admin can enter in a login-home infinite redirect cycle. Keep that code until a better understanding of the apparent bug from react-admin. To reproduce it, remove the reload and keep only the redirect, then induce a forced logout by editing the localstorage JWT token and try a navigation to a different resource, then reload the page, and try to login. For some reason the same bug will not happen when making a manual logout, indicating a possible bug on react-admin code."
                <Route
                  key="login"
                  path="/"
                  render={() => {
                    return authProvider.checkExistingToken() ? (
                      window.location.reload()
                    ) : (
                      <Redirect to="/login" />
                    );
                  }}
                />,
              ],
            })
          );
        default:
          return Promise.reject(result);
      }
    }
  );

const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, false);

export default dataProvider;
