import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, SelectInput, useNotify, ReferenceInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Bookmark } from '@material-ui/icons';

import DialogForm from './DialogForm';
import { PropTypes } from 'prop-types';
import authProvider from './authProvider';
import { apiRequest, HTTP_METHOD_GET, HTTP_METHOD_POST, resourceId } from './utils';

const useStyles = makeStyles({
  fullwidth: {
    width: '100%',
  },
});

const AddPassButton = ({ disabled, selectedIds, tournament, resource, source, yourChance }) => {
  const [open, setOpen] = useState(false);
  const [tournamentState, setTournamentState] = useState('');
  const [filteredTournaments, setFilteredTournaments] = useState([]);
  const [stage, setStage] = useState('');
  const [type, setType] = useState('GENERAL');
  const notify = useNotify();

  const handleInit = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!authProvider.checkExistingToken()) {
      notify('Error: passes not created', 'warning');
    }

    if (resource !== 'users') {
      selectedIds = await Promise.all(
        selectedIds.map(async (id) => {
          const response = await apiRequest({
            path: `/${resource}/${resourceId(id)}`,
            method: HTTP_METHOD_GET,
          });
          const data = await response.json();

          if (data[source] instanceof Object) {
            return data[source]['@id'];
          } else {
            return data[source];
          }
        })
      );
    }

    const errors = [];
    selectedIds.forEach(async (id) => {
      try {
        await apiRequest({
          path: '/passes',
          method: HTTP_METHOD_POST,
          payload: {
            target: id,
            stage: stage,
            type: type,
          },
        });
      } catch {
        errors.push(id);
      }
    });

    if (selectedIds.length === errors.length) {
      notify('Error: passes not created', 'warning');
    } else if (errors.length > 0) {
      notify('Error: some passes not created', 'warning');
    } else {
      notify('Passes created');
    }

    setOpen(false);
  };

  useEffect(() => {
    const httpCall = async () => {
      const response = (
        await apiRequest({
          path: '/tournaments',
          method: HTTP_METHOD_GET,
        })
      ).json();

      const tournamentsFiltered =
        (await response)['hydra:member']?.filter((t) => {
          return (tournament ?? []).map((t) => resourceId(t)).includes(t.id);
        }) ?? [];
      setFilteredTournaments(tournamentsFiltered);
    };
    httpCall().catch((e) => console.error(e));
  }, []);

  const classes = useStyles();

  return (
    <>
      <Button label="Add pass" disabled={disabled} onClick={handleInit}>
        <Bookmark />
      </Button>
      <DialogForm
        open={open}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        title="Add pass"
        submitLabel="Add pass"
      >
        <>
          <SelectInput
            className={classes.fullwidth}
            source="type"
            onChange={(event) => setType(event.target.value)}
            value={type}
            choices={[
              { id: 'GENERAL', name: 'General' },
              { id: 'MONTHLY', name: 'Monthly' },
              { id: 'CHALLENGE', name: 'Challenge' },
              { id: 'EXTRA', name: 'Extra' },
              { id: 'STAGE', name: 'Fase' },
            ]}
          />
          <SelectInput
            className={classes.fullwidth}
            label="Torneo"
            source="tournament"
            onChange={(event) => {
              setTournamentState(event.target.value);
            }}
            value={tournamentState}
            required
            choices={filteredTournaments.map((t) => ({
              name: t.title,
              id: t.id,
              value: t.id,
            }))}
          />
          {tournamentState && (
            <ReferenceInput
              className={classes.fullwidth}
              label="Fase"
              source="stage"
              reference="stages"
              required
              value={stage}
              filter={{ yourChance: yourChance ? yourChance : undefined }}
              sort={{ field: 'sequence', order: 'ASC' }}
              onChange={(event) => setStage(event.target.value)}
              filterToQuery={(searchText) => ({
                title: searchText,
                'tournament.id': resourceId(tournamentState),
              })}
            >
              <SelectInput optionText="title" />
            </ReferenceInput>
          )}
        </>
      </DialogForm>
    </>
  );
};

AddPassButton.propTypes = {
  disabled: PropTypes.bool,
  tournament: PropTypes.array,
  resource: PropTypes.string,
  source: PropTypes.string,
  selectedIds: PropTypes.array,
  yourChance: PropTypes.bool,
};

export default AddPassButton;
