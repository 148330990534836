import React from 'react';
import { DateField, Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';
import GenderField from '../fields/GenderField';
import { PartnershipReferenceField } from '../partnership/utils';

const TournamentsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="description" />
      <PartnershipReferenceField></PartnershipReferenceField>
      <DateField source="startDate" />
      <DateField source="endDate" />
      <GenderField source="gender" />
      <BooleanField source="premium" />
      <TextField source="sequence" label="Ordinamento" />
      <TextField source="subscribedPlayers" label="Partecipanti" />
    </SimpleShowLayout>
  </Show>
);

export default TournamentsShow;
