import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  ReferenceField,
  FunctionField,
  ChipField,
  TextField,
  BooleanField,
  ArrayField,
  NumberField,
  Datagrid,
} from 'react-admin';
import { Box, Typography, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MatView from '../materialized-views/mat-view.utils';
import { getCloudFrontMedia, getRawUploadUrl, tournamentOptionText } from '../utils';
import InfoBox from '../InfoBox';
import { PlayerRoleField } from '../fields';
import { FormGrid, FormGridItem } from '../FormGrid';
import { ShowMedia } from '../fields/media.utils';

const useStyles = makeStyles({
  customFieldList: {
    '& > *': {
      width: '100%',
    },
  },
  banner: {
    '--count': 3,
    '--padding': '16px',
    display: 'flex',
    gap: 'var(--padding)',
    flexWrap: 'wrap',
    '& > *': {
      width: 'calc((100% - (var(--count) - 1) * var(--padding)) / var(--count))',
      minWidth: '250px',
      maxWidth: '500px',
    },
  },
});

const EmptyItemList = ({ record }) => {
  const items = record?.items;
  if (
    !items ||
    (Array.isArray(items) && !items.length) ||
    (typeof items === 'object' && !Object.keys(items).length)
  ) {
    return <InfoBox text="Non sono presenti masterclass per questo ruolo" />;
  }
  return null;
};
EmptyItemList.propTypes = {
  record: PropTypes.object,
};

const MasterclassShow = (props) => {
  const classes = useStyles();
  const [masterclass, selectMasterclass] = useState(null);
  const [columnWidth, setColumnWidth] = useState(12);
  const getPath = (path) =>
    /^https?:\/\//.exec(path)
      ? path
      : path.includes('/')
      ? getCloudFrontMedia(path)
      : getRawUploadUrl(path);

  return (
    <MatView.Show {...props} simple={false}>
      <FormGrid>
        <FormGridItem xs={columnWidth}>
          <MatView.Disclaimer></MatView.Disclaimer>
          <MatView.LastUpdatedField
            source="updatedAt"
            label="Ultimo aggiornamento"
          ></MatView.LastUpdatedField>
          <ReferenceField label="Torneo" source="tournament" reference="tournaments">
            <FunctionField
              render={(record) => (
                <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
              )}
            />
          </ReferenceField>
          <PlayerRoleField source="category" />
          <FunctionField
            label="Banners"
            source="banner"
            render={(r) => (
              <div className={classes.banner}>
                <ShowMedia
                  label="Banner (1x)"
                  source="banner1"
                  record={r}
                  mimeType="image/*"
                  empty={
                    <InfoBox level="error">
                      <strong>No banner was provided</strong>
                    </InfoBox>
                  }
                />
                <ShowMedia
                  label="Banner (2x)"
                  source="banner2"
                  record={r}
                  mimeType="image/*"
                  empty={
                    <InfoBox level="error">
                      <strong>The 2x resolution of the banner was not provided</strong>
                    </InfoBox>
                  }
                />
                <ShowMedia
                  label="Banner (3x)"
                  source="banner3"
                  record={r}
                  mimeType="image/*"
                  empty={
                    <InfoBox level="error">
                      <strong>The 3x resolution of the banner was not provided</strong>
                    </InfoBox>
                  }
                />
              </div>
            )}
          />
          <BooleanField source="complete" />
          <BooleanField source="disabled" />
          <ArrayField label="Elenco masterclass" source="items" className={classes.customFieldList}>
            <Datagrid
              rowClick={(record) => {
                selectMasterclass(typeof record === 'string' ? JSON.parse(record) : record);
                setColumnWidth(7);
              }}
              isRowSelectable={() => true}
              size="medium"
            >
              <NumberField label="#" source="id" sortable={false} />
              <TextField label="Name" source="name" sortable={false} />
              <BooleanField label="Premium" source="premium" sortable={false} />
              <BooleanField label="Disabilitato" source="disabled" sortable={false} />
            </Datagrid>
          </ArrayField>
          <EmptyItemList />
        </FormGridItem>
        {columnWidth < 12 && masterclass && (
          <FormGridItem xs={12 - columnWidth}>
            <FunctionField
              label=""
              source="name"
              render={() => (
                <Box>
                  <Typography
                    style={{ padding: '1.5rem 0 1rem', width: '100%', textAlign: 'center' }}
                    variant={'h4'}
                  >
                    {masterclass?.name}
                  </Typography>
                  {masterclass.media?.path && (
                    <CardMedia
                      component={/^video/.exec(masterclass.media.mimeType) ? 'video' : 'img'}
                      src={getPath(masterclass.media.path)}
                      controls
                    />
                  )}
                  {!masterclass.media?.path && (
                    <InfoBox level="error">
                      <strong>No video was provided</strong>
                    </InfoBox>
                  )}
                  {masterclass.media?.poster && (
                    <CardMedia component="img" src={getPath(masterclass.media.poster)} controls />
                  )}
                  {!masterclass.media?.poster && (
                    <InfoBox level="error">
                      <strong>No poster was provided</strong>
                    </InfoBox>
                  )}
                  <Typography
                    style={{ padding: '1.5rem 0 1rem', width: '100%', textAlign: 'center' }}
                    variant={'body2'}
                  >
                    <span dangerouslySetInnerHTML={{ __html: masterclass?.description }}></span>
                  </Typography>
                </Box>
              )}
            />
          </FormGridItem>
        )}
      </FormGrid>
    </MatView.Show>
  );
};
MasterclassShow.propTypes = {
  props: PropTypes.object,
};

export default MasterclassShow;
