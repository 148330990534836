import Icon from '@material-ui/icons/Class';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdvisor, isScout } from '../utils';
import MasterclassList from './MasterclassList';
import MasterclassShow from './MasterclassShow';
import MasterclassEdit from './MasterclassEdit';
import MasterclassCreate from './MasterclassCreate';

const MasterclassResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdvisor(permissions) || isScout(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={MasterclassList}
      show={MasterclassShow}
      edit={MasterclassEdit}
      create={MasterclassCreate}
      icon={Icon}
    />
  );
};
MasterclassResource.defaultProps = {
  name: 'masterclass',
};

export default MasterclassResource;
