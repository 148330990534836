import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { AddShoppingCart, RemoveShoppingCart, Visibility, VisibilityOff } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import {
  BooleanField,
  Button,
  DateField,
  DateInput,
  Filter,
  FunctionField,
  NullableBooleanInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useMutation,
} from 'react-admin';
import { tournamentOptionText } from '../utils';

const SoldOutToggleButton = ({ record }) => {
  const [toggleSoldOut, { loading, error }] = useMutation({
    type: 'update',
    resource: 'events',
    payload: { id: record.id, data: { soldOut: !record.soldOut } },
  });
  return (
    <Button
      label={record.soldOut ? 'Make available' : 'Make sold-out'}
      onClick={toggleSoldOut}
      disabled={loading || error}
      startIcon={record.soldOut ? <AddShoppingCart /> : <RemoveShoppingCart />}
    />
  );
};
SoldOutToggleButton.propTypes = {
  record: PropTypes.object,
};

const VisibilityToggleButton = ({ record }) => {
  const [toggleVisibility, { loading, error }] = useMutation({
    type: 'update',
    resource: 'events',
    payload: { id: record.id, data: { visible: !record.visible } },
  });
  return (
    <Button
      label={record.visible ? 'Nascondi' : 'Rendi visibile'}
      onClick={toggleVisibility}
      disabled={loading || error}
      startIcon={record.visible ? <VisibilityOff /> : <Visibility />}
    />
  );
};
VisibilityToggleButton.propTypes = {
  record: PropTypes.object,
};

const EventFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="Name" alwaysOn />
    <TextInput source="place" label="Place" alwaysOn />
    <DateInput label="Active from" source="date[after]" showTime={false} alwaysOn />
    <DateInput label="Active to" source="date[before]" showTime={false} alwaysOn />
    <NullableBooleanInput alwaysOn source="visible" label="Enable" />
    <NullableBooleanInput alwaysOn source="soldOut" label="Sold out" />
    <ReferenceInput label="Torneo" source="tournament.id" reference="tournaments" alwaysOn>
      <SelectInput optionText={(record) => tournamentOptionText(record)} />
    </ReferenceInput>
    <SelectInput source="type" choices={[{ id: 'YourChance', name: 'Your Chance' }]} />
  </Filter>
);

const EventList = (props) => (
  <ListGuesser
    {...props}
    title="List of Event"
    perPage={25}
    filters={<EventFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <ReferenceField
      label="Tournament"
      source="tournament"
      reference="tournaments"
      link="show"
      sortable={false}
    >
      <FunctionField render={(record) => tournamentOptionText(record)} />
    </ReferenceField>
    <TextField source="name" label="Name" />
    <TextField source="place" label="Place" />
    <DateField source="date" />
    <BooleanField source="soldOut" sortable={false} />
    <TextField source="type" label="Type" />
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
    <SoldOutToggleButton />
    <VisibilityToggleButton />
  </ListGuesser>
);

export default EventList;
