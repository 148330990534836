import React from 'react';
import PropTypes from 'prop-types';
import { useQueryWithStore, Loading, Error } from 'react-admin';
import AsideMedia from './AsideMedia';

const AsideVideo = (props) => {
  const { videoId } = props;
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'video',
    payload: { id: videoId },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return <AsideMedia {...props} mediaIds={[data.media]} videoRecord={data} />;
};
AsideVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
};

export default AsideVideo;
