import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import {
  Filter,
  TextField,
  ReferenceField,
  ReferenceInput,
  ChipField,
  SelectInput,
  FunctionField,
} from 'react-admin';
import { tournamentOptionText } from '../utils';

const WinnerFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Torneo" source="tournament.id" reference="tournaments" alwaysOn>
      <SelectInput optionText={(record) => tournamentOptionText(record)} />
    </ReferenceInput>
  </Filter>
);

const WinnerList = (props) => (
  <ListGuesser
    {...props}
    title="List of Winner"
    perPage={25}
    filters={<WinnerFilter />}
    sort={{ field: 'startDate', order: 'ASC' }}
  >
    <ReferenceField label="Owner" source="owner" reference="users" link="show">
      <FunctionField render={(record) => `${record.surname} ${record.name}`} />
    </ReferenceField>
    <ReferenceField label="Torneo" source="tournament" reference="tournaments">
      <FunctionField
        render={(record) => (
          <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
        )}
      />
    </ReferenceField>
    <TextField source="place" label="Posizione" />
  </ListGuesser>
);

export default WinnerList;
