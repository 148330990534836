import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';
import {
  ROLE_ADMIN,
  ROLE_ADVISOR,
  ROLE_EDITOR,
  ROLE_PLAYER,
  ROLE_PLAYER_PREMIUM,
  ROLE_SCOUT,
} from '../utils';

const useStyles = makeStyles((theme) => ({
  rolesList: {
    margin: 0,
    padding: 0,
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  unknown: {},
  user: {
    backgroundColor: '#bcbcff',
  },
  admin: {
    backgroundColor: '#ffbcbc',
  },
  player: {
    backgroundColor: '#bcffbc',
  },
}));

const RolesField = ({ record = {}, source }) => {
  const classes = useStyles();

  if (!record[source]) {
    return <span>No role</span>;
  }

  if (record[source].includes(ROLE_PLAYER_PREMIUM)) {
    return (
      <Chip className={clsx(classes.chip, classes.player)} label={'PLAYER PREMIUM'} size="small" />
    );
  } else if (record[source].includes(ROLE_PLAYER)) {
    return <Chip className={clsx(classes.chip, classes.player)} label={'PLAYER'} size="small" />;
  } else if (record[source].includes(ROLE_ADMIN)) {
    return <Chip className={clsx(classes.chip, classes.admin)} label={'ADMIN'} size="small" />;
  } else if (record[source].includes(ROLE_SCOUT)) {
    return <Chip className={clsx(classes.chip, classes.admin)} label={'SCOUT'} size="small" />;
  } else if (record[source].includes(ROLE_EDITOR)) {
    return <Chip className={clsx(classes.chip, classes.admin)} label={'EDITOR'} size="small" />;
  } else if (record[source].includes(ROLE_ADVISOR)) {
    return <Chip className={clsx(classes.chip, classes.admin)} label={'ADVISOR'} size="small" />;
  } else {
    return <Chip className={clsx(classes.chip, classes.user)} label={'USER'} size="small" />;
  }
};

RolesField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

RolesField.defaultProps = {
  addLabel: true, // to enable it in the "show" view
};

export default RolesField;
