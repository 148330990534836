import CardGiftcard from '@material-ui/icons/CardGiftcard';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdmin } from '../utils';
import UserSponsorCodeCreate from './UserSponsorCodeCreate';
import UserSponsorCodeList from './UserSponsorCodeList';
import UserSponsorCodeShow from './UserSponsorCodeShow';

const UserSponsorCodeResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={UserSponsorCodeList}
      show={UserSponsorCodeShow}
      create={UserSponsorCodeCreate}
      icon={CardGiftcard}
    />
  );
};
UserSponsorCodeResource.defaultProps = {
  name: 'user_sponsor_codes',
};

export default UserSponsorCodeResource;
