import React from 'react';
import {
  BooleanField,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ChipField,
  FunctionField,
} from 'react-admin';
import { tournamentOptionText } from '../utils';

const ProductShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" label="Title" />
      <TextField source="type" label="Type" />
      <TextField source="price" label="Price" />
      <BooleanField source="main" sortable={false} />
      <BooleanField source="enable" sortable={false} />
      <ReferenceField label="Torneo" source="tournament" reference="tournaments" sortable={false}>
        <FunctionField
          render={(record) => (
            <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
          )}
        />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default ProductShow;
