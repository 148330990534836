import PropTypes from 'prop-types';
import React from 'react';
import {
  BooleanInput,
  DateInput,
  Edit,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ReferenceInput,
} from 'react-admin';
import { tournamentOptionText } from '../utils';

import { validateRequired } from './validators';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const EventEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceInput label="Tournament" source="tournament" reference="tournaments">
          <SelectInput optionText={(record) => tournamentOptionText(record)} />
        </ReferenceInput>
        <TextInput source="name" label="Name" validate={validateRequired} />
        <TextInput source="place" label="Place" validate={validateRequired} />
        <DateInput source="date" label="Date" validate={validateRequired} />
        <BooleanInput label="Sold out" source="soldOut" />
        <BooleanInput label="Enable" source="visible" />
        <SelectInput source="type" choices={[{ id: 'YourChance', name: 'Your Chance' }]} />
      </SimpleForm>
    </Edit>
  );
};

export default EventEdit;
