import React from 'react';
import {
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  FunctionField,
  useQueryWithStore,
  Loading,
  Error,
} from 'react-admin';
import PropTypes from 'prop-types';
import AddPassButton from '../AddPassButton';
import { resourceId } from '../utils';
import { Chip } from '@material-ui/core';

const AddPassAction = ({ record }) => {
  const filter =
    record && record.owner
      ? { 'profile.owner.id': resourceId(record.owner), visible: true }
      : { visible: true };

  const { loaded, error, data } = useQueryWithStore({
    type: 'getManyReference',
    resource: 'profile_tournaments',
    payload: {
      pagination: { page: 1 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter,
    },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  if (!data || !data[0] || !data[0].tournament) {
    return null;
  }

  return (
    <AddPassButton
      selectedIds={[record.id]}
      resource="tickets"
      source="owner"
      tournament={data[0].tournament}
      yourChance={true}
      disabled={!record || !record.owner || record.state !== 'completed'}
    />
  );
};
AddPassAction.propTypes = {
  record: PropTypes.object,
};

const TicketShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="User" source="owner" reference="users" link="show" sortable={false}>
        <FunctionField render={(record) => (record ? `${record.surname} ${record.name}` : '')} />
      </ReferenceField>
      <AddPassAction />
      <ReferenceField
        label="Partnership"
        source="partnership"
        reference="partnerships"
        link="show"
        sortable={false}
      >
        <FunctionField render={(record) => (record ? <Chip label={record.name} /> : '')} />
      </ReferenceField>
      <ReferenceField
        label="Seller Code"
        source="seller"
        reference="sellers"
        link="show"
        sortable={false}
      >
        <FunctionField render={(record) => (record ? `${record.code}` : '')} />
      </ReferenceField>
      <ReferenceField
        label="Promo Code"
        source="promoCode"
        reference="promo_codes"
        link="show"
        sortable={false}
      >
        <FunctionField render={(record) => (record ? `${record.code}` : '')} />
      </ReferenceField>
      <TextField source="state" label="State" />
      <TextField source="price" label="Price" />
      <ReferenceField
        label="Product"
        source="product"
        reference="products"
        link="show"
        sortable={false}
      >
        <FunctionField render={(record) => `${record.title}`} />
      </ReferenceField>
      <TextField source="platform" label="Platform" />
      <TextField source="preOrderCode" label="Pre-Order Code" />
      <TextField source="codeChallenge" label="Codice esterno" />
      <TextField source="placePrefer" label="Place prefer" />
      <DateField source="datePrefer" label="Date prefer" />
      <ReferenceField
        label="Pre-Order Buyer"
        source="preOrderBuyer"
        reference="pre_order_buyers"
        link="show"
        sortable={false}
      >
        <FunctionField
          render={(record) =>
            record ? (record.phone ? `${record.phone}` : `${record.email}`) : ''
          }
        />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export default TicketShow;
