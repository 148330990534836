import React from 'react';
import {
  DateTimeInput,
  TextInput,
  SelectInput,
  NumberInput,
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  useDataProvider,
  useNotify,
  ReferenceInput,
  SaveButton,
  Toolbar,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import AsideView from '../asideView';
import {
  validateTitle,
  validateTournament,
  validateDescription,
  validateMaxParticipants,
  validateVideoLength,
  validateType,
  validateRole,
  validateAvailableAt,
  validateStartDate,
  validateEndDate,
} from './validators';
import mediaCreator from '../mediaCreator';
import PropTypes from 'prop-types';
import { tournamentOptionText } from '../utils';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const ChallengesEdit = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  return (
    <Edit
      aside={<AsideView />}
      {...props}
      transform={async (data) => {
        const createCover = mediaCreator({
          dataProvider,
          notify,
          mediaFieldLabel: 'cover',
          fileLabel: 'file2',
        });
        const createExample = mediaCreator({ dataProvider, notify, mediaFieldLabel: 'example' });
        try {
          const withCover = await createCover(data);
          const withExample = await createExample(withCover);
          return withExample;
        } catch (error) {
          notify(`Errore inatteso: ${error.message}`, 'warning');
        }
        return data;
      }}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="title" validate={validateTitle} />
        <ReferenceInput
          label="Torneo"
          source="tournament"
          reference="tournaments"
          validate={validateTournament}
        >
          <SelectInput optionText={(record) => tournamentOptionText(record)} />
        </ReferenceInput>
        <RichTextInput
          validate={validateDescription}
          source="description"
          toolbar={[
            [{ font: [] }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ color: [] }, { background: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image'],
          ]}
        />
        <DateTimeInput source="availableAt" validate={validateAvailableAt} />
        <DateTimeInput source="startDate" validate={validateStartDate} />
        <DateTimeInput source="endDate" validate={validateEndDate} />
        <NumberInput source="maxParticipants" min={0} validate={validateMaxParticipants} />
        <NumberInput
          source="videoLength"
          label="lunghezza massima dei video in secondi"
          min={0}
          validate={validateVideoLength}
        />
        <SelectInput
          validate={validateType}
          source="type"
          label="Tipologia di sfida"
          choices={[
            { id: 'QLT', name: 'Qualità' },
            { id: 'QNT', name: 'Quantità' },
          ]}
        />
        <SelectInput
          validate={validateRole}
          source="role"
          label="Ruolo del giocatore"
          choices={[
            { id: 'GK', name: 'Portiere' },
            { id: 'OP', name: 'Giocatore di movimento' },
          ]}
        />
        <FileInput
          source="file2"
          label="Immagine cover"
          placeholder={
            <p>
              Se vuoi sostituire l&apos;immagine cover, trascina qui la nuova immagine, altrimenti
              verrà mantenuta l&apos;immagine corrente
            </p>
          }
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          source="file"
          label="Video di esempio"
          placeholder={
            <p>
              Se vuoi sostituire il video di esempio, trascina qui il nuovo video, altrimenti verrà
              mantenuto il video corrente
            </p>
          }
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export default ChallengesEdit;
