import React, { useState } from 'react';
import { FunctionField, TextInput, TextField } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import OpenIcon from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import InfoBox from '../InfoBox';
import PropTypes from 'prop-types';

export const FeedSettingsList = {
    cached_feed_size: 'Permette di stabilire la lunghezza dei feed pregenerati (qta di video)',
};

/**
 * @param {string|null|undefined} value
 * @returns {boolean}
 */
export function validateSettingsValue(value) {
    return !isNaN(parseInt(value)) && parseInt(value) > 0
        ? undefined
        : "Campo non valido, il valore dev'essere un numero intero positivo";
}

export const FeedSettingsUI = ({ input = false, page, ...props }) => {
    const [isOpen, setOpen] = useState(false);

    if (page === 'list') {
        return (
            <InfoBox showIcon={false} showBackground={true} level="warning">
                Configurazione per la gestione delle impostazioni di generazione feed.
            </InfoBox>
        );
    }

    const toggle = () => {
        if (isOpen) {
            setOpen(false);
        }
        if (!isOpen) {
            setOpen(true);
        }
    };

    return (
        <FunctionField
            {...props}
            label={false}
            source="value"
            render={(record) => {
                if (!record) {
                    return null;
                }
                return (
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <br />
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{ cursor: 'pointer' }}
                            onClick={toggle}
                        >
                            {isOpen && <CloseIcon />}
                            {!isOpen && <OpenIcon />}
                            <span style={{ fontStyle: '18px' }}>
                            <strong>Impostazioni</strong>
                        </span>
                    </Box>
                        <Box style={{paddingLeft: '32px'}}>
                            <InfoBox>
                                <strong>
                                    Questa configurazione permette di modificare alcuni parametri tecnici del feed.
                                    <br />
                                    Lasciare la gestione in mano ad una figura tecnica, non modificare!
                                </strong>
                            </InfoBox>
                        </Box>
                        <br />
                        <Box display="flex" flexDirection="column" style={{ gap: '16px' }}>
                            {Object.keys(FeedSettingsList).map((setting) => {
                                if (!isOpen) {
                                    return null;
                                }
                                return (
                                    <Box display="flex" justifyContent="flex-start" alignItems="center" style={{gap:'16px', paddingLeft:'32px'}} key={setting}>
                                        <InfoBox showIcon={false} level="info" showBackground={true} style={{width:'fit-content'}}>{setting}:</InfoBox>
                                        <Box display="flex" flexDirection="column">
                                            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{FeedSettingsList[setting]}</Typography>
                                            {input && (
                                                <TextInput
                                                    style={{ width: '100%' }}
                                                    source={`value.settings.${setting}`}
                                                    label={setting}
                                                    record={record}
                                                    validate={[validateSettingsValue]}
                                                />
                                            )}
                                            {!input && (
                                                <TextField
                                                    style={{ width: '100%' }}
                                                    source={`value.settings.${setting}`}
                                                    label={setting}
                                                    record={record}
                                                    emptyText={
                                                        "Non impostato, verrà usato il valore di default."
                                                    }
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                        <br />
                    </Box>
                );
            }}
        />
    );
};
FeedSettingsUI.propTypes = {
    input: PropTypes.bool,
    page: PropTypes.oneOf([undefined, 'list', 'show']),
    props: PropTypes.object,
};
