import EventIcon from '@material-ui/icons/EmojiEvents';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdvisor, isScout } from '../utils';
import ProfileTournamentShow from './ProfileTournamentShow';
import ProfileTournamentList from './ProfileTournamentList';

const ProfileTournamentResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdvisor(permissions)) {
    return <Resource {...props} />;
  }

  return (
    <Resource
      {...props}
      list={ProfileTournamentList}
      show={ProfileTournamentShow}
      icon={EventIcon}
    />
  );
};
ProfileTournamentResource.defaultProps = {
  name: 'profile_tournaments',
};

export default ProfileTournamentResource;
