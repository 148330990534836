import { makeStyles } from '@material-ui/core';

const useAsideStyles = makeStyles((theme) => ({
  container: {
    width: '40%',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },
  containerNoPadding: {
    width: '50%',
    marginLeft: theme.spacing(2),
  },
  header: {
    textAlign: 'center',
  },
  videoPresentationsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  videoPresentation: {
    flex: '1 0 40%',
    marginLeft: '10px',
    marginBottom: '10px',
    display: 'flex',
    flexFlow: 'column wrap',
  },
  removeButton: {
    marginTop: '3px',
    width: '100%',
  },
  removeButtonLoading: {
    width: '100%',
  },
  avatar: {
    maxWidth: '200px',
    display: 'inline-block',
  },
}));

export default useAsideStyles;
