import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useProductTypeStyles = makeStyles({
  premium: {
    // backgroundColor: '#91d8ff',
    backgroundColor: '#ffe600',
  },
  your_chance: {
    // backgroundColor: '#a3fba9',
    backgroundColor: '#1d1d1b',
    color: '#fff',
  },
  none: {
    backgroundColor: 'transparent',
  },
});

export const ProductTypes = {
  premium: 'Future Player',
  your_chance: 'Your Chance',
};

export const ProductTypeChip = ({ type, record, source }) => {
  const classes = useProductTypeStyles();
  let _t = type;

  if (!type && record && source) _t = record[source];

  return <Chip className={classes[_t ?? 'none']} label={ProductTypes[_t]} />;
};
ProductTypeChip.propTypes = {
  type: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
};
