import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, Button, Loading } from 'react-admin';
import { VisibilityOff, Visibility } from '@material-ui/icons';

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_EDITOR = 'ROLE_EDITOR';
export const ROLE_SCOUT = 'ROLE_SCOUT';
export const ROLE_SCOUT_PLAY2GIVE = 'ROLE_SCOUT_PLAY2GIVE';
export const ROLE_ADVISOR = 'ROLE_ADVISOR';
export const ROLE_PLAYER = 'ROLE_PLAYER';
export const ROLE_PLAYER_PREMIUM = 'ROLE_PLAYER_PREMIUM';
/**
 * @param {{}} permissions
 * @returns {boolean}
 */
export function isAdmin(permissions) {
  return hasRole(permissions, ROLE_ADMIN);
}

/**
 * @param permissions
 * @returns {boolean}
 */
export function isEditor(permissions) {
  return hasRole(permissions, ROLE_EDITOR);
}
/**
 * @param {{}} permissions
 * @returns {boolean}
 */
export function isScout(permissions) {
  return hasRole(permissions, ROLE_SCOUT);
}
/**
 * @param {{}} permissions
 * @returns {boolean}
 */
export function isScoutPlay2Give(permissions) {
  return hasRole(permissions, ROLE_SCOUT_PLAY2GIVE);
}
/**
 * @param {{}} permissions
 * @returns {boolean}
 */
export function isAdvisor(permissions) {
  return hasRole(permissions, ROLE_ADVISOR);
}
/**
 * @param {{}} permissions
 * @returns {boolean}
 */
export function isPlayer(permissions) {
  return hasRole(permissions, ROLE_PLAYER);
}
/**
 * @param {{}} permissions
 * @param {string} role
 * @returns {boolean}
 */
export function hasRole(permissions, role) {
  return permissions ? permissions.roles.includes(role) : false;
}

export function resourceId(resource) {
  const r = resource.split('/');
  return r[r.length - 1];
}

export function uniqueArray(array) {
  return Array.from(new Set(array));
}

export function tournamentOptionText(record) {
  const startDate = new Date(record.startDate);
  const endDate = new Date(record.endDate);
  const year =
    startDate.getFullYear() !== endDate.getFullYear()
      ? startDate.getFullYear().toString().slice(2) +
        '/' +
        endDate.getFullYear().toString().slice(2)
      : startDate.getFullYear();

  const premium = record.isPremium ? '(Premium)' : '';
  return `${record.title} ${year} - ${record.description} - ${
    record.gender
  } ${premium} ${getTournamentStatus(record)}`;
}

export function getTournamentStatus(record) {
  const now = new Date();
  const startDate = new Date(record.startDate);
  const endDate = new Date(record.endDate);
  return startDate <= now && now <= endDate
    ? '(Attivo)'
    : now <= startDate
    ? '(Partirà a breve)'
    : '(Non attivo)';
}

export const API_BASE_URL = process.env.REACT_APP_API_ENTRYPOINT.replace('/api/v1', '');

export const HTTP_METHOD_GET = 'GET';
export const HTTP_METHOD_POST = 'POST';
export const HTTP_METHOD_PUT = 'PUT';
export const HTTP_METHOD_DELETE = 'DELETE';

/**
 * Prepares & sends a request to the given api path
 *
 * @param {string} path
 * @param {"POST"|"GET"|"PUT"|"DELETE"} method
 * @param {{}|FormData} payload
 * @param headers Request payload, here you may override existing headers,
 * **watch out**: do not introduce unauthorized headers as the OPTION call is going to be rejected by the API for CORS policies
 * @returns {Promise<Response>}
 */
export async function apiRequest({
  path,
  method = HTTP_METHOD_GET,
  payload = {},
  headers = {},
} = {}) {
  const slash = path.match(/^\//) ? '' : '/';
  let url = new URL(`${process.env.REACT_APP_API_ENTRYPOINT}${slash}${path}`);
  if (method === HTTP_METHOD_GET) {
    Object.entries(payload).forEach(([key, value]) => url.searchParams.set(key, value));
    payload = undefined;
  }
  const request = new Request(url, {
    method,
    body: !payload || payload instanceof FormData ? payload : JSON.stringify(payload),
    headers: new Headers({
      'OOU-Client-Platform': 'web',
      'Content-Type': 'application/ld+json',
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      ...(headers ?? {}),
    }),
  });

  return fetch(request);
}

/**
 * Creates a profile interaction after 30seconds spent on the applied component
 *
 * The event fires only if the current (logged) user is a scout
 *
 * @param permissions Logged user's permissions (i.e. the scout)
 * @param id id of the user being viewed
 * @returns {(function(): void)|*} the clear function, pass this to React's useEffect method or run it yourself
 */
export function scoutViewInteraction(permissions, id) {
  /**
   * notify the selected user that a scout has looked up his account
   * @returns {Promise<void>}
   */
  const didViewUser = async () => {
    if (!isScout(permissions)) return; // scout-only feature

    try {
      // --------- get user data using query param id -> unable to get record from resource component
      // --------- ATTOW: useRecordContext is not an option
      const response = await (
        await apiRequest({
          path: `/users/${resourceId(id)}`,
          method: HTTP_METHOD_GET,
        })
      ).json();
      // --------- user data fetched
      // --------- send user interaction
      await apiRequest({
        path: '/profile_interactions',
        method: HTTP_METHOD_POST,
        payload: {
          profile: response.profile['@id'],
          type: 'visit',
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      // --------- remove timeout id
      delete document.usersShowDidViewUserTimeout;
    }
  };

  // --------- send user interaction after timeout
  const onPageLoad = () => setTimeout(didViewUser, 30 * 1000);

  if (document.readyState === 'complete') {
    // --------- if page did load start operation
    document.usersShowDidViewUserTimeout = onPageLoad();
  } else {
    // --------- if page did not: await load using listener
    window.addEventListener('load', () => (document.usersShowDidViewUserTimeout = onPageLoad()));
  }

  // --------- cleanup function run by react when component unmounts
  return () => {
    // --------- stop timeout to prevent notification from being sent
    if (typeof document.usersShowDidViewUserTimeout == 'number')
      clearTimeout(document.usersShowDidViewUserTimeout);
    // --------- remove the event listener
    window.removeEventListener('load', onPageLoad);
  };
}

export const OperatingSystems = {
  android: 'android',
  ios: 'ios',
};

export const AvailablePlatforms = {
  ...OperatingSystems,
  web: 'web',
  mobile: 'mobile',
};

export function getAvailablePlatforms() {
  return Object.entries(AvailablePlatforms).reduce((prev, curr) => {
    prev.push({
      id: curr[0],
      name: curr[1],
    });
    return prev;
  }, []);
}

export const VisibilityToggleButton = ({ record, resource }) => {
  const [toggleVisibility, { loading, error }] = useMutation({
    type: 'update',
    resource,
    payload: record ? { id: record.id, data: { visible: !record.visible } } : undefined,
  });

  if (!record) return <Loading />;

  return (
    <Button
      label={record.visible ? 'Nascondi' : 'Rendi visibile'}
      onClick={toggleVisibility}
      disabled={loading || error}
      startIcon={record.visible ? <VisibilityOff /> : <Visibility />}
    />
  );
};
VisibilityToggleButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
};

/**
 * Splits camel-case string into array
 * @param string
 * @return {string[]}
 */
export function splitCamelCaseString(string) {
  return string
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(' ')
    .map((s) => s.toLowerCase());
}

export const SERVER_EVENTS_MAP = {
  '\\App\\Event\\SignUp\\OnUserCreateSignUpEvent': 'Registration',
  '\\App\\Event\\Onboarding\\UserToPlayerUpgradeEvent': 'Profile upgrade: Free Player',
  '\\App\\Event\\Ticket\\TicketCompleteEvent': 'Purchase complete',
  '\\App\\Event\\Ticket\\YourChanceTicketCompleteEvent': 'Your Chance: Purchase complete',
  '\\App\\Event\\Ticket\\FuturePlayerTicketCompleteEvent': 'Future Player: Purchase complete',
};

export function getServerEvents() {
  return Object.entries(SERVER_EVENTS_MAP).reduce((prev, current) => {
    prev.push({
      id: current[0],
      name: current[1],
    });
    return prev;
  }, []);
}

export const CLOUD_FRONT_PATH = 'https://d1au7wetc4zo8y.cloudfront.net';

/**
 * @param {string} path
 * @returns {string}
 */
export function addSlash(path) {
  return path.match(/^\//) ? path : `/${path}`;
}
export function getCloudFrontMedia(path) {
  if (!path) return undefined;
  if (/^https?:\/\//.exec(path)) {
    return path;
  }

  return `${CLOUD_FRONT_PATH}${addSlash(path)}`;
}

export function getRawUploadUrl(path) {
  if (!path) return undefined;
  if (/^https?:\/\//.exec(path)) {
    return path;
  }

  return getCloudFrontMedia(`raw-uploads${addSlash(path)}`);
}

/**
 * Retrieves the value of a nested key (dot-separated)
 *
 * @example deepGet('my.object', { my: { object: true } }) -> true
 *
 * @param key dot-separated path
 * @param object the record
 * @returns {any}
 */
export function deepGet(key, object) {
  const segments = typeof key === 'string' ? key.split('.') : key;
  const segment = segments[0];
  if (!object || typeof object !== 'object' || segments.length === 0 || segment?.length === 0) {
    return object;
  }
  const value = object[segment];
  segments.reverse().pop();
  return deepGet(segments.reverse(), value);
}
