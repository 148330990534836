import React from 'react';
import {
  FunctionField,
  ChipField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

const PassesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="User" source="target" reference="users" link="show" sortable={false}>
        <FunctionField render={(record) => `${record.surname} ${record.name}`} />
      </ReferenceField>
      <ReferenceField label="Email" source="target" reference="users" link={false} sortable={false}>
        <ChipField source="email" />
      </ReferenceField>
      <ReferenceField label="Phone" source="target" reference="users" link={false} sortable={false}>
        <ChipField source="phone" />
      </ReferenceField>
      <ReferenceField label="Scout" source="owner" reference="users" link="show" sortable={false}>
        <FunctionField render={(record) => `${record.surname} ${record.name}`} />
      </ReferenceField>
      <TextField source="description" />
      <TextField source="type" />
      <ReferenceField label="Fase" source="stage" reference="stages">
        <TextField source="title" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default PassesShow;
