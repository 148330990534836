import React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { TextField, BooleanField } from 'react-admin';
import {ConfigShowForm} from "./utils";

const configList = (props) => (
    <ListGuesser {...props} title="List of Configurations" perPage={25} bulkActionButtons={false}>
      <TextField source="key" label="Key" />
      <ConfigShowForm page="list" />
      <BooleanField source="private" />
    </ListGuesser>
);

export default configList;
