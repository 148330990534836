import React from 'react';
import { ReferenceField, ChipField, FunctionField } from 'react-admin';
import PropTypes from 'prop-types';

const ReportTargetReferenceField = (props) => {
  if (props.record) {
    switch (props.record.targetClass) {
      case 'App\\Entity\\Video':
        return (
          <ReferenceField {...props} reference="videos">
            <ChipField source="id" />
          </ReferenceField>
        );
      case 'App\\Entity\\User':
        return (
          <ReferenceField {...props} reference="users">
            <FunctionField render={(record) => `${record.surname} ${record.name}`} />
          </ReferenceField>
        );
      case 'App\\Entity\\Comment':
        return <span>commento a video</span>;
      default:
    }
  }
  return <span>target sconosciuto</span>;
};
ReportTargetReferenceField.propTypes = {
  record: PropTypes.object,
};
ReportTargetReferenceField.defaultProps = {
  addLabel: true, // to enable it in the "show" view
};

export default ReportTargetReferenceField;
