import React from 'react';
import {
  BooleanInput,
  Create,
  DateTimeInput,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  NumberInput,
} from 'react-admin';
import {
  validateTitle,
  validateTournament,
  validateStartDate,
  validateEndDate,
  validateRequired,
} from './validators';
import RichTextInput from 'ra-input-rich-text';
import { tournamentOptionText } from '../utils';
import FormGrid, { FormGridItem } from '../FormGrid';
import { StageRegulations } from './stage.utils';

const StageCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <FormGrid>
          <FormGridItem xs={6}>
            <TextInput source="title" validate={validateTitle} />
            <ReferenceInput
              label="Torneo"
              source="tournament"
              reference="tournaments"
              validate={validateTournament}
            >
              <SelectInput optionText={(record) => tournamentOptionText(record)} />
            </ReferenceInput>
            <RichTextInput
              source="description"
              toolbar={[
                [{ font: [] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ color: [] }, { background: [] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ align: [] }],
                ['clean'],
                ['link', 'image'],
              ]}
            />
            <NumberInput
              source="sequence"
              label="Ordinamento"
              min={0}
              validate={validateRequired}
            />
            <DateTimeInput source="startDate" validate={validateStartDate} />
            <DateTimeInput source="endDate" validate={validateEndDate} />
            <BooleanInput label="Your chance" source="yourChance" />
          </FormGridItem>
          <FormGridItem xs={6}>
            <RichTextInput label="Informazioni fase" source="extra.info"></RichTextInput>
            <RichTextInput
              label="Descrizione: la fase sta per cominciare"
              source="extra.headerStarting"
            ></RichTextInput>
            <RichTextInput
              label="Descrizione: inizio fase"
              source="extra.headerStarted"
            ></RichTextInput>
            <RichTextInput
              label="Descrizione: fine fase"
              source="extra.headerEnded"
            ></RichTextInput>
          </FormGridItem>
          <FormGridItem sx={12}>
            <StageRegulations />
          </FormGridItem>
        </FormGrid>
      </SimpleForm>
    </Create>
  );
};

export default StageCreate;
