import React from 'react';
import {
  ChipField,
  BooleanField,
  DateField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import { tournamentOptionText } from '../utils';

const EventShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField
        label="Tournament"
        source="tournament"
        reference="tournaments"
        link="show"
        sortable={false}
      >
        <FunctionField render={(record) => tournamentOptionText(record)} />
      </ReferenceField>
      <TextField source="name" label="Name" />
      <TextField source="place" label="Place" />
      <DateField source="date" />
      <BooleanField source="soldOut" sortable={false} />
      <BooleanField source="visible" sortable={false} />
      <TextField source="type" label="Type" />
      <ReferenceManyField reference="promo_codes" target="events.id" label="PromoCodes">
        <SingleFieldList>
          <ChipField source="code" />
        </SingleFieldList>
      </ReferenceManyField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export default EventShow;
