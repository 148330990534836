import * as React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';
import Filter9PlusIcon from '@material-ui/icons/Filter9Plus';

const ChallengeTypeField = ({ record = {}, source }) =>
  record[source] === 'QLT' ? (
    <Tooltip title="Qualità">
      <StarIcon />
    </Tooltip>
  ) : (
    <Tooltip title="Quantità">
      <Filter9PlusIcon />
    </Tooltip>
  );

ChallengeTypeField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

ChallengeTypeField.defaultProps = {
  addLabel: true, // to enable it in the "show" view
};

export default ChallengeTypeField;
