import React from 'react';
import { Button } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Form } from 'react-final-form';
import { PropTypes } from 'prop-types';

const DialogForm = ({
  open,
  loading,
  onSubmit,
  onCancel,
  title,
  cancelLabel,
  submitLabel,
  children,
}) => {
  return (
    <Dialog open={open}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            <DialogActions>
              {cancelLabel !== false && (
                <Button label={cancelLabel || 'Cancel'} onClick={onCancel} disabled={loading} />
              )}
              <Button label={submitLabel || 'Validate'} type="submit" disabled={loading} />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

DialogForm.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  submitLabel: PropTypes.string,
  children: PropTypes.element,
};

export default DialogForm;
