import React from 'react';
import {
  Show,
  SimpleShowLayout,
  BooleanField,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  FunctionField,
  ReferenceField,
} from 'react-admin';
import { isAdmin, isEditor, tournamentOptionText } from '../utils';
import PropTypes from 'prop-types';
import { PartnerReferenceField } from '../partner/utils';
import { UserClusterField } from '../UserClusters';
import { GenderField } from '../fields';

const hasPermissions = (permissions) => isEditor(permissions) || isAdmin(permissions);

const PartnershipShow = ({ permissions, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="signupFlow" />
      <PartnerReferenceField source="partner" label="Partner" />
      <ReferenceField sortable={false} source="tournament" reference="tournaments">
        <FunctionField render={(record) => tournamentOptionText(record)} />
      </ReferenceField>
      <ArrayField source="targets">
        <SingleFieldList
          style={{
            display: 'flex',
            flex: '1 0 30%',
            gap: '5px 10px',
            padding: '5px',
            maxWidth: '500px',
          }}
        >
          <FunctionField render={(record) => <UserClusterField value={record} />} />
        </SingleFieldList>
      </ArrayField>
      <GenderField source="gender" />
      <DateField source="startDate" />
      <DateField source="endDate" />
      {hasPermissions(permissions) && <BooleanField source="visible" addLabel={true} />}
      <DateField source="createdAt" />
      {hasPermissions(permissions) && <DateField source="updatedAt" />}
    </SimpleShowLayout>
  </Show>
);
PartnershipShow.propTypes = {
  permissions: PropTypes.object,
  props: PropTypes.any,
};

export default PartnershipShow;
