import { required, minValue, maxValue } from 'react-admin';

export const validateTitle = [required()];
export const validateTournament = [required()];
export const validateDescription = [required()];
export const validateMaxParticipants = [required(), minValue(0)];
export const validateVideoLength = [required(), minValue(10), maxValue(600)];
export const validateType = [required()];
export const validateRole = [required()];
export const validateFile = [required()];
export const validateAvailableAt = [required()];
export const validateStartDate = [required()];
export const validateEndDate = [required()];
