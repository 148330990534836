import Icon from '@material-ui/icons/Dashboard';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import { isAdmin, isEditor } from '../utils';
import PartnershipList from './PartnershipList';
import PartnershipCreate from './PartnershipCreate';
import PartnershipShow from './PartnershipShow';
import PartnershipEdit from './PartnershipEdit';

const PartnershipResource = (props) => {
  const { permissions } = usePermissions();

  if (isAdmin(permissions) || isEditor(permissions)) {
    return (
      <Resource
        {...props}
        list={PartnershipList}
        create={PartnershipCreate}
        show={PartnershipShow}
        edit={PartnershipEdit}
        icon={Icon}
      />
    );
  }

  return <Resource {...props} />;
};
PartnershipResource.defaultProps = {
  name: 'partnerships',
};

export default PartnershipResource;
