import LocalActivity from '@material-ui/icons/LocalActivity';
import React from 'react';
import { Resource, usePermissions } from 'react-admin';

import TicketList from './TicketList';
import TicketShow from './TicketShow';
import { isAdmin, isEditor } from '../utils';

const TicketResource = (props) => {
  const { permissions } = usePermissions();

  if (!isAdmin(permissions) && !isEditor(permissions)) {
    return <Resource {...props} />;
  }

  return <Resource {...props} list={TicketList} show={TicketShow} icon={LocalActivity} />;
};
TicketResource.defaultProps = {
  name: 'tickets',
};

export default TicketResource;
