import React from 'react';
import { SelectInput, Filter, ReferenceInput } from 'react-admin';
import { tournamentOptionText } from '../utils';
import { MasterclassMap } from './masterclass.utils';

export const MasterclassFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Torneo" source="tournament.id" reference="tournaments" alwaysOn>
      <SelectInput optionText={(record) => tournamentOptionText(record)} />
    </ReferenceInput>
    <SelectInput
      label="Categoria"
      source="category"
      alwaysOn
      choices={MasterclassMap}
      translateChoice={false}
    />
  </Filter>
);
