import React from 'react';
import {
  TextField,
  EmailField,
  DateField,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  ChipField,
  BooleanField,
  Pagination,
  ShowButton,
  Error,
  Button,
  useMutation,
  Loading,
  useQueryWithStore,
  LinearProgress,
  FunctionField,
  useGetList,
} from 'react-admin';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { GenderField, RolesField } from '../fields';
import {
  isAdmin,
  isScout,
  isEditor,
  resourceId,
  tournamentOptionText,
  scoutViewInteraction,
} from '../utils';
import AsideView from '../asideView';

import { UsersTitle } from './UserTitle';
import AddPassButton from '../AddPassButton';
import RequestAdditionalContentButton from '../RequestAdditionalContentButton';
import TagUserAsPotential from '../TagUserAsPotential';
import { UserAgeField } from './users.util';
import { PartnershipSignupFlowArrayField } from '../partnership/utils';
import UserRoleManagerButton from './UserRoleManagerButton';

const VisibilityToggleButton = ({ record }) => {
  const [toggleVisibility, { loading, error }] = useMutation({
    type: 'update',
    resource: 'users',
    payload: record && { id: record.id, data: { visible: !record.visible } },
  });
  if (!record) return null;
  if (loading) return <LinearProgress />;
  if (error) return <Error />;
  return (
    <Button
      label={record.visible ? 'Nascondi user' : 'Rendi nuovamente visibile'}
      color="primary"
      variant="contained"
      onClick={toggleVisibility}
    />
  );
};
VisibilityToggleButton.propTypes = {
  record: PropTypes.object,
};

const AddPassAction = ({ record }) => {
  const filter =
    record && record.id
      ? { 'profile.owner.id': resourceId(record.id), visible: true }
      : { visible: true };

  const { loaded, error, data } = useQueryWithStore({
    type: 'getManyReference',
    resource: 'profile_tournaments',
    payload: {
      pagination: { page: 1 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter,
    },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  if (!data || !data[0]) return null;

  return (
    <AddPassButton
      selectedIds={[record.id]}
      resource="users"
      tournaments={data.map((item) => item.tournament)}
      disabled={!record.roles || !record.roles.includes('ROLE_PLAYER')}
    />
  );
};
AddPassAction.propTypes = {
  record: PropTypes.object,
};

const RequestAdditionalContentAction = ({ record }) => {
  return (
    <RequestAdditionalContentButton
      disabled={!record.roles || !record.roles.includes('ROLE_PLAYER')}
      user={record}
    />
  );
};
RequestAdditionalContentAction.propTypes = {
  record: PropTypes.object,
};

const UsersShow = ({ permissions, ...props }) => {
  const id = decodeURIComponent(useParams().id);
  const request = useGetList(
    'partnerships',
    {},
    {},
    {
      pagination: false,
      active: true,
    }
  );

  // ------------ Abandon all hope, ye who enter here ------------ //

  React.useEffect(() => scoutViewInteraction(permissions, id), []);

  return (
    <Show title={<UsersTitle />} aside={<AsideView permissions={permissions} />} {...props}>
      <SimpleShowLayout>
        {isAdmin(permissions) && <BooleanField source="visible" />}
        {isAdmin(permissions) && <VisibilityToggleButton />}
        {isAdmin(permissions) && <TextField source="phone" addLabel={true} />}
        {isAdmin(permissions) && <EmailField source="email" addLabel={true} />}
        <RolesField source="roles" addLabel={true} />
        <TextField source="name" addLabel={true} />
        <TextField source="surname" addLabel={true} />
        {!isEditor(permissions) && !isScout(permissions) && (
          <DateField source="birthDate" addLabel={true} />
        )}
        {isScout(permissions) && <UserAgeField source="birthDate" label="Age" addLabel={true} />}
        <GenderField source="gender" addLabel={true} />
        <BooleanField source="test" addLabel={true} />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <TextField source="signupFlow" />
        <Typography className={['MuiFormLabel-root', 'MuiInputLabel-shrink']} variant="span">
          Attributi attivi
        </Typography>
        <PartnershipSignupFlowArrayField
          source="attributes"
          label="Attributi attivi"
          isList={false}
          data={request?.data}
          loading={request?.loading}
          error={request?.error}
        />
        {(isAdmin(permissions) || isEditor(permissions)) && (
          <TextField source="preferredProvince" />
        )}
        {isAdmin(permissions) && <UserRoleManagerButton userId={id} />}
        <AddPassAction />
        {isScout(permissions) && <RequestAdditionalContentAction />}
        {isScout(permissions) && <TagUserAsPotential />}
        <ReferenceManyField
          pagination={<Pagination />}
          label="Pass"
          reference="passes"
          target="target.id"
        >
          <Datagrid>
            <ReferenceField
              label="User"
              source="target"
              reference="users"
              link="show"
              sortable={false}
            >
              <FunctionField render={(record) => `${record.surname} ${record.name}`} />
            </ReferenceField>
            <TextField source="type" />
            <ReferenceField label="Fase" source="stage" reference="stages">
              <TextField source="title" />
            </ReferenceField>
            <ReferenceField label="Tournament" source="stage" reference="stages">
              <ReferenceField
                source="tournament"
                reference="tournaments"
                link="show"
                sortable={false}
              >
                <FunctionField
                  render={(record) => (
                    <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
                  )}
                />
              </ReferenceField>
            </ReferenceField>
            <DateField source="createdAt" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          pagination={<Pagination />}
          label="Uploaded challenge videos"
          reference="videos"
          target="owner.id"
          filter={{ 'exists[challenge]': true }}
        >
          <Datagrid>
            <ReferenceField
              label="Challenge"
              source="challenge"
              reference="challenges"
              link="show"
              sortable={false}
            >
              <ChipField source="title" />
            </ReferenceField>
            <BooleanField source="visible" sortable={false} />
            <DateField source="createdAt" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

UsersShow.propTypes = {
  permissions: PropTypes.object,
  props: PropTypes.any,
};

export default UsersShow;
