import { fetchOptions } from './dataProvider';

const mediaCreator = ({
  dataProvider,
  notify,
  mediaFieldLabel,
  fileLabel = 'file',
  suppressError = true,
}) => async (data) => {
  // no new file case, no transformation is needed
  // this case cannot happen with the creators thanks to validators requiring the file to be present
  if (!data[fileLabel]) return data;

  // do not send the file in the final call
  const { [fileLabel]: file, ...dataExceptFile } = data;

  // manually fetch a multipart
  const formData = new FormData();
  formData.append('file', file.rawFile);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/media`,
      fetchOptions({ body: formData, method: 'POST' })
    );
    const media = await response.json();
    if (!media || !media['@id']) throw new Error("impossibile determinare l'id del Media");
    // load the new Media entity using dataProvider methods to ensure putting it in the cache
    const resource = await dataProvider.getOne('media', { id: media['@id'] });
    dataExceptFile[mediaFieldLabel] = resource.data['@id'];
  } catch (error) {
    if (suppressError) {
      notify(`Errore inatteso: ${error.message}`, 'warning');
    } else {
      throw error;
    }
  }
  return dataExceptFile;
};

export default mediaCreator;
