import React, { useState } from 'react';
import { FunctionField, TextInput, TextField } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import OpenIcon from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import InfoBox from '../InfoBox';
import PropTypes from 'prop-types';

export const FeedKPIList = {
  premium_owner: 'Video di utenti Premium (FP/YC)',
  selected_tournament: 'Video appartenenti al torneo selezionato',
  timeline_latest: 'Video più recenti [< 3 giorni]',
  timeline_week: 'Video più recenti [3 - 10 giorni]',
  timeline_month: 'Video creati questo mese [10 - 31 giorni]',
  timeline_semester: 'Video creati questo semestre [31 - 186 giorni]',
  timeline_year: "Video creati quest'anno [31 - 365 giorni]",
  timeline_old: 'Video creati tanto tempo fa [365 giorni <]',
  followed: 'Video di utenti seguiti',
  most_viewed: 'Video con molte visualizzazioni',
  same_role: 'Video di utenti con stesso ruolo (difensore, portiere, etc..)',
  extra_tournament: 'Video extra torneo (di presentazione e extra mensili)',
  proximity: 'Video geolocalizzati vicino all’utente',
  shared_tournament: 'Video di utenti che partecipano a stessi tornei',
  same_partner: 'Video di utenti con stesso partner d’iscrizione',
  shared_partnerships: 'Video di utenti che condividono delle partnership',
  already_seen: 'Video già visualizzati (peso negativo)',
};

/**
 * @param {string|null|undefined} value
 * @returns {boolean}
 */
export function validateKPIValue(value) {
  if (!value && typeof value !== 'boolean') {
    return true;
  }
  if (typeof value === 'string') {
    if (!value.length) {
      return true; // do not validate empty string
    }
    if (/^v\.[a-z_]+$/.exec(value)) {
      return ['v.user_views', 'v.total_views', 'v.unique_views', 'v.weight'].includes(value);
    }
    try {
      if (!isNaN(parseInt(value))) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  return false;
}

export const FeedKPIConfigUI = ({ input = false, page, ...props }) => {
  const [openedKPI, setOpenedKPI] = useState([]);

  if (page === 'list') {
    return (
        <InfoBox showIcon={false} showBackground={true} level="warning">
          Configurazione per la gestione dei vari KPI usati durante la generazione del feed.
        </InfoBox>
    );
  }

  return (
    <FunctionField
      {...props}
      label={false}
      source="value"
      render={(record) => {
        if (!record) {
          return null;
        }
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <br />
            <InfoBox>
              <strong>
                Questa configurazione permette di modificare le meccaniche alla base del feed
                impostando i pesi delle varie metriche (o KPI)
              </strong>
            </InfoBox>
            <br />
            <Box display="flex" flexDirection="column" style={{ gap: '16px' }}>
              {Object.keys(FeedKPIList).map((kpi) => {
                const isOpen = openedKPI.includes(kpi);
                const toggle = () => {
                  if (isOpen) {
                    setOpenedKPI(openedKPI.filter((k) => k !== kpi));
                  }
                  if (!isOpen) {
                    setOpenedKPI([...openedKPI, kpi]);
                  }
                };
                return (
                  <Box display="flex" flexDirection="column" key={kpi}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ cursor: 'pointer' }}
                      onClick={toggle}
                    >
                      {isOpen && <CloseIcon />}
                      {!isOpen && <OpenIcon />}
                      <span style={{ fontStyle: '18px' }}>
                        <strong>{FeedKPIList[kpi]}</strong>
                      </span>
                    </Box>
                    {isOpen && <br />}
                    {isOpen && input && (
                      <Typography style={{ fontSize: '14px' }}>
                        In questo campo è possibile configurare un numero intero (sia positivo che
                        negativo) o una tra le seguenti query (una query è composta da &quot;v.
                        {'{nome_parametro}'}&quot;):
                        <ul>
                          <li>
                            <strong>v.user_views</strong>: numero di volte che l&apos;utente ha
                            visualizzato il video in questione.
                          </li>
                          <li>
                            <strong>v.total_views</strong>: conteggio delle visualizzazioni totali.
                          </li>
                          <li>
                            <strong>v.unique_views</strong>: numero di utenti che ha visualizzato il
                            video.
                          </li>
                          <li>
                            <strong>v.weight</strong>: rilevanza dell&apos;utente, 1 -&gt;
                            spettatore o 2 -&gt; calciatore.
                          </li>
                        </ul>
                      </Typography>
                    )}
                    {isOpen && input && (
                      <TextInput
                        style={{ width: '100%' }}
                        source={`value.list.${kpi}`}
                        label={kpi}
                        record={record}
                        validate={[
                          (v) =>
                            validateKPIValue(v)
                              ? undefined
                              : "Campo non valido, il valore dev'essere o un numero intero (negativo/positivo) o una query valida.",
                        ]}
                      />
                    )}
                    {isOpen && !input && (
                      <TextField
                        style={{ width: '100%' }}
                        source={`value.list.${kpi}`}
                        label={kpi}
                        record={record}
                        emptyText={
                          "Non impostato, verrà usato il valore di default.\n\nPer annullare l'effetto del KPI impostare a 0 (zero)!"
                        }
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
            <br />
            <br />
            <InfoBox level="warning">
              <span style={{ fontStyle: '18px' }}>
                è molto importante che qualsiasi modifica effettuata sia prima discussa e validata
                <br />
                Le modifiche vengono applicate per tutti gli utenti quasi istantaneamente!
              </span>
            </InfoBox>
          </Box>
        );
      }}
    />
  );
};
FeedKPIConfigUI.propTypes = {
  input: PropTypes.bool,
  page: PropTypes.oneOf([undefined, 'list', 'show']),
  props: PropTypes.object,
};
