import PropTypes from 'prop-types';
import React from 'react';
import {
  AutocompleteInput,
  DateInput,
  Edit,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';

import { validateTarget, validateUntil } from './validators';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const BansEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceInput
          label="User"
          source="target"
          reference="users"
          validate={validateTarget}
          filterToQuery={(searchText) => ({ search: searchText, visible: true })}
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(record) => `${record.surname} ${record.name} ${record.phone || ''}`}
          />
        </ReferenceInput>
        <TextInput multiline source="reason" fullWidth="true" />
        <DateInput source="until" validate={validateUntil} />
      </SimpleForm>
    </Edit>
  );
};

export default BansEdit;
