import React from 'react';
import {
  DateInput,
  Filter,
  SelectInput,
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
} from 'react-admin';
import PropTypes from 'prop-types';
import { isAdmin, isEditor } from '../utils';
import { getUserBenefitTypesMap } from './util';

const canEdit = (permissions) => isAdmin(permissions) || isEditor(permissions);

const UserBenefitFilter = ({ permissions, ...props }) => {
  return (
    <Filter {...props}>
      <ReferenceInput label="Partnership" source="partnership.id" reference="partnerships" alwaysOn>
        <SelectInput optionText={(record) => record.name}></SelectInput>
      </ReferenceInput>
      <TextInput label="Search name" source="name" alwaysOn />
      <SelectInput
        translateChoice={false}
        choices={getUserBenefitTypesMap()}
        source="type"
        optionText="text"
        optionValue="value"
        alwaysOn
      />
      {canEdit(permissions) && (
        <SelectInput
          alwaysOn
          label="Stato"
          source="visible"
          allowEmpty={true}
          choices={[
            { id: true, name: 'Attivo' },
            { id: false, name: 'Cancellato' },
          ]}
        />
      )}
      <NumberInput label="Slot totali" source="supply" />
      <NumberInput label="Slot disponibili" source="availableSupply" />
      <BooleanInput label="Monouso" source="oneTimeOnly" />
      <DateInput label="Comincia il" source="startDate[strictly_after]" />
      <DateInput label="Termina il" source="endDate[strictly_before]" />
      <DateInput label="Registrato dal" source="createdAt[strictly_after]" />
      <DateInput label="Registrato al" source="createdAt[strictly_before]" />
    </Filter>
  );
};
UserBenefitFilter.propTypes = {
  setFilters: PropTypes.func,
  filterValues: PropTypes.object,
  permissions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default UserBenefitFilter;
