import React from 'react';
import {
  ChipField,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SingleFieldList,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { PromoCodeTypeChip } from './promoCode.util';

const PromoCodeShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="discount" label="Discount(%)" />
      <ReferenceField
        label="Seller Code"
        source="seller"
        reference="sellers"
        link="show"
        sortable={false}
      >
        <FunctionField render={(record) => `${record.code}`} />
      </ReferenceField>
      <ReferenceField
        label="Product"
        source="product"
        reference="products"
        link="show"
        sortable={false}
      >
        <FunctionField render={(record) => `${record.title}`} />
      </ReferenceField>
      <ReferenceManyField reference="events" target="promoCodes.id" label="Events">
        <SingleFieldList>
          <FunctionField
            source="event_name"
            link="show"
            render={(record, source) => (
              <ChipField
                record={{
                  ...record,
                  event_name: `${record.place} ${new Date(record.date).toLocaleDateString(
                    'it-it'
                  )}`,
                }}
                source={source}
              />
            )}
          />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="code" label="Code" />
      <Typography className={['MuiFormLabel-root', 'MuiInputLabel-shrink']} variant="span">
        Type
      </Typography>
      <PromoCodeTypeChip source="type" label="Type" />
    </SimpleShowLayout>
  </Show>
);

export default PromoCodeShow;
