import React from 'react';
import {
  Show,
  SimpleShowLayout,
  DateField,
  ReferenceField,
  FunctionField,
  TextField,
  useRefresh,
  useNotify,
  Button,
  fetchStart,
  fetchEnd,
  Error,
  LinearProgress,
} from 'react-admin';
import AsideView from '../asideView';
import { ReportStateField, ReportTargetReferenceField } from '../fields';
import { fetchOptions } from '../dataProvider';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    marginRight: theme.spacing(2),
  },
  actionContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ActionButton = ({ record, operation, setLoading, setError }) => {
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const classes = useStyles();

  const executeOperation = () => {
    setLoading(true);
    dispatch(fetchStart());
    fetch(
      `${new URL(process.env.REACT_APP_API_ENTRYPOINT).origin}${record['@id']}/${operation}`,
      fetchOptions({ method: 'GET' })
    )
      .then(() => {
        refresh();
        notify(operation === 'approve' ? 'Report approvato' : 'Report negato');
      })
      .catch((error) => {
        setError(error);
        notify(
          operation === 'approve' ? 'Error: report non approvato' : 'Error: report non negato',
          'warning'
        );
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  };

  return (
    <Button
      className={classes.actionButton}
      label={operation === 'approve' ? 'Approva' : 'Nega'}
      color="primary"
      variant="contained"
      onClick={executeOperation}
    />
  );
};
ActionButton.propTypes = {
  operation: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

const ActionButtons = ({ record }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  if (!record || !record.state || record.state !== 'waiting') return null;

  if (loading) return <LinearProgress />;
  if (error) return <Error />;

  return (
    <div className={classes.actionContainer}>
      <ActionButton
        operation="approve"
        record={record}
        setLoading={setLoading}
        setError={setError}
      />
      <ActionButton operation="deny" record={record} setLoading={setLoading} setError={setError} />
    </div>
  );
};
ActionButtons.propTypes = {
  record: PropTypes.object,
};

const ReportsShow = (props) => (
  <Show aside={<AsideView />} {...props}>
    <SimpleShowLayout>
      <TextField source="reason" />
      <ActionButtons />
      <ReferenceField label="Owner" source="owner" reference="users" link="show" sortable={false}>
        <FunctionField render={(record) => `${record.surname} ${record.name}`} />
      </ReferenceField>
      <ReportTargetReferenceField label="Target" source="targetIRI" link="show" />
      <ReportStateField source="state" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
);

export default ReportsShow;
