import React from 'react';
import { BooleanField, DateField, TextField } from 'react-admin';
import PropTypes from 'prop-types';
import { ListGuesser } from '@api-platform/admin';
import PartnerFilter from './PartnerFilters';
import PartnerListActions from './PartnerListActions';
import { isAdmin, isEditor } from '../utils';

const hasPermissions = (permissions) => isEditor(permissions) || isAdmin(permissions);

const PartnerList = ({ permissions, ...props }) => {
  return (
    <ListGuesser
      {...props}
      title={'List of partners'}
      perPage={25}
      filters={<PartnerFilter permissions={permissions} {...props} />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={<PartnerListActions />}
    >
      <TextField source="name" />
      {hasPermissions(permissions) && <BooleanField source="visible" />}
      <DateField source="createdAt" />
      {hasPermissions(permissions) && <DateField source="updatedAt" />}
    </ListGuesser>
  );
};
PartnerList.propTypes = {
  permissions: PropTypes.array,
  props: PropTypes.any,
};

export default PartnerList;
