import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  unknown: {},
  denied: {
    backgroundColor: '#bcbcbc',
  },
  waiting: {
    backgroundColor: '#ffbcbc',
  },
  approved: {
    backgroundColor: '#bcffbc',
  },
}));

const ReportStateField = ({ record = {}, source }) => {
  const classes = useStyles();
  if (!record[source]) return null;
  let style = classes.unknown;
  let label = record[source];
  switch (label) {
    case 'waiting':
      style = classes.waiting;
      break;
    case 'approved':
      style = classes.approved;
      break;
    case 'denied':
      style = classes.denied;
      break;
    default:
  }
  return <Chip className={clsx(classes.chip, style)} label={label} />;
};

ReportStateField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

ReportStateField.defaultProps = {
  addLabel: true, // to enable it in the "show" view
};

export default ReportStateField;
