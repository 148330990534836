import React from 'react';
import {
  BooleanField,
  DateField,
  TextField,
  ArrayField,
  SingleFieldList,
  FunctionField,
  ReferenceField,
} from 'react-admin';
import PropTypes from 'prop-types';
import { ListGuesser } from '@api-platform/admin';
import PartnershipFilter from './PartnershipFilters';
import PartnershipListActions from './PartnershipListActions';
import { isAdmin, isEditor, tournamentOptionText } from '../utils';
import { PartnerReferenceField } from '../partner/utils';
import { UserClusterField } from '../UserClusters';
import { GenderField } from '../fields';

const hasPermissions = (permissions) => isEditor(permissions) || isAdmin(permissions);

const PartnershipList = ({ permissions, ...props }) => {
  return (
    <ListGuesser
      {...props}
      title={'List of partners'}
      perPage={25}
      filters={<PartnershipFilter permissions={permissions} {...props} />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={<PartnershipListActions />}
    >
      <TextField source="name" />
      <TextField source="signupFlow" />
      <PartnerReferenceField label="Partner" source="partner" />
      <ReferenceField sortable={false} source="tournament" reference="tournaments">
        <FunctionField render={(record) => tournamentOptionText(record)} />
      </ReferenceField>
      <ArrayField source="targets">
        <SingleFieldList
          style={{ display: 'flex', flex: '1 0 45%', gap: '5px 10px', padding: '5px' }}
        >
          <FunctionField render={(record) => <UserClusterField value={record} />} />
        </SingleFieldList>
      </ArrayField>
      <GenderField source="gender" />
      <DateField source="startDate" />
      <DateField source="endDate" />
      {hasPermissions(permissions) && <BooleanField source="visible" />}
      <DateField source="createdAt" />
      {hasPermissions(permissions) && <DateField source="updatedAt" />}
    </ListGuesser>
  );
};
PartnershipList.propTypes = {
  permissions: PropTypes.array,
  props: PropTypes.any,
};

export default PartnershipList;
