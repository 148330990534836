import { LinearProgress } from '@material-ui/core';
import React from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  useDataProvider,
  useNotify,
  SaveButton,
  Toolbar,
} from 'react-admin';
import { validateDescription } from './validators';
import mediaCreator from '../mediaCreator';
import PropTypes from 'prop-types';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);
EditToolbar.propTypes = {
  pristine: PropTypes.bool,
};

const VideoEdit = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [loading, showLoading] = React.useState(false);

  return (
    <Edit
      {...props}
      transform={async (data) => {
        const createStageVideo = mediaCreator({ dataProvider, notify, mediaFieldLabel: 'stage' });
        try {
          showLoading(true);
          const withStageVideo = await createStageVideo(data);
          return withStageVideo;
        } catch (error) {
          notify(`Errore inatteso: ${error.message}`, 'warning');
        } finally {
          showLoading(false);
        }
        return data;
      }}
    >
      <SimpleForm>
        <TextInput multiline source="description" fullWidth={true} validate={validateDescription} />
        <FileInput
          source="file"
          label="Video dello Stage"
          placeholder={
            <p>
              Se vuoi sostituire il video, trascina qui il nuovo video, altrimenti verrà mantenuto
              il video corrente
            </p>
          }
        >
          <FileField source="src" title="title" />
        </FileInput>
        {loading && <LinearProgress fullWidth={true} />}
      </SimpleForm>
    </Edit>
  );
};

export default VideoEdit;
