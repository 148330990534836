import React, { useState } from 'react';
import {
  EditButton,
  ShowButton,
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { apiRequest, hasRole, HTTP_METHOD_POST } from '../utils';
import authProvider from '../authProvider';
import { Button, useNotify } from 'react-admin';
import { Box } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/Sync';
import DialogForm from '../DialogForm';
import InfoBox from '../InfoBox';

/*
 * ----------------------------- Materialized View utils -----------------------------
 *
 * Every function, object, or JsxElement defined in this file
 * allows for a more centralized and cleaner handling of MaterializedViews endpoints
 *
 * Every util makes a few assumptions regarding the materialized-view configurations:
 *
 * - The materialized view endpoint is nested within the 'api/v1/views' root path, components may still work if this is not the case
 * - The materialized view has a corresponding entity exposed via API, the API path of the materialized view is composed as follows: /api/v1/views/<entity-path>
 *      e.g. entity endpoint -> /api/v1/users, materialized view endpoint -> /api/v1/views/users
 * - Every materialized view record has a one-to-one correspondence to its non-materialized record, the relationship is given by the record own id (id, @id)
 * - The record @type corresponds to the @type of the entity ending in 'View'.
 *      e.g. @type of the entity -> User, @type of the materialized view -> UserView
 *
 */

/**
 * @module MatView
 */

/**
 * Returns the full record id of the non-materialized entity
 *
 * @param id
 * @returns {string}
 */
export function parseMaterializedViewRecordId(id) {
  return id?.split('views/')?.join('');
}

/**
 * Transforms basic entity identifiers from a materialized view to its original entity
 *
 * @param {{
 *     "@id": string,
 *     "@type": string,
 *     id: string,
 *     [ key: string ]: unknown
 * }} record
 * @returns {{
 *     "@id": string,
 *     "@type": string,
 *     id: string,
 *     [ key: string ]: unknown
 * }}
 */
export function parseMaterializedViewRecord(record) {
  return {
    ...record,
    id: parseMaterializedViewRecordId(record?.id),
    '@id': parseMaterializedViewRecordId(record?.['@id']),
    '@type': record?.['@type'].split('View')?.join(''),
  };
}

/**
 * Updates the Show, Edit, List, Create props so that the main component operates on the materialized view endpoints
 *
 * @param {{resource:string, id?:string, [k:string]:any}} record
 * @returns {object|undefined}
 */
export function enforceMaterializedViewResource(props) {
  if (!props) {
    return undefined;
  }
  const shallowCopy = { ...props };
  if (shallowCopy?.resource) {
    const resource = shallowCopy.resource;
    const r = resource.startsWith('views/') ? resource : `views/${resource}`;
    // override default resource for list component -> allows read operation on materialized view endpoint
    shallowCopy.resource = r;
    if (shallowCopy.id) {
      const id = shallowCopy.id;
      shallowCopy.id = id && !id.includes(r) ? id.replace(resource, r) : id;
    }
  }
  return shallowCopy;
}

export const EditMaterializedViewRecord = ({ resource, record, ...props }) =>
  record ? (
    <EditButton {...props} record={parseMaterializedViewRecord(record)} resource={resource} />
  ) : null;
EditMaterializedViewRecord.propTypes = {
  resource: PropTypes.string,
  record: PropTypes.object,
  props: PropTypes.object,
};

export const ShowMaterializedViewRecord = ({ resource, record, ...props }) =>
  record ? (
    <ShowButton {...props} record={parseMaterializedViewRecord(record)} resource={resource} />
  ) : null;
ShowMaterializedViewRecord.propTypes = {
  resource: PropTypes.string,
  record: PropTypes.object,
  props: PropTypes.object,
};

const RefreshMaterializedViewButton = ({ disabled, targetClass, title, description }) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);

  const handleSubmit = async () => {
    setOpen(false);
    if (!authProvider.checkExistingToken()) {
      notify('Error during export', 'warning');
      return;
    }
    const response = await apiRequest({
      path: '/views/refresh',
      method: HTTP_METHOD_POST,
      payload: { targetClass },
    });
    if (response.ok) {
      notify('The view is being updated!');
    } else {
      notify('Error during operation', 'warning');
    }
  };

  return (
    <>
      <Button disabled={disabled} label="Refresh" onClick={() => setOpen(true)}>
        <DownloadIcon />
      </Button>
      <DialogForm
        open={open}
        onSubmit={handleSubmit}
        onCancel={() => setOpen(false)}
        title={title}
        submitLabel="Refresh"
      >
        <Box display="flex" flexDirection="column">
          {description}
        </Box>
      </DialogForm>
    </>
  );
};

RefreshMaterializedViewButton.propTypes = {
  disabled: PropTypes.bool,
  targetClass: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.element,
};

export const MatViewButtonActions = { show: 'show', edit: 'edit', refresh: 'refresh' };
/**
 * Creates a button that allows to perform the specified action on the underlying entity
 *
 * @param {string} action
 * @param {string} resource name of the api resource
 * @param {object} record
 * @param {string[]} acl list of allowed roles
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
export const MatViewButton = ({ action, resource, record, acl, ...props }) => {
  if (!Object.values(MatViewButtonActions).includes(action)) {
    throw new Error(`MatViewButton: invalid prop action, provided ${action}.
            \nValue must be one of ${Object.values(MatViewButtonActions).join(', ')}.
            \nCheck out MatViewButtonActions`);
  }

  if (acl && props.permissions) {
    let hasPermissions = false;
    for (const role of acl) {
      if (hasRole(props.permissions, role)) {
        hasPermissions = true;
        break;
      }
    }
    if (!hasPermissions) return null;
  }

  switch (action) {
    case MatViewButtonActions.show:
      return <ShowMaterializedViewRecord resource={resource} record={record} {...(props ?? {})} />;
    case MatViewButtonActions.edit:
      return <EditMaterializedViewRecord resource={resource} record={record} {...(props ?? {})} />;
    case MatViewButtonActions.refresh:
      return <RefreshMaterializedViewButton targetClass={resource} {...(props ?? {})} />;
  }
};
MatViewButton.propTypes = {
  action: PropTypes.string,
  resource: PropTypes.string,
  record: PropTypes.object,
  permissions: PropTypes.object,
  acl: PropTypes.arrayOf(PropTypes.string),
  props: PropTypes.object,
};

/**
 * Creates a List using the materialized view endpoint of an entity
 *
 * e.g. resource 'users' -> materialized view 'views/users'
 *
 * > !!!Important!!! you MUST define the <Resource name="views/<entity>" /> in the App.js file
 *
 * @param {string} resource name of the non-materialized entity
 * @param {array} children
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
export const MatViewList = ({ children, ...props }) => (
  <List {...enforceMaterializedViewResource(props)}>
    <Datagrid>{children}</Datagrid>
  </List>
);
MatViewList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  props: PropTypes.object,
};

/**
 * Creates a Show component using the materialized view endpoint of an entity
 *
 * e.g. resource 'users' -> materialized view 'views/users'
 *
 * > !!!Important!!! you MUST define the <Resource name="views/<entity>" /> in the App.js file
 *
 * @param {string} resource name of the non-materialized entity
 * @param {array} children
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
export const MatViewShow = ({ children, simple = true, ...props }) => {
  const scaffold = (content) => <Show {...enforceMaterializedViewResource(props)}>{content}</Show>;

  if (!simple) {
    return scaffold(children);
  }

  return scaffold(<SimpleShowLayout>{children}</SimpleShowLayout>);
};
MatViewShow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object]),
  simple: PropTypes.bool,
  props: PropTypes.object,
};

export const MatViewDisclaimer = ({ children, level = 'info' }) => {
  return (
    <InfoBox level={level}>
      <span style={{ fontSize: '14px' }}>
        <strong>
          I risultati mostrati a schermo derivano da una vista, potrebbero non essere aggiornati
          alle ultime modifiche.
        </strong>
      </span>
      {children}
    </InfoBox>
  );
};
MatViewDisclaimer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  level: PropTypes.string,
};

export const MatViewDisclaimerField = ({ children, level = 'info' }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div onClick={() => setIsOpen(true)}>
      <InfoBox level={level}></InfoBox>
      <DialogForm
        open={isOpen}
        onSubmit={() => setIsOpen(false)}
        submitLabel="Capisco"
        cancelLabel={false}
      >
        <MatViewDisclaimer level={level}>{children}</MatViewDisclaimer>
      </DialogForm>
    </div>
  );
};
MatViewDisclaimerField.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  level: PropTypes.string,
};

export const MatViewLastUpdatedField = ({ source, ...props }) => (
  <FunctionField
    {...props}
    source={source}
    render={(record) => {
      if (!record || !source || !record[source]) {
        return null;
      }
      const lastUpdated = new Date(record[source]);
      const now = new Date();
      /** @var {'m'|'d'|'h'|'i'} timeframe */
      let timeframe = 'i';
      if (lastUpdated.getHours() - now.getHours() < 0) {
        timeframe = 'h';
      }
      if (lastUpdated.getDate() - now.getDate() < 0) {
        timeframe = 'd';
      }
      if (
        lastUpdated.getMonth() - now.getMonth() < 0 ||
        lastUpdated.getFullYear() - now.getFullYear() < 0
      ) {
        timeframe = 'm';
      }
      const styleMap = {
        i: { backgroundColor: '#73d34a', color: '#000000' },
        h: { backgroundColor: '#73d34a', color: '#000000' },
        d: { backgroundColor: '#ffe600', color: '#000000' },
        m: { backgroundColor: '#f45c45', color: '#000000' },
      };
      const labelMap = {
        i: now.getMinutes() - lastUpdated.getMinutes() + ' minuti fa',
        h: now.getHours() - lastUpdated.getHours() + ' ore fa',
        d: now.getDate() - lastUpdated.getDate() + ' giorni fa',
        m:
          lastUpdated.getFullYear() - now.getFullYear() < 0
            ? now.getFullYear() - lastUpdated.getFullYear() + ' anni fa'
            : now.getMonth() - lastUpdated.getMonth() + ' mesi fa',
      };

      return <Chip style={styleMap[timeframe]} label={labelMap[timeframe]} />;
    }}
  />
);
MatViewLastUpdatedField.propTypes = {
  source: PropTypes.string,
  props: PropTypes.object,
};

/**
 * @type {{
 *  parseRecordId: parseMaterializedViewRecordId,
 *  parseRecord: parseMaterializedViewRecord,
 *  buttonActions: MatViewButtonActions,
 *  Button: MatViewButton,
 *  List: MatViewList,
 * }}
 */
const MatView = {
  parseRecordId: parseMaterializedViewRecordId,
  parseRecord: parseMaterializedViewRecord,
  buttonActions: MatViewButtonActions,
  Button: MatViewButton,
  List: MatViewList,
  Show: MatViewShow,
  Disclaimer: MatViewDisclaimer,
  DisclaimerField: MatViewDisclaimerField,
  LastUpdatedField: MatViewLastUpdatedField,
};
export default MatView;
