import React, { useState } from 'react';
import { SelectInput } from 'react-admin';
import { PropTypes } from 'prop-types';
import { Chip } from '@material-ui/core';

export const ACTIVITY_LOG_STATUS_PENDING = 'PENDING';
export const ACTIVITY_LOG_STATUS_ERROR = 'ERROR';
export const ACTIVITY_LOG_STATUS_OK = 'OK';
export const ACTIVITY_LOG_STATUS_ENUM = [
  ACTIVITY_LOG_STATUS_PENDING,
  ACTIVITY_LOG_STATUS_ERROR,
  ACTIVITY_LOG_STATUS_OK,
];

export const ActivityLogStatusField = ({ record, ...props }) => {
  const [status] = useState(record?.status);

  if (status === ACTIVITY_LOG_STATUS_PENDING)
    return (
      <Chip
        {...props}
        style={{ backgroundColor: '#e5bc53', color: '#000' }}
        label="In progress..."
      />
    );
  if (status === ACTIVITY_LOG_STATUS_ERROR)
    return (
      <Chip {...props} style={{ backgroundColor: '#e14f41', color: '#ffffff' }} label="Failed" />
    );
  if (status === ACTIVITY_LOG_STATUS_OK)
    return (
      <Chip
        {...props}
        style={{ backgroundColor: '#47e02e', color: '#ffffff' }}
        label="Successful"
      />
    );

  console.error('null or invalid status given', status);
  return null;
};
ActivityLogStatusField.propTypes = {
  record: PropTypes.object,
  props: PropTypes.object,
};

export const ActivityLogStatusInput = ({ source, label, record, ...props }) => (
  <SelectInput
    {...props}
    record={record}
    label={label}
    source={source}
    allowEmpty={true}
    choices={[
      { id: ACTIVITY_LOG_STATUS_PENDING, name: 'Pending' },
      { id: ACTIVITY_LOG_STATUS_ERROR, name: 'Failed' },
      { id: ACTIVITY_LOG_STATUS_OK, name: 'Successful' },
    ]}
  />
);
ActivityLogStatusInput.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  props: PropTypes.object,
};
